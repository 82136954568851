import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
import { PDFContainer } from "./styles";
import { CreateQuotePDF } from "../../pages/Quote History/components/CreateQuotePDF";
import { pdf } from "@react-pdf/renderer";
import ReactLoading from "react-loading";
import { theme } from "../../styles/theme";

const PDFModal = ({
  modalOpen,
  handleCloseModal,
  pdfData,
  isLoadingDataPdf,
}) => {
  const [pdfUrl, setPdfUrl] = useState(null);

  const generatePdf = async () => {
    const blob = await pdf(<CreateQuotePDF pdfData={pdfData} />).toBlob();
    const url = URL.createObjectURL(blob);
    setPdfUrl(url);
  };

  useEffect(() => {
    if (modalOpen) {
      setPdfUrl(null);
      const timer = setTimeout(() => {
        generatePdf();
      }, 100);
      return () => clearTimeout(timer);
    } else {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
        setPdfUrl(null);
      }
    }
  }, [modalOpen, pdfData]);

  return (
    <PDFContainer>
      <Modal
        open={modalOpen}
        onCancel={handleCloseModal}
        width={"80%"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Modal.Header />
        <Modal.Body
          padding={"5px 5px 5px 5px"}
          style={{
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {pdfUrl && !isLoadingDataPdf ? (
            <iframe
              src={pdfUrl}
              style={{ width: "100%", height: "100%" }}
              frameBorder="0"
            />
          ) : (
            <ReactLoading
              type="spin"
              color={theme.colors.green}
              height={50}
              width={50}
            />
          )}
        </Modal.Body>
      </Modal>
    </PDFContainer>
  );
};

export default PDFModal;
