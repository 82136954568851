import styled from "styled-components";

export const CustomCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 490px;
  height: 594px;
  background: linear-gradient(
    121deg,
    rgba(255, 255, 255, 0.45) -42.27%,
    rgba(217, 217, 217, 0.45) 145.68%
  );
  backdrop-filter: blur(19px);
  border: 1.5px solid rgba(0, 0, 0, 0.55);
  border-radius: 23px;
  opacity: 1;
  padding: 57px 45.98px 66.53px 45px;
`;
