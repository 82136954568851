import styled from "styled-components";
import { Select } from "antd";
import { theme } from "../../styles/theme";

export const CustomSelect = styled(Select)`
  font-family: ${theme.fonts.family};
  font-size: ${theme.fonts.size.sm};
  color: ${(props) => props.color || theme.colors.white150};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || "44px"};
  border: 1.7px solid ${({ border }) => border || theme.colors.gray150};
  border-radius: ${({ radius }) => radius || "30px"};
  background: ${({ background }) => background || theme.colors.white};

  &&& .ant-select {
    &-selector {
      padding-left: 15px;
      height: ${({ height }) => height || "44px"};
      border-radius: ${({ radius }) => radius || "30px"};
      background: transparent;
      font-size: ${theme.fonts.size.sm};
      color: ${(props) => props.color || theme.colors.white150};
      border: none;

      &:hover {
        background: ${(props) =>
          props.hoverBackground || theme.colors.purpleGradientSelects};
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
        border-color: ${(props) => props.borderHover || theme.colors.gray150};
      }

      &:focus {
        border: none;
      }
    }

    &-selection-search-input {
      padding-left: 15px;
      font-size: ${theme.fonts.size.sm};
      color: ${(props) => props.color || theme.colors.gray950};
      border-radius: ${({ radius }) => radius || "30px"};

      height: 44px;
    }

    &-selection-item,
    &-selection-placeholder {
      font-family: ${theme.fonts.family};
      font-size: ${theme.fonts.size.sm};
      color: ${(props) => props.color || theme.colors.white150};
      line-height: ${({ height }) => height || "44px"};
    }

    &-selection-item {
      font-weight: ${(props) => props.weight || theme.fonts.weight.regular};
      text-transform: capitalize;
      color: ${(props) => props.color || theme.colors.white150};
      span {
        color: ${(props) => props.color || theme.colors.white150} !important;
      }
    }

    &-disabled {
      .ant-select-selection-placeholder {
        color: ${theme.colors.gray100};
      }
    }

    &-open {
      .ant-select-arrow {
        transform: rotate(-180deg);
        margin-right: ${(props) => (props.allowClear ? "8px" : "0")};
      }
    }
  }
`;
