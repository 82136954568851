import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Row, Div, Text, Container } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Skeleton } from "../../components/Skeleton";
import { Modal } from "../../components/Modal";
import Table from "../../components/Table";
import { theme } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import {
  ClinicIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  PhotoOpenIcon,
} from "../../assets/icons";
import useGetClinics from "./hooks/useGetClinics";
import useDebounce from "../../core/hooks/useDebounce";
import { Link } from "../../components/Link";
import { LogoCard } from "./styles";
import { setSelectedClinicInformation } from "../../core/store/clinics";
import { formattedDate, isSuperAdmin } from "../../utilities/helpers";
import { Tooltip } from "antd";

export const Clinics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forceUpdate, setForceUpdate] = useState(true);
  const [showLogo, setShowLogo] = useState({ show: false, logo: null });

  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [sortOptions, setSortOptions] = useState({
    sortBy: null,
    sortDirection: null,
  });

  const [totalItems, setTotalItems] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const searchValue = useDebounce(searchText, 600);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
    sortDirection: sortOptions.sortDirection,
    sortBy: sortOptions.sortBy,
    searchBy: searchValue,
  });

  useEffect(() => {
    if (!isSuperAdmin()) {
      navigate("/home");
    }
  }, []);

  const getClinics = useGetClinics(payloadOptions);
  const { data, isLoading, isFetching } = getClinics;
  const [clinicsList, setClinicsList] = useState([]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      const dataTable = data.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: (
              <Tooltip title="Edit" color={theme.colors.gray950}>
                <EditIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              dispatch(setSelectedClinicInformation(row));
              navigate("edit-clinic");
            },
          },
          {
            key: `1${i}-view`,
            id: ele.id,
            icon: (
              <Tooltip title="See" color={theme.colors.gray950}>
                <EyeIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              dispatch(setSelectedClinicInformation(row));
              navigate("view-clinic");
            },
          },
          {
            key: `1${i}-image`,
            id: ele.id,
            icon: (
              <Tooltip title="Logo" color={theme.colors.gray950}>
                <PhotoOpenIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setShowLogo({ show: true, logo: row.clinic.image });
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: (
              <Tooltip title="Delete" color={theme.colors.gray950}>
                <DeleteIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: () => console.log("delete"),
          },
        ],
      }));
      setClinicsList(dataTable);
      setTotalItems(data.data.data.total);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
      sortBy: sortOptions.sortBy,
      sortDirection: sortOptions.sortDirection,
      searchBy: searchValue,
    });
  }, [pages, sortOptions, searchValue]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleChangeTable = (pagination, filter, sort) => {
    if (sort && sort.columnKey) {
      setSortOptions({
        sortBy: sort.columnKey,
        sortDirection: sort.order === "ascend" ? "ASC" : "DESC",
      });
    } else {
      setSortOptions({ sortBy: null, sortDirection: null });
    }

    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });

    setForceUpdate(!forceUpdate);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "C. Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 120,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => `${formattedDate(value)}`,
      },
      {
        title: "Practice Name",
        dataIndex: "clinic",
        key: "clinicName",
        sorter: true,
        showSorterTooltip: false,
        width: 200,
        render: (value, item) => `${value ? value.name : ""}`,
      },
      {
        title: "Admin Name",
        dataIndex: "user",
        key: "userName",
        width: 200,
        sorter: true,
        ellipsis: true,
        showSorterTooltip: false,
        render: (value, item) =>
          `${value ? `${value.name} ${value.lastName}` : ""}`,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        ellipsis: true,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => `${value}`,
      },

      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 160,
        render: (actions, row) => {
          return (
            <Div gap="10px">
              {actions &&
                actions.map((action, i) => (
                  <Link
                    key={i}
                    onClick={() => action.onClick(row)}
                    color={theme.colors.gray50}
                    icon={action.icon}
                    styles={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  />
                ))}
            </Div>
          );
        },
      },
    ],
    [clinicsList]
  );

  const handleCloseModal = () => {
    setShowLogo({ show: false, logo: null });
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Modal open={showLogo.show} onCancel={handleCloseModal} width={"569px"}>
        <Modal.Header />
        <Modal.Body padding={"10px 5px 0px 5px"}>
          <LogoCard background={showLogo.logo} />
        </Modal.Body>
      </Modal>

      <Row
        height="40px"
        justify="space-between"
        align="center"
        m="0px 0px 37px 0px"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Practice
          </Text>
        </Div>

        <Div gap="10px" align="center">
          <Input
            placeholder="Search and filter by name, admin or email"
            color={theme.colors.gray400}
            width="597px"
            border={theme.colors.gray800}
            onChange={handleChangeSearch}
            background={theme.colors.gray800}
          />

          <Button width="183px" onClick={() => navigate("create-clinic")}>
            <Row width="100%" justify="center">
              <Div gap={"8px"} align={"center"}>
                <ClinicIcon stroke={theme.colors.black50} />
                Create Practice
              </Div>
            </Row>
          </Button>
        </Div>
      </Row>

      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={clinicsList}
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              } table-row-hover`
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "clinics", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Container>
  );
};
