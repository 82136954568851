import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { Row, Div, Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { Switch } from "../../../components/Switch";
import { Link } from "../../../components/Link";
import { theme } from "../../../styles/theme";
import { LateralCard, QuotingCard, TotalCard } from "../../Quoting Tool/styles";
import { BilledInfo } from "./components/BilledInfo/Index";
import { ItemsInfo } from "./components/ItemsInfo/Index";
import ModalAdditionalDiscount from "./components/ModalAdditionalDiscount";
import ModalItems from "../../Quoting Tool/components/ModalItems";
import { downloadPDF, formatPriceToDollar } from "../../../utilities/helpers";
import {
  AddIcon,
  EditIcon,
  CloseIcon,
  DeleteIcon,
} from "../../../assets/icons";
import useEditQuote from "./hooks/useEditQuote";
import useGetQuoteById from "./hooks/useGetQuoteById";
import useGetUserByRoles from "../../../core/hooks/useGetUserByRoles";
import useGetOpeners from "../../../core/hooks/useGetOpeners";
import useGetClinicById from "../../Quoting Tool/hooks/useGetClinicById";
import useAddItem from "../../Quoting Tool/hooks/useAddItem";
import DrawerPaymentMethod from "./components/DrawerPaymentMethod";
import ModalPaidInFull from "./components/ModalPaidInFull";
import ModalAddAmount from "./components/ModalAddAmount";
import { Skeleton } from "antd";
import PDFModal from "../../../components/PDFModal";
import Checkbox from "../../../components/Checkbox";
import useParameters from "../../../core/hooks/useParameters";
import ModalOpenItems from "../../Quoting Tool/components/ModalOpenItems";
import ModalPackages from "../../Quoting Tool/components/ModalPackages";
import useGetPackagesDropdown from "../../Quoting Tool/hooks/useGetPackagesDropdown";
import useSaveQuote from "../../Quoting Tool/hooks/useSaveQuote";
import useGetPatientsByClinicId from "../../Quoting Tool/hooks/useGetPatientsByClinicId";
import { setSelectedQuotationInformation } from "../../../core/store/quotations";
import useGetPdfData from "../hooks/useGetPdfData";

export const EditQuotation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const showSendByEmail = false;

  const editQuote = useEditQuote();
  const addItem = useAddItem();
  const saveQuote = useSaveQuote();

  const { quotations } = useSelector((state) => state);

  const quoteId = quotations?.selectedQuotation?.id;
  const clinicId = quotations?.selectedQuotation?.clinic?.id;

  const [showModalDiscount, setShowModalDiscount] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalOpenItems, setShowModalOpenItems] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [paidFull, setPaidFull] = useState(false);
  const [overDue, setOverDue] = useState(false);
  const [isQuotePending, setIsQuotePending] = useState(false);
  const [discountConsultationFee, setDiscountConsultationFee] = useState(false);
  const defaultDiscount = {
    name: "Discount",
    type: "dollar",
    value: 150,
  };
  const [discountConsultationValue, setDiscountConsultationValue] =
    useState(defaultDiscount);
  const [showItemizedPricing, setShowItemizedPricing] = useState(false);
  const [quoteProviders, setQuoteProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [quoteOpener, setQuoteOpener] = useState();
  const [selectedOpener, setSelectedOpener] = useState();
  const [selectedCloser, setSelectedCloser] = useState({
    value: null,
    label: "Organic",
  });
  const [showModalPaid, setShowModalPaid] = useState(false);
  const [showModalAmount, setShowModalAmount] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [showModalPackages, setShowModalPackages] = useState(false);
  const [updateQuote, setUpdateQuote] = useState(false);
  const [isEditingItems, setIsEditingItems] = useState(false);

  const getClinic = useGetClinicById(selectedClinic?.value);
  const { data: dataClinic, isSuccess: isSuccessDataClinic } = getClinic;
  const [clinicTax, setClinicTax] = useState(0);
  useEffect(() => {
    if (dataClinic?.data?.data && isSuccessDataClinic) {
      setClinicTax(dataClinic?.data?.data?.taxes);
      if (dataClinic?.data?.data?.consultationFeeValue) {
        setDiscountConsultationValue({
          name:
            dataClinic?.data?.data?.consultationFeeName !== ""
              ? dataClinic?.data?.data?.consultationFeeName
              : "Discount",
          type:
            dataClinic?.data?.data?.consultationFeeType !== ""
              ? dataClinic?.data?.data?.consultationFeeType
              : "dollar",
          value: dataClinic?.data?.data?.consultationFeeValue,
        });
      }
    }
  }, [dataClinic, isSuccessDataClinic]);

  const getPackages = useGetPackagesDropdown(clinicId);
  const { data: dataPackages } = getPackages;
  const [optionsPackages, setOptionsPackages] = useState([]);
  useEffect(() => {
    if (dataPackages) {
      setOptionsPackages(
        dataPackages?.data?.data?.map((ele) => ({
          value: ele.id,
          label: `${ele.name}`,
        }))
      );
    }
  }, [dataPackages]);

  const getPatients = useGetPatientsByClinicId(selectedClinic?.value);
  const { data: patients } = getPatients;
  const [listPatientsOrigin, setListPatientsOrigin] = useState([]);
  useEffect(() => {
    if (patients) {
      setListPatientsOrigin(patients?.data?.data);
    }
  }, [patients]);

  const initialValues = {
    id: null,
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: null,
  };
  const [actualInfo, setActualInfo] = useState(initialValues);

  const initialValuesOpenItem = {
    type: "product",
    name: "",
    price: null,
    tax: clinicTax,
    isTaxIncluded: null,
    package: "",
  };
  const [actualInfoOpenItems, setActualInfoOpenItems] = useState(
    initialValuesOpenItem
  );
  useEffect(() => {
    setActualInfoOpenItems(initialValuesOpenItem);
  }, [clinicTax]);
  const itemInitialErrors = {
    treatmentsCategories: {
      error: false,
      message: "",
    },
    treatments: {
      error: false,
      message: "",
    },
    productsCategories: {
      error: false,
      message: "",
    },
    products: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [itemErrorForm, setItemErrorForm] = useState(itemInitialErrors);
  const openItemInitialErrors = {
    type: {
      error: false,
      message: "",
    },
    name: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    tax: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [openItemErrorForm, setOpenItemErrorForm] = useState(
    openItemInitialErrors
  );

  const [treatmentPlanNames, setTreatmentPlanNames] = useState([]);
  const { data: dataProductsTreatments } = useParameters(
    "treatments_plans",
    {
      onSuccess: (apiData) => {
        const listPlans = apiData?.data?.data?.treatments_plans.map((pl) => ({
          value: pl.id,
          label: pl.name,
        }));
        setTreatmentPlanNames([{ value: -1, label: "Custom" }, ...listPlans]);
      },
    },
    selectedClinic?.value
  );
  useEffect(() => {
    if (dataProductsTreatments?.data?.data?.treatments_plans) {
      const listPlans =
        dataProductsTreatments?.data?.data?.treatments_plans.map((pl) => ({
          value: pl.id,
          label: pl.name,
        }));
      setTreatmentPlanNames([{ value: -1, label: "Custom" }, ...listPlans]);
    }
  }, [dataProductsTreatments]);

  const [selectedDueDate, setSelectedDueDate] = useState();
  const [pdfFile, setPdfFile] = useState(null);
  const [modalPdfopen, setModalPdfopen] = useState(false);

  useEffect(() => {
    if (!quotations?.selectedQuotation?.id) {
      navigate("/quote-history");
    } else {
      setPaidFull(
        quotations?.selectedQuotation?.state === "paid" ? true : false
      );
      setSelectedClinic({
        value: quotations?.selectedQuotation?.clinic?.id,
        label: quotations?.selectedQuotation?.clinic?.name,
      });
      setSelectedPatient({
        value: quotations?.selectedQuotation?.patient?.id,
        label: `${quotations?.selectedQuotation?.patient?.name} ${quotations?.selectedQuotation?.patient?.lastname}`,
      });
      setSelectedDueDate(dayjs(quotations?.selectedQuotation?.dueDate));
    }
  }, [quotations?.selectedQuotation]);

  const [originalAdditionalDiscount, setOriginalAdditionalDiscount] = useState(
    []
  );
  const [additionalDiscount, setAdditionalDiscount] = useState([]);

  const getQuote = useGetQuoteById(quoteId);
  const { data: dataQuote, isLoading: isLoadingQuote } = getQuote;

  const getProviders = useGetUserByRoles(
    ["ServiceProvider", "Manager", "StaffAdmin", "AccountOwner"],
    selectedClinic?.value
  );
  const { data: providers } = getProviders;
  const [optionsProviders, setOptionsProviders] = useState([]);

  useEffect(() => {
    if (providers) {
      setOptionsProviders(
        providers?.data?.data?.map((ele) => {
          return {
            value: ele.id,
            label: `${ele.name} ${ele.lastname}`,
          };
        })
      );
    }
  }, [providers]);

  const getOpeners = useGetOpeners(selectedClinic?.value);
  const { data: openers } = getOpeners;
  const [optionsOpeners, setOptionsOpeners] = useState([]);

  useEffect(() => {
    if (openers) {
      setOptionsOpeners(
        openers?.data?.data?.map((ele) => ({
          value: ele.id,
          label: `${ele.name} ${ele.lastname}`,
        }))
      );
    }
  }, [openers]);

  const getPdfData = useGetPdfData(quoteId);
  const { data: dataPdf, isLoading: isLoadingDataPdf } = getPdfData;
  const [pdfData, setPdfData] = useState(null);
  useEffect(() => {
    if (dataPdf?.data?.data) {
      setPdfData(dataPdf?.data?.data);
    }
  }, [dataPdf, isLoadingDataPdf]);

  const [originalQuote, setOriginalQuote] = useState({});
  const [quotation, setQuotation] = useState({});
  const [originalOpenItems, setOriginalOpenItems] = useState([]);
  const [openItems, setOpenItems] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentError, setPaymentError] = useState(false);

  useEffect(() => {
    if (dataQuote) {
      const initialQuotation = {
        id: dataQuote?.data?.data?.id,
        clinicId: dataQuote?.data?.data?.clinicId,
        subtotal: dataQuote?.data?.data?.subtotal,
        discount: dataQuote?.data?.data?.discount,
        taxes: dataQuote?.data?.data?.taxes,
        total: dataQuote?.data?.data?.total,
        items: dataQuote?.data?.data?.details.filter(
          (item) => item.productTreatmentId !== null
        ),
        discountConsultationFee: dataQuote?.data?.data?.discountConsultationFee,
        showRate: dataQuote?.data?.data?.showRate,
      };

      setPaymentMethods(
        dataQuote?.data?.data?.paymentMethods.length > 0
          ? dataQuote?.data?.data?.paymentMethods.split(",")
          : []
      );
      setOriginalQuote(initialQuotation);
      setQuotation(initialQuotation);
      setOriginalOpenItems(
        dataQuote?.data?.data?.details
          .filter((item) => item.productTreatmentId === null)
          .map((item) => ({
            ...item,
            isOpenItem: true,
            isTaxIncluded: item.tax > 0,
          }))
      );
      setOpenItems(
        dataQuote?.data?.data?.details
          .filter((item) => item.productTreatmentId === null)
          .map((item) => ({
            ...item,
            isOpenItem: true,
            isTaxIncluded: item.tax > 0,
          }))
      );

      const discountsArray = _.range(0, 4)
        .map((index) => ({
          bonusType:
            index === 0
              ? dataQuote?.data?.data["additionalDiscountType"]
              : dataQuote?.data?.data[`additionalDiscountType${index}`],
          bonusValue:
            index === 0
              ? dataQuote?.data?.data["additionalDiscountValue"]
              : dataQuote?.data?.data[`additionalDiscountValue${index}`],
          discountName:
            index === 0
              ? dataQuote?.data?.data["additionalDiscountName"]
              : dataQuote?.data?.data[`additionalDiscountName${index}`],
        }))
        .filter((obj) => obj.bonusType && obj.bonusValue !== null);
      setOriginalAdditionalDiscount(discountsArray);
      setAdditionalDiscount(discountsArray);

      setShowItemizedPricing(dataQuote?.data?.data.showRate);
      setOverDue(dataQuote?.data?.data?.state === "overdue");
      setIsQuotePending(dataQuote?.data?.data?.state === "pending");
      setDiscountConsultationFee(
        dataQuote?.data?.data?.discountConsultationFee
      );

      const filteredProviders = dataQuote?.data?.data?.providers?.filter(
        (provider) => provider.userId !== null
      );
      const prevSelectedProviders = filteredProviders
        .map((provider) => {
          const matchingOption = optionsProviders.find(
            (option) => Number(option.value) === Number(provider.userId)
          );
          return matchingOption
            ? { id: matchingOption.value, name: matchingOption.label }
            : null;
        })
        .filter(Boolean);
      setQuoteProviders(prevSelectedProviders);
      setSelectedProviders(prevSelectedProviders);

      const selectedOpenerOption = optionsOpeners.find(
        (option) =>
          Number(option.value) === Number(dataQuote?.data?.data?.opener)
      );
      setQuoteOpener(selectedOpenerOption);
      setSelectedOpener(selectedOpenerOption);
    }
  }, [dataQuote, optionsProviders]);

  useEffect(() => {
    if (
      JSON.stringify(selectedProviders) !== JSON.stringify(quoteProviders) ||
      JSON.stringify(selectedOpener) !== JSON.stringify(quoteOpener) ||
      JSON.stringify(quotation.items) !== JSON.stringify(originalQuote.items) ||
      discountConsultationFee !== originalQuote.discountConsultationFee ||
      JSON.stringify(additionalDiscount) !==
        JSON.stringify(originalAdditionalDiscount) ||
      JSON.stringify(openItems) !== JSON.stringify(originalOpenItems) ||
      showItemizedPricing !== originalQuote.showRate
    ) {
      setUpdateQuote(true);
    } else {
      setUpdateQuote(false);
    }
  }, [
    selectedProviders,
    selectedOpener,
    quotation,
    discountConsultationFee,
    additionalDiscount,
    openItems,
    showItemizedPricing,
  ]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const handleDeletePaymentMethod = (label) => {
    setPaymentMethods((prevMethods) =>
      prevMethods.filter((method) => method !== label)
    );
  };

  const calculateTotal = () => {
    let calculation =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity + item.tax - item.discount
      ) +
      _.sumBy(
        openItems,
        (item) => item.price * item.quantity + item.tax - item.discount
      );

    const subtotal =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity - item.discount
      ) +
      _.sumBy(openItems, (item) => item.price * item.quantity - item.discount);
    additionalDiscount.forEach((discount) => {
      if (discount.bonusValue > 0) {
        if (discount.bonusType === "percentage") {
          calculation -= (subtotal * discount.bonusValue) / 100;
        } else {
          calculation -= discount.bonusValue;
        }
      }
    });

    if (discountConsultationFee && isSuccessDataClinic) {
      if (discountConsultationValue.type === "percentage") {
        calculation -= (subtotal * discountConsultationValue.value) / 100;
      } else {
        calculation -= discountConsultationValue.value;
      }
    }
    calculation = Math.max(0, calculation);
    return formatPriceToDollar(calculation);
  };

  const handleChange = (checked) => {
    setShowModalPaid(true);
    setPaidFull(checked);
  };

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "patient":
        if (value !== "createNewPatient") {
          setSelectedPatient(option);
        } else {
          setSelectedPatient({});
        }
        break;
      case "dueDate":
        setSelectedDueDate(value);
        break;
      case "selectedProviders":
        let newProviders = [...selectedProviders];
        const foundIndex = newProviders.findIndex(
          (ele) => ele.id === option.value
        );
        if (foundIndex === -1) {
          newProviders = [
            ...newProviders,
            { id: option.value, name: option.label },
          ];
        } else {
          newProviders[foundIndex] = { id: option.value, name: option.label };
        }
        setSelectedProviders(newProviders);
        break;
      case "selectedCloser":
        setSelectedCloser(option);
        break;
      case "opener":
        setSelectedOpener(option);
        break;
    }
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = selectedProviders.filter(
      (prov) => prov.id !== provider.id
    );
    setSelectedProviders(updatedProviders);
  };

  const handleCheckbox = (e, id) => {
    switch (id) {
      case "discountConsultationFee":
        setDiscountConsultationFee(e.target.checked);
        break;
      case "itemizedPricing":
        setShowItemizedPricing(e.target.checked);
        break;
    }
  };

  const handleCloseModal = () => {
    setActualInfo(initialValues);
    setActualInfoOpenItems(initialValuesOpenItem);
    setOpenItemErrorForm(openItemInitialErrors);
    setShowModalPackages(false);
    setShowModal(false);
    setShowModalOpenItems(false);
  };

  const handleCloseModalPay = () => {
    setSelectedCloser({
      value: null,
      label: "Organic",
    });
    setPaidFull(false);
    setShowModalPaid(false);
  };

  const handleSaveQuote = (createCopy) => {
    let additionalDiscountType = null;
    let additionalDiscountName = null;
    let additionalDiscountValue = null;
    let additionalDiscountType1 = null;
    let additionalDiscountName1 = null;
    let additionalDiscountValue1 = null;
    let additionalDiscountType2 = null;
    let additionalDiscountName2 = null;
    let additionalDiscountValue2 = null;
    let additionalDiscountType3 = null;
    let additionalDiscountName3 = null;
    let additionalDiscountValue3 = null;
    additionalDiscount.forEach((discount, index) => {
      switch (index) {
        case 0:
          additionalDiscountType = discount.bonusType || null;
          additionalDiscountName = discount.discountName || null;
          additionalDiscountValue = discount.bonusValue || null;
          break;
        case 1:
          additionalDiscountType1 = discount.bonusType || null;
          additionalDiscountName1 = discount.discountName || null;
          additionalDiscountValue1 = discount.bonusValue || null;
          break;
        case 2:
          additionalDiscountType2 = discount.bonusType || null;
          additionalDiscountName2 = discount.discountName || null;
          additionalDiscountValue2 = discount.bonusValue || null;
          break;
        case 3:
          additionalDiscountType3 = discount.bonusType || null;
          additionalDiscountName3 = discount.discountName || null;
          additionalDiscountValue3 = discount.bonusValue || null;
          break;
        default:
          break;
      }
    });

    let dueDateToSend = selectedDueDate;
    if (createCopy) {
      dueDateToSend = dataClinic?.data?.data?.expirationDays
        ? dayjs().add(dataClinic?.data?.data?.expirationDays, "days")
        : dayjs().add(7, "days");
    }

    const formData = {
      clinicId: dataQuote?.data?.data?.clinicId,
      patientId: Number(selectedPatient.value),
      dueDate: dueDateToSend,
      additionalDiscountType,
      additionalDiscountValue,
      additionalDiscountName,
      additionalDiscountType1,
      additionalDiscountName1,
      additionalDiscountValue1,
      additionalDiscountType2,
      additionalDiscountName2,
      additionalDiscountValue2,
      additionalDiscountType3,
      additionalDiscountName3,
      additionalDiscountValue3,
      paymentMethods: paymentMethods,
      state: dataQuote?.data?.data?.state,
      discountConsultationFee: discountConsultationFee,
      showRate: showItemizedPricing,
      providers: selectedProviders.map((provider) => {
        return {
          userId: provider.id,
        };
      }),
      productsTreatments: quotation.items.map((item) => ({
        id: Number(item.productTreatmentId),
        package: item.package,
        quantity: item.quantity,
        belongsToPkg: item.belongsToPkg ? item.belongsToPkg : false,
      })),
      openItems: openItems.map((item) => ({
        package: item.package,
        name: item.name,
        type: item.type,
        price: item.price,
        quantity: item.quantity,
        tax: item.isTaxIncluded,
        belongsToPkg: item.belongsToPkg ? item.belongsToPkg : false,
        discount: item.discount,
      })),
    };

    if (!createCopy) {
      editQuote.reset();
      editQuote.mutate(
        { formData, quoteId },
        {
          onSuccess: () => {
            toast.success("The quotation has been updated successfully");
            queryClient.invalidateQueries(["getQuotateById"]);
            queryClient.invalidateQueries(["getPdfData"]);
            setUpdateQuote(false);
            setIsEditingItems(false);
            navigate("/quote-history/edit-quotation");
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                "Error trying to update the quote"
            );
          },
        }
      );
    } else {
      saveQuote.reset();
      saveQuote.mutate(formData, {
        onSuccess: (data) => {
          toast.success("Quotation duplicated successfully");
          const patient = listPatientsOrigin.find(
            (p) => Number(p.id) === Number(selectedPatient.value)
          );
          queryClient.invalidateQueries(["getPdfData"]);

          dispatch(
            setSelectedQuotationInformation({
              id: Number(data?.data?.data?.id),
              state: "pending",
              dueDate: new Date(dueDateToSend),
              clinic: {
                id: selectedClinic.value,
                name: selectedClinic.label,
              },
              patient: {
                id: selectedPatient.value,
                name: patient.name,
                lastname: patient.lastname,
              },
            })
          );
          navigate("/quote-history/edit-quotation");
        },
        onError: (err) => {
          toast.error(
            err.response.data.error.description ||
              err.response.data.error.message ||
              "Error trying to save the quote"
          );
        },
      });
    }
  };

  return (
    <Div height="max-content" width="1070px" direction="column">
      <PDFModal
        modalOpen={modalPdfopen}
        pdfData={pdfData}
        handleCloseModal={() => {
          setModalPdfopen(false);
        }}
        isLoadingDataPdf={isLoadingDataPdf}
      />
      <ModalAdditionalDiscount
        showModalDiscount={showModalDiscount}
        setShowModalDiscount={setShowModalDiscount}
        additionalDiscount={additionalDiscount}
        setAdditionalDiscount={setAdditionalDiscount}
      />
      <ModalPaidInFull
        showModalPaid={showModalPaid}
        setShowModalPaid={setShowModalPaid}
        quoteId={quoteId}
        handleCloseModalPay={handleCloseModalPay}
        selectedCloser={selectedCloser}
        setSelectedCloser={setSelectedCloser}
        selectedProviders={selectedProviders}
      />
      <ModalAddAmount
        showModalAmount={showModalAmount}
        setShowModalAmount={setShowModalAmount}
      />
      <ModalItems
        addItem={addItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfo}
        errorForm={itemErrorForm}
        setErrorForm={setItemErrorForm}
        quotation={quotation}
        setQuotation={setQuotation}
        packageNames={treatmentPlanNames}
      />
      <ModalOpenItems
        showModal={showModalOpenItems}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfoOpenItems}
        errorForm={openItemErrorForm}
        setErrorForm={setOpenItemErrorForm}
        openItems={openItems}
        setOpenItems={setOpenItems}
        packageNames={treatmentPlanNames}
        clinicTax={clinicTax}
      />
      <DrawerPaymentMethod
        showDrawer={showDrawer}
        closeDrawer={closeDrawer}
        paymentMethods={paymentMethods}
        setPaymentMethods={setPaymentMethods}
        setPaymentError={setPaymentError}
      />
      <ModalPackages
        showModal={showModalPackages}
        handleCloseModal={handleCloseModal}
        clinicTax={clinicTax}
        optionsPackages={optionsPackages}
        quotation={quotation}
        setQuotation={setQuotation}
        addItem={addItem}
        openItems={openItems}
        setOpenItems={setOpenItems}
        setAdditionalDiscount={setAdditionalDiscount}
      />
      <Row justify="space-between" align="center" m="0px 0px 30px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Edit Quote
          </Text>
        </Div>
        {!paidFull && !overDue && (
          <Row gap="14px" align="center">
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.gray50}
            >
              Mark as Paid
            </Text>
            <Switch checked={paidFull} onChange={handleChange} />
            {/* <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={() => setShowModalAmount(true)}
              width="180px"
              background={theme.colors.green}
              m={"0 10px 0 10px"}
            >
              Add Amount
            </Button> */}
          </Row>
        )}
      </Row>
      <>
        {isLoadingQuote ? (
          <Div justify="center" gap="16px">
            <Skeleton.Input
              active
              size={410}
              style={{ width: "500px", backgroundColor: theme.colors.gray800 }}
            />
            <Div width="100%" gap="10px" justify="center" direction="column">
              <Skeleton.Input
                active
                size={200}
                style={{
                  width: "500px",
                  backgroundColor: theme.colors.gray800,
                }}
              />
              <Skeleton.Input
                active
                size={200}
                style={{
                  width: "500px",
                  backgroundColor: theme.colors.gray800,
                }}
              />
            </Div>
          </Div>
        ) : (
          <Row gap="16px">
            <QuotingCard>
              <BilledInfo
                selectedClinic={selectedClinic}
                selectedPatient={selectedPatient}
                selectedDueDate={selectedDueDate}
                paidFull={paidFull}
                handleSelect={handleSelect}
                overDue={overDue}
                showModalPaid={showModalPaid}
                selectedProviders={selectedProviders}
                handleRemoveProvider={handleRemoveProvider}
                optionsProviders={optionsProviders}
                optionsOpeners={optionsOpeners}
                selectedOpener={selectedOpener}
                setSelectedOpener={setSelectedOpener}
              />
              <ItemsInfo
                addItem={addItem}
                clinicId={dataQuote?.data?.data?.clinicId}
                setShowModal={setShowModal}
                setShowModalOpenItems={setShowModalOpenItems}
                setShowModalPackages={setShowModalPackages}
                quotation={quotation}
                setQuotation={setQuotation}
                isQuotePending={isQuotePending}
                isEditingItems={isEditingItems}
                setIsEditingItems={setIsEditingItems}
                openItems={openItems}
                setOpenItems={setOpenItems}
              />
            </QuotingCard>

            <TotalCard>
              <LateralCard>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.h4}
                  color={theme.colors.white}
                  mb="24px"
                >
                  Payment Method
                </Text>

                {paymentMethods &&
                  paymentMethods.length > 0 &&
                  paymentMethods.map((method, idx) => (
                    <Div
                      key={idx}
                      m="4px 0"
                      p="20px"
                      background={theme.colors.gray600}
                      radius="13px"
                      align="center"
                      justify="space-between"
                    >
                      <Text
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.default}
                        color={theme.colors.gray50}
                      >
                        {method}
                      </Text>
                      <Link onClick={() => handleDeletePaymentMethod(method)}>
                        <DeleteIcon stroke={theme.colors.gray50} />
                      </Link>
                    </Div>
                  ))}
                {paymentError && (
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.default}
                    color={theme.colors.red}
                    ml="10px"
                  >
                    Add a payment method
                  </Text>
                )}
                {!paidFull && !overDue && (
                  <Link
                    justify="center"
                    height="58px"
                    m="14px 0 0 0"
                    style={{
                      border: `1px dashed ${theme.colors.gray500}`,
                      "border-radius": "13px",
                      justifyContent: "center",
                    }}
                    onClick={() => setShowDrawer(true)}
                  >
                    <AddIcon stroke={theme.colors.green} />
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.default}
                      color={theme.colors.green}
                      ml="10px"
                    >
                      Edit payment method
                    </Text>
                  </Link>
                )}
              </LateralCard>
              <LateralCard>
                <Row align="center" justify="space-between" m="0 0 24px 0">
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h4}
                    color={theme.colors.white}
                  >
                    Amount due
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray50}
                  >
                    {"(USD)"}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Subtotal
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {formatPriceToDollar(
                      _.sumBy(
                        quotation?.items,
                        (item) => item.price * item.quantity
                      ) +
                        _.sumBy(openItems, (item) => item.price * item.quantity)
                    )}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Saving
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {formatPriceToDollar(
                      _.sumBy(quotation?.items, (item) => item.discount) +
                        _.sumBy(openItems, (item) => item.discount)
                    )}
                  </Text>
                </Row>
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="0 0 12px 0"
                >
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total Tax
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {formatPriceToDollar(
                      _.sumBy(quotation?.items, (item) => item.tax) +
                        _.sumBy(openItems, (item) => item.tax)
                    )}
                  </Text>
                </Row>
                {additionalDiscount.length > 0 &&
                  additionalDiscount.map((discount, index) => (
                    <Row
                      style={{
                        background: theme.colors.gray600,
                        "border-radius": "11px",
                        justifyContent: "space-between",
                      }}
                      p="10px 5px 10px 10px"
                      m="0 0 19px 0"
                    >
                      <Text
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.default}
                        color={theme.colors.gray50}
                        style={{ width: "185px" }}
                      >
                        {discount?.discountName
                          ? discount?.discountName
                          : "Additional discount"}
                      </Text>
                      <Row gap="3px">
                        <Text
                          weight={theme.fonts.weight.bold}
                          size={theme.fonts.size.default}
                          color={theme.colors.white}
                        >
                          {discount?.bonusValue}
                        </Text>
                        <Text
                          weight={theme.fonts.weight.bold}
                          size={theme.fonts.size.default}
                          color={theme.colors.white}
                        >
                          {discount?.bonusType === "dollar" ? "USD" : "%"}
                        </Text>
                        <Link
                          onClick={() =>
                            setAdditionalDiscount(
                              additionalDiscount.filter(
                                (_, idx) => index !== idx
                              )
                            )
                          }
                        >
                          <CloseIcon
                            stroke={theme.colors.gray400}
                            width="20px"
                            height="20px"
                          />
                        </Link>
                      </Row>
                    </Row>
                  ))}

                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="0 0 12px 0"
                >
                  <Checkbox
                    label={
                      discountConsultationValue?.name ||
                      "Frequent Additional Discount"
                    }
                    checked={discountConsultationFee}
                    onChange={(event) =>
                      handleCheckbox(event, "discountConsultationFee")
                    }
                    colorLabel={theme.colors.gray50}
                    sizeLabel={theme.fonts.size.default}
                    disabled={paidFull || overDue}
                  />
                </Row>
                {discountConsultationFee && isSuccessDataClinic && (
                  <Row
                    style={{
                      background: theme.colors.gray600,
                      "border-radius": "11px",
                      justifyContent: "space-around",
                    }}
                    p="10px 5px 10px 5px"
                    m="0 0 19px 0"
                  >
                    <Text
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray50}
                      style={{ width: "185px" }}
                    >
                      {discountConsultationValue.name}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.default}
                      color={theme.colors.white}
                    >
                      {`${discountConsultationValue.value} ${
                        discountConsultationValue.type === "dollar"
                          ? "USD"
                          : "%"
                      }`}
                    </Text>
                  </Row>
                )}
                <Div
                  height="1px"
                  width="100%"
                  background={theme.colors.gray500}
                  m="0 0 19px 0"
                />
                <Row align="center" justify="space-between" m="0 0 19px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h2}
                    color={theme.colors.green}
                  >
                    {calculateTotal()}
                  </Text>
                </Row>
                {!paidFull && !overDue && additionalDiscount.length < 4 && (
                  <Link
                    justify="center"
                    height="58px"
                    style={{
                      border: `1px dashed ${theme.colors.gray500}`,
                      "border-radius": "13px",
                      justifyContent: "center",
                    }}
                    onClick={() => setShowModalDiscount(true)}
                  >
                    <EditIcon stroke={theme.colors.green} />
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.default}
                      color={theme.colors.green}
                    >
                      Edit additional discount
                    </Text>
                  </Link>
                )}
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="20px 0 0 0"
                >
                  <Checkbox
                    label={"Show itemized pricing in Quote"}
                    checked={showItemizedPricing}
                    onChange={(event) =>
                      handleCheckbox(event, "itemizedPricing")
                    }
                    colorLabel={theme.colors.green}
                    sizeLabel={theme.fonts.size.sm}
                    weightLabel={theme.fonts.weight.bold}
                  />
                </Row>
              </LateralCard>
            </TotalCard>
          </Row>
        )}
        <Row align="center" m="35px 0px 37px 0px" gap={"14px"}>
          <Button
            variant={"outlined"}
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            onClick={() => handleSaveQuote(true)}
            width="155px"
            loading={saveQuote.isLoading}
          >
            Duplicate Quote
          </Button>
          {updateQuote ? (
            <>
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={() => handleSaveQuote(false)}
                width="180px"
                m={"0 10px 0 0"}
                loading={editQuote.isLoading}
              >
                Save Quote
              </Button>
              <Text
                weight={theme.fonts.weight.bold}
                size={theme.fonts.size.default}
                color={theme.colors.green}
              >
                To preview the quote as a pdf file please save the quotation
              </Text>
            </>
          ) : (
            <Button
              variant={"outlined"}
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={async () => {
                setModalPdfopen(true);
                const setUrl = await downloadPDF(quoteId);
                setPdfFile(setUrl);
              }}
              width="155px"
            >
              Preview
            </Button>
          )}
          {showSendByEmail && (
            <Button
              variant={"outlined"}
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={() => console.log("send by email")}
              width="155px"
            >
              Send by email
            </Button>
          )}
        </Row>
      </>
    </Div>
  );
};
