import { useQuery } from "react-query";
import { axiosClient } from "../../../core/services/axiosInstance";

const useGetPdfData = (quoteId) => {
  const data = useQuery(
    ["getPdfData", quoteId],
    () => quoteId && axiosClient.get(`/quote/get-pdf-data/${quoteId}`),
    {
      enabled: !!quoteId,
    }
  );
  return data;
};
export default useGetPdfData;
