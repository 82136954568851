import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  body: {
    paddingTop: 30,
    paddingBottom: 56,
    paddingHorizontal: 27,
  },
  row: {
    flexDirection: "row",
    gap: "5px",
  },
  header: {
    flexDirection: "row",
    marginBottom: 20,
    borderBottom: "1px solid #D9D9D9",
    paddingBottom: "2px",
    gap: "24px",
    alignItems: "center",
  },
  rowsHeader: {
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "10px",
  },
  firstColumn: {
    justifyContent: "center",
    width: "75px",
  },
  patientTitle: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "semibold",
    lineHeight: "normal",
  },
  patientName: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "semibold",
    lineHeight: "normal",
    color: "#363333",
  },
  invoiceTitle: {
    fontFamily: "Poppins",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "medium",
    lineHeight: "normal",
    color: "#363333",
    letterSpacing: "1.32px",
  },
  invoiceNumber: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#676464",
    letterSpacing: "0.4px",
  },
  iconSize: {
    height: "10px",
    width: "10px",
  },
  columnSocialInfo: {
    gap: "4px",
    justifyContent: "center",
  },
  socialInfo: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#676464",
    letterSpacing: "0.16px",
  },
  rowEffectiveDate: {
    flexDirection: "row",
  },
  dateTitles: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.16px",
    width: "60px",
    marginRight: "17px",
  },
  rowValidUntil: {
    flexDirection: "row",
  },
  image: {
    width: "130px",
    height: "117px",
    marginLeft: "auto",
    marginTop: "-20px",
  },

  packageTitle: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "semibold",
    lineHeight: "normal",
    color: "#363333",
    minWidth: "63px",
    maxWidth: "63px",
    marginRight: "23px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },

  packageTitleContainer: {
    textOverflow: "ellipsis",
  },

  table: {
    width: "57%",
  },
  tableHead: {
    flexDirection: "row",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    lineHeight: "normal",
    color: "#676464",
    borderBottom: "1px dashed #D9D9D9",
    marginBottom: "13px",
  },
  productsNames: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#363333",
    width: "300px",
  },
  productsQty: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#363333",
  },
  totalTittle: {
    flexDirection: "row",
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#676464",
    borderBottom: "1px dashed #D9D9D9",
    alignItems: "flex-end",
    marginBottom: "5px",
    width: "165px",
    justifyContent: "space-around",
  },
  totalQuoteText: {
    fontFamily: "Poppins",
    fontWeight: "semibold",
  },
  whiteArea: {
    width: "400px",
  },
  totalValues: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "14px",
  },
  taxSavignsValues: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "normal",
    color: "#363333",
    width: "40px",
    textAlign: "center",
  },
  totalFormat: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "semibold",
    lineHeight: "normal",
    color: "#363333",
    width: "50px",
    textAlign: "center",
  },
  footer: {
    borderTop: "1px solid #D9D9D9",
    position: "absolute",
    bottom: "32px",
    left: 0,
    right: 0,
    margin: "0 27px",
    padding: "13px 0",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  footerInfo: {
    flexDirection: "row",
    fontSize: "10px",
    textAlign: "center",
    color: "#363333",
    gap: "4px",
    flex: 1,
    justifyContent: "center",
  },
  resumeWrapper: {
    position: "relative",
    minHeight: "174px",
    marginBottom: 10,
    marginTop: "auto",
  },
  resume: {
    width: "100%",
    height: "174px",
    backgroundColor: "rgba(218, 218, 218, 0.35)",
    margin: "24px 0 17px 0",
    padding: "12px",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  qrBackground: {
    width: "127px",
    height: "148px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "6px",
  },
  qrImage: {
    width: "127px",
    height: "148px",
  },

  resumeTableTitle: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    lineHeight: "normal",
    textAlign: "center",
    borderBottom: "1px solid #969696",
    marginBottom: "6px",
    width: "170px",
    padding: "5px",
  },
  resumeTableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "170px",
    marginBottom: "5px",
  },
  paymentConcept: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#363333",
  },
  paymentValue: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "normal",
    textAlign: "right",
  },
  paymentAlert: {
    borderTop: "1px solid #969696",
    marginTop: "3px",
    width: "170px",
    padding: "5px 0",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  textAlert: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "normal",
    color: "#363333",
    lineHeight: "178.5%",
    letterSpacing: "0.16px",
  },
  text2Alert: {
    fontFamily: "Poppins",
    fontSize: "8px",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#363333",
    lineHeight: "178.5%",
    letterSpacing: "0.16px",
  },

  totalInvestment: {
    flexDirection: "row",
    borderTop: "1px solid #969696",
    marginTop: "3px",
    width: "170px",
    padding: "5px 0",
    textAlign: "center",
    justifyContent: "space-between",
  },
  totalDescription: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "semibold",
    color: "#363333",
  },
  totalValue: {
    fontFamily: "Poppins",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "semibold",
    color: "#363333",
  },

  pageNumber: {
    fontSize: "8px",
    textAlign: "right",
    color: "#676464",
  },
});
