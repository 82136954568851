import React from "react";
import { Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { CustomDatePicker } from "./styles";
import { ConfigProvider } from "antd";

export const DatePicker = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  sizeLabel,
  weightLabel,
  colorLabel,
  ...rest
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: theme.colors.gray700,
          colorBgContainerDisabled: theme.colors.gray950,
          colorPrimary: theme.colors.purple400,
          colorText: theme.colors.gray400,
          colorTextHeading: theme.colors.gray400,
          colorTextDisabled: theme.colors.gray500,
          colorTextLightSolid: theme.colors.white,
          colorTextTertiary: theme.colors.purple200,
          controlItemBgActive: theme.colors.purple400T,
          controlItemBgHover: theme.colors.purple,
          activeBorderColor: theme.colors.purple200,
          colorLink: theme.colors.purple200,
        },
      }}
    >
      <div style={{ width }}>
        {label && (
          <Text
            weight={weightLabel}
            size={sizeLabel || theme.fonts.size.sm}
            color={colorLabel || theme.colors.gray500}
            mt="0px"
            mb="7px"
            ml="6px"
            align="left"
          >
            {label}
          </Text>
        )}
        <CustomDatePicker
          placeholder={placeholder}
          suffix={suffix}
          status={error ? "error" : ""}
          disabled={disabled}
          onChange={onChange}
          format="MM-DD-YYYY"
          style={{ width: width }}
          className={helper ? "has-helper" : ""}
          {...rest}
        />
        {helper && (
          <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
            {helper}
          </Text>
        )}
      </div>
    </ConfigProvider>
  );
};
