import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { Select } from "../../../../components/Select";
import { theme } from "../../../../styles/theme";
import useGetPackageById from "../../../Packages/EditPackage/hooks/useGetPackageById";

const ModalPackages = ({
  showModal,
  handleCloseModal,
  clinicTax,
  optionsPackages,
  quotation,
  setQuotation,
  addItem,
  openItems,
  setOpenItems,
}) => {
  const [selectedPackage, setSelectedPackage] = useState({});
  const [error, setError] = useState(false);

  const getPackage = useGetPackageById(selectedPackage?.value);
  const { data: dataPackage, isLoading: isLoadingPackage } = getPackage;

  const handleSelect = (value, item) => {
    setError(false);
    setSelectedPackage({
      value: item.value,
      label: item.label?.props?.children,
    });
  };

  const handleAddPackage = () => {
    toast.remove();

    if (!selectedPackage?.value) {
      setError(true);
    } else {
      setError(false);
      const prevProductTreatments = quotation?.items
        ? Object.values(quotation.items)
            .filter((item) => item.package !== dataPackage?.data?.data.name)
            .map((item) => ({
              id: Number(item.productTreatmentId),
              quantity: item.quantity,
              package: item.package,
              discount: item.discount,
            }))
        : [];
      const newProductTreatments =
        dataPackage?.data?.data.productsTreatments.map((item) => ({
          id: Number(item.productTreatmentId),
          quantity: Number(item.quantity),
          package: dataPackage?.data?.data.name,
          discount: item.discount,
        }));
      const productsTreatments = [
        ...prevProductTreatments,
        ...newProductTreatments,
      ];

      const prevBelongToPkg = quotation?.items.map((item) => ({
        id: item.productTreatmentId,
        quantity: item.quantity,
        package: item.package,
        belongsToPkg: item.belongsToPkg,
        discount: item.discount,
      }));

      const newOpenItems = dataPackage?.data?.data.openItems.map((item) => ({
        quantity: Number(item.quantity),
        isOpenItem: true,
        discount: item.discount,
        tax: item.tax ? (clinicTax / 100) * item.price : 0,
        type: item.type,
        name: item.name,
        price: item.price,
        isTaxIncluded: item.tax,
        package: dataPackage?.data?.data.name,
        belongsToPkg: true,
      }));
      const filteredOpenItems = openItems.filter(
        (item) => item.package !== dataPackage?.data?.data.name
      );

      addItem.reset();
      addItem.mutate(
        { productsTreatments },
        {
          onSuccess: (res) => {
            const updatedItems = res.data.data.items.map((item) => {
              const prevItem = prevBelongToPkg.find(
                (prevBelong) =>
                  prevBelong.id === item.productTreatmentId &&
                  prevBelong.package === item.package
              );

              const prevItemDiscount = newProductTreatments.find(
                (newPackageItem) =>
                  newPackageItem.id === Number(item.productTreatmentId)
              );
              if (prevItem) {
                return {
                  ...item,
                  tax: Number(item.tax),
                  belongsToPkg: prevItem.belongsToPkg,
                  discount: prevItem?.discount,
                };
              } else {
                return {
                  ...item,
                  tax: Number(item.tax),
                  belongsToPkg: true,
                  discount: prevItemDiscount?.discount
                    ? prevItemDiscount.discount
                    : item.discount,
                };
              }
            });

            setQuotation({
              ...res.data.data,
              items: updatedItems,
            });
            setOpenItems([...filteredOpenItems, ...newOpenItems]);
            if (dataPackage?.data?.data?.additionalDiscountValue > 0) {
              const newItem = {
                bonusType: dataPackage?.data?.data?.additionalDiscountType,
                bonusValue: dataPackage?.data?.data?.additionalDiscountValue,
                discountName: dataPackage?.data?.data.name,
              };
            }

            setSelectedPackage({});
            handleCloseModal();
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                "Error trying to add a package"
            );
          },
        }
      );
    }
  };

  return (
    <Modal
      open={showModal}
      onCancel={() => {
        setError(false);
        setSelectedPackage({});
        handleCloseModal();
      }}
      width={"405px"}
    >
      <Modal.Header
        title={"Add Package to Quote"}
        align="-webkit-center"
        weightTitle={theme.fonts.weight.semibold}
        margin={"24px 0 0 0"}
      />
      <Modal.Body
        margin="18px 0 0 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" justify="center">
          <Row width="100%" m="0 0 14px 0" justify="center">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              Select one of the pre-built packages below to add to the patient's
              quote:
            </Text>
          </Row>

          <Row width="100%" m="17px 0px 24px 0px">
            <Select
              label={"Packages"}
              id="packages"
              options={optionsPackages}
              onChange={(val, item) => handleSelect(val, item)}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"350px"}
              defaultValue=""
              value={selectedPackage}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
              error={error}
              helper={error ? "Select a package" : ""}
            />
          </Row>

          <Row width="100%" p="0px 0px 16px 0px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleAddPackage}
              width="100%"
              loading={addItem.isLoading}
              variant="filled"
            >
              Add Pre-Built Package
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPackages;
