import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PublicRoute } from "../components/PublicRoute/PublicRoute";

import { App } from "./App";
import { Login } from "./Login";
import { ResetPassword } from "./ResetPassword";
import { ResetPasswordRequest } from "./ResetPasswordRequest";

import { Home } from "./Home";
import { Clinics } from "./Clinics";
import { CreateClinic } from "./Clinics/CreateClinic";
import { Clinic } from "./Clinics/Clinic";
import { EditClinic } from "./Clinics/EditClinic";
import { Users } from "./Users";
import { CreateUser } from "./Users/CreateUser";
import { EditUser } from "./Users/EditUser";
import { Patients } from "./Patients";
import { Patient } from "./Patients/Patient";
import { Categories } from "./Categories";
import { Products } from "./Products";
import { Treatments } from "./Treatments";
import { Discounts } from "./Discounts";
import { Plans } from "./Plans";
import { QuoteHistory } from "./Quote History";
import { QuotingTool } from "./Quoting Tool";
import { Profile } from "./Profile";
import { EditProfile } from "./Profile/EditProfile";
import { EditQuotation } from "./Quote History/EditQuotation";
import { CreatePackage } from "./Packages/CreatePackage";
import { Packages } from "./Packages";
import { EditPackage } from "./Packages/EditPackage";
import { GetHelp } from "./GetHelp";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route
            index
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="reset-password-request"
            element={
              <PublicRoute>
                <ResetPasswordRequest />
              </PublicRoute>
            }
          />

          <Route
            path="reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />

          <Route path="home" element={<Home />} />
          <Route path="clinics" element={<Clinics />} />
          <Route path="clinics/create-clinic" element={<CreateClinic />} />
          <Route path="clinics/view-clinic" element={<Clinic />} />
          <Route path="clinics/edit-clinic" element={<EditClinic />} />
          <Route path="users" element={<Users />} />
          <Route path="users/create-user" element={<CreateUser />} />
          <Route path="users/edit-user" element={<EditUser />} />
          <Route path="patients" element={<Patients />} />
          <Route path="patients/view-patient" element={<Patient />} />
          <Route path="categories" element={<Categories />} />
          <Route path="products" element={<Products />} />
          <Route path="treatments" element={<Treatments />} />
          <Route path="discounts" element={<Discounts />} />
          <Route path="plans" element={<Plans />} />
          <Route path="packages" element={<Packages />} />
          <Route path="packages/create-package" element={<CreatePackage />} />
          <Route path="packages/edit-package" element={<EditPackage />} />
          <Route path="quote-history" element={<QuoteHistory />} />
          <Route path="get-help" element={<GetHelp />} />
          <Route
            path="quote-history/edit-quotation"
            element={<EditQuotation />}
          />
          <Route path="quoting-tool" element={<QuotingTool />} />
          <Route path="profile" element={<Profile />} />
          <Route path="profile/edit-profile" element={<EditProfile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
