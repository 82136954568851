import React, { useState } from "react";
import axios from "axios";
import { theme } from "../../styles/theme";
import { Row, Container, Div, Text, Col } from "../../styles/Common";
import { Card } from "../../components/Card";
import imageGetHelp from "../../assets/images/imageGetHelp.svg";
import { Input } from "../../components/Input";
import { TextArea } from "../../components/TextArea";
import { Button } from "../../components/Button";
import toast from "react-hot-toast";
import { validateGetHelpForm } from "../../utilities/validations";
import { getUserInfo, getIp } from "../../utilities/helpers";
import usePostToZapier from "./hooks/usePostToZapier";

export const GetHelp = () => {
  const postToZapier = usePostToZapier();

  const [getHelpForm, setGetHelpForm] = useState({ email: "", message: "" });
  const [forceUpdate, setForceUpdate] = useState(false);
  const [errorForm, setErrorForm] = useState({
    email: {
      error: false,
      message: "",
    },
    message: {
      error: false,
      message: "",
    },
  });

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newGetHelpForm = getHelpForm;
    newGetHelpForm[id] = value;
    setGetHelpForm(newGetHelpForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSave = async () => {
    toast.remove();

    const validation = validateGetHelpForm.validate(getHelpForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      postToZapier.mutate(
        {
          email: getHelpForm.email,
          message: getHelpForm.message,
          date: new Date().toLocaleDateString("en-US"),
          time: new Date().toLocaleTimeString(),
          ip: await getIp(),
          practice: getUserInfo()?.clinic?.name,
        },
        {
          onSuccess: () => {
            toast.success("Form submitted  successfully");
          },
          onError: (err) => {
            toast.error(
              err.response?.data?.error?.description ||
                err.response?.data?.error?.message ||
                "The form could not be sent"
            );
          },
        }
      );
    }
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Row
        height="40px"
        align="center"
        m="0px 0px 30px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Get Help
          </Text>
        </Div>
      </Row>
      <Card
        background={theme.colors.gray800}
        width="100%"
        height="550px"
        radius="13.4px"
        padding="83.14px 63px 45px 63px"
        style={{ flexDirection: "row", display: "flex", gap: "69px" }}
      >
        <Col width="50%">
          <Text
            color={theme.colors.white}
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.default}
            mb="11.2px"
          >
            Ask anything, we’re here to help.
          </Text>
          <Text color={theme.colors.white} size={theme.fonts.size.xs} mb="17px">
            Let us know what questions or issues you’re facing, and we’ll get
            back to you as soon as possible. Include all relevant details, as we
            can’t see what you’re seeing.
          </Text>
          <Input
            label={"Email address"}
            id="email"
            width={"100%"}
            placeholder={"Required – so we can reach you back."}
            style={{ margin: " 0 0 19.5px 0" }}
            background={theme.colors.gray600}
            colorPlaceholder={theme.colors.gray400}
            color={theme.colors.gray400}
            onChange={handleChange}
            error={errorForm.email.error}
            helper={errorForm.email.error ? errorForm.email.message : ""}
            value={getHelpForm.email}
          />
          <TextArea
            label={"Describe your issue or question in detail."}
            id="message"
            width={"100%"}
            margin=" 0 0 12px 0"
            background={theme.colors.gray600}
            color={theme.colors.gray400}
            onChange={handleChange}
            error={errorForm.message.error}
            helper={errorForm.message.error ? errorForm.message.message : ""}
            value={getHelpForm.message}
          />
          <Row justify="end">
            <Button
              width="119.399px"
              height="38.964px"
              onClick={handleSave}
              loading={postToZapier.isLoading}
            >
              Get Help
            </Button>
          </Row>
        </Col>
        <Col width="50%" align="center" justify="center">
          <img
            src={imageGetHelp}
            alt="image Get Help"
            width="318.396px"
            height="295.227px"
          />
        </Col>
      </Card>
    </Container>
  );
};
