import React, { useState, useMemo } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { max, range } from "lodash";
import { Container, Row, Div, Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import WithoutDiscountCard from "./components/WithoutDiscountCard";
import DiscountCard from "./components/DiscountCard";
import useGetDiscounts from "./hooks/useGetDiscounts";
import useAddDiscount from "./hooks/useAddDiscount";
import useUpdateDiscount from "./hooks/useUpdateDiscount";
import useCategory from "../../core/hooks/useCategory";
import { useSelector } from "react-redux";
import { isSuperAdmin, userClinic } from "../../utilities/helpers";
import { Select } from "../../components/Select";
import { Button } from "../../components/Button";
import { Skeleton } from "antd";
import useParameters from "../../core/hooks/useParameters";
import useAddDiscountWithout from "./hooks/useAddDiscountWithout";
import useDeleteDiscount from "./hooks/useDeleteDiscount";
import ModalDelete from "./components/ModalDelete";

export const Discounts = () => {
  const superAdmin = isSuperAdmin();
  const { id: userClinicId } = userClinic();
  const { common } = useSelector((state) => state);
  const queryClient = useQueryClient();
  const optionsClinics = common?.clinicsList || {};
  const [listDiscounts, setListDiscounts] = useState([]);
  const [listDiscountsDB, setListDiscountsDB] = useState([]);
  const [listWithoutDiscounts, setListWithoutDiscounts] = useState([]);
  const [listCategories, setListCategories] = useState([]);
  const [listProductsTreatments, setListProductsTreatments] = useState([]);
  const [isOpenCard, setIsOpenCard] = useState({});
  const [clinicId, setClinicId] = useState(superAdmin ? null : userClinicId);
  const [forceUpdate, setForceUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState();

  const addDiscount = useAddDiscount();
  const updateDiscount = useUpdateDiscount();
  const addWithoutDiscount = useAddDiscountWithout();
  const deleteDiscount = useDeleteDiscount();

  const AddDiscountByDefault = (list) => {
    const newListDefault = list.map((item) => {
      let obj = JSON.parse(JSON.stringify(item));

      if (
        obj.details.filter((eleType) => eleType.type === "amount").length === 0
      ) {
        obj = {
          ...obj,
          details: [...obj.details, { type: "amount", amount: 0, discount: 0 }],
        };
      }
      if (
        obj.details.filter((eleType) => eleType.type === "quantity_products")
          .length === 0
      ) {
        obj = {
          ...obj,
          details: [
            ...obj.details,
            { type: "quantity_products", amount: 0, discount: 0 },
          ],
        };
      }
      obj = {
        ...obj,
        details: obj.details.map((det, index) => ({ idDetail: index, ...det })),
      };
      return obj;
    });

    return newListDefault;
  };

  const { isLoading } = useGetDiscounts({
    onSuccess: (apiData) => {
      const dataDisc = apiData.data.data.discounts;
      const dataWithoutDisc = apiData.data.data.without_discounts;
      const objectOpen = {};
      dataDisc.map((disc) => {
        objectOpen[disc.id] = false;
      });
      setListDiscountsDB(dataDisc);

      setListDiscounts(AddDiscountByDefault(dataDisc));
      setListWithoutDiscounts(dataWithoutDisc);
      setIsOpenCard(objectOpen);
    },
  });

  const { isLoading: isLoadingCat } = useCategory({
    onSuccess: (apiData) => {
      setListCategories(apiData.data.data);
    },
  });

  const { isLoading: isLoadingParameters } = useParameters(
    "products_treatments",
    {
      onSuccess: (apiData) => {
        setListProductsTreatments(apiData.data.data.products_treatments);
      },
    }
  );

  const filterCat = useMemo(() => {
    if (listCategories) {
      const filterCat = listCategories.filter(
        (catEle) => Number(catEle.clinic.id) === Number(clinicId)
      );
      return filterCat.map((filterEle) => ({
        value: filterEle.id,
        label: filterEle.name,
      }));
    }
    return [];
  }, [clinicId, listCategories]);

  const filterProductTreatmentList = useMemo(() => {
    if (listProductsTreatments) {
      const filterProdTreat = listProductsTreatments.filter(
        (ptEle) => Number(ptEle.clinicId) === Number(clinicId)
      );
      return filterProdTreat.map((filterEle) => ({
        value: filterEle.id,
        label: filterEle.name,
        cat: filterEle.categoryId,
      }));
    }
    return [];
  }, [clinicId, listProductsTreatments]);

  const handleOpenCard = (idCard) => {
    const arrayKeys = Object.keys(isOpenCard);
    const objectOpen = {};
    arrayKeys.map((idDisc) => {
      objectOpen[idDisc] =
        Number(idDisc) === Number(idCard) && !isOpenCard[idDisc];
    });
    setIsOpenCard(objectOpen);
  };

  const handleAddDiscount = () => {
    const newList = JSON.parse(JSON.stringify(listDiscounts));
    newList.push({
      categoryId: null,
      clinicId: clinicId,
      details: [
        { idDetail: 0, type: "quantity_products", amount: 0, discount: 0 },
        { idDetail: 1, type: "amount", amount: 0, discount: 0 },
      ],
      id: -1,
      type: "dollar",
      type_amount: "dollar",
    });
    setListDiscounts(newList);
    const objectOpen = {};
    newList.map((disc) => {
      objectOpen[disc.id] = false;
    });

    setIsOpenCard(objectOpen);
    setForceUpdate(!forceUpdate);
  };

  const handleCancelDiscount = () => {
    const newList = JSON.parse(JSON.stringify(listDiscounts));
    newList.pop();
    setListDiscounts(newList);
    const objectOpen = {};
    newList.map((disc) => {
      objectOpen[disc.id] = false;
    });

    setIsOpenCard(objectOpen);
    setForceUpdate(!forceUpdate);
  };

  const handleCancel = (id) => {
    if (Number(id) > 0) {
      const DBvalues = listDiscountsDB.find(
        (ele) => Number(ele.id) === Number(id)
      );
      const indexCancel = listDiscounts.findIndex(
        (ele) => Number(ele.id) === Number(id)
      );

      const newList = JSON.parse(JSON.stringify(listDiscounts));
      newList[indexCancel] = DBvalues;
      setListDiscounts(newList);
      setForceUpdate(!forceUpdate);
    } else {
      handleCancelDiscount();
    }
  };

  const handleChangeCat = (val, id) => {
    const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
    const indexChange = listDiscounts.findIndex(
      (ele) => Number(ele.id) === Number(id)
    );
    const newList = JSON.parse(JSON.stringify(listDiscounts));
    newList[indexChange] = { ...values, categoryId: val };
    setListDiscounts(newList);
    setForceUpdate(!forceUpdate);
  };

  const handleAddDiscountType = (id, typeDis) => {
    const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
    const indexChange = listDiscounts.findIndex(
      (ele) => Number(ele.id) === Number(id)
    );
    const maxIndex = max(values.details.map((det) => Number(det.idDetail)));
    const newList = JSON.parse(JSON.stringify(listDiscounts));
    newList[indexChange] = {
      ...values,
      details: [
        ...values.details,
        {
          idDetail: maxIndex + 1,
          type: typeDis,
          amount: 0,
          discount: 0,
        },
      ],
    };
    setListDiscounts(newList);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeInput = (event, idDetail, idDiscount) => {
    const values = listDiscounts.find(
      (ele) => Number(ele.id) === Number(idDiscount)
    );
    const indexChange = listDiscounts.findIndex(
      (ele) => Number(ele.id) === Number(idDiscount)
    );

    const newList = JSON.parse(JSON.stringify(listDiscounts));
    const valuesDetails = values.details.find(
      (ele) => Number(ele.idDetail) === Number(idDetail)
    );
    const indexDetailChange = values.details.findIndex(
      (ele) => Number(ele.idDetail) === Number(idDetail)
    );
    const newDetails = JSON.parse(JSON.stringify(values.details));
    if (event.target.id === "amount") {
      newDetails[indexDetailChange] = {
        ...valuesDetails,
        amount: event.target.value,
      };
    } else {
      newDetails[indexDetailChange] = {
        ...valuesDetails,
        discount: event.target.value,
      };
    }

    newList[indexChange] = { ...values, details: newDetails };
    setListDiscounts(newList);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeName = (event, idDiscount) => {
    setListDiscounts((prevListDiscounts) => {
      return prevListDiscounts.map((item) => {
        if (Number(item.id) === Number(idDiscount)) {
          return { ...item, name: event.target.value };
        }
        return item;
      });
    });
  };

  const handleDiscountType = (event, id) => {
    const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
    const indexChange = listDiscounts.findIndex(
      (ele) => Number(ele.id) === Number(id)
    );
    const newList = JSON.parse(JSON.stringify(listDiscounts));
    switch (event.target.name) {
      case "bonusType":
        newList[indexChange] = { ...values, type: event.target.value };
        break;
      case "bonusTypeAmount":
        newList[indexChange] = { ...values, type_amount: event.target.value };
        break;
    }
    setListDiscounts(newList);
    setForceUpdate(!forceUpdate);
  };

  const handleOpenModal = (discountId) => {
    setSelectedDiscountId(discountId);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setSelectedDiscountId();
    setShowModal(false);
  };

  const handleRemoveItemDiscount = (idDetailRemove, id) => {
    const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
    const indexChange = listDiscounts.findIndex(
      (ele) => Number(ele.id) === Number(id)
    );
    const newDetails = values.details.filter(
      (det) => Number(det.idDetail) !== Number(idDetailRemove)
    );

    const newList = JSON.parse(JSON.stringify(listDiscounts));
    newList[indexChange] = {
      ...values,
      details: newDetails,
    };
    setListDiscounts(newList);
    setForceUpdate(!forceUpdate);
  };

  const handleSave = (id) => {
    if (id < 0) {
      const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
      addDiscount.reset();
      addDiscount.mutate(
        {
          type: values.type,
          type_amount: values.type_amount,
          categoryId: Number(values.categoryId),
          clinicId: Number(values.clinicId),
          name: values.name,
          details: values.details.map((det) => ({
            type: det.type,
            amount: Number(det.amount),
            discount: Number(det.discount),
          })),
        },
        {
          onSuccess: () => {
            toast.success("The discount has been successfully saved");
            queryClient.invalidateQueries(["discountsList"]);
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.message ||
                "Error trying to add a discount"
            );
          },
        }
      );
    } else {
      const values = listDiscounts.find((ele) => Number(ele.id) === Number(id));
      updateDiscount.reset();
      updateDiscount.mutate(
        {
          id: values.id,
          type: values.type,
          type_amount: values.type_amount,
          categoryId: Number(values.categoryId),
          clinicId: Number(values.clinicId),
          name: values.name,
          details: values.details.map((det) => ({
            type: det.type,
            amount: Number(det.amount),
            discount: Number(det.discount),
          })),
        },
        {
          onSuccess: () => {
            toast.success("The discount has been successfully saved");
            queryClient.invalidateQueries(["discountsList"]);
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.message ||
                "Error trying to update the discount"
            );
          },
        }
      );
    }
  };

  const handleSaveWithout = (idsPT) => {
    const values = idsPT.map((ele) => Number(ele.productTreatmentId));
    addWithoutDiscount.reset();
    addWithoutDiscount.mutate(
      {
        clinicId: Number(clinicId),
        productTreatmentIds: values,
      },
      {
        onSuccess: () => {
          toast.success("The change has been successfully saved");
          queryClient.invalidateQueries(["discountsList"]);
        },
        onError: (err) => {
          toast.error(
            err.response.data.error.message ||
              "Error trying to exclude products and treatments for discounts"
          );
        },
      }
    );
  };

  const isLoadingApp =
    isLoading ||
    isLoadingCat ||
    addDiscount.isLoading ||
    updateDiscount.isLoading;

  return (
    <Container>
      <ModalDelete
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedDiscountId={selectedDiscountId}
      />
      <Row height="40px" justify="space-between" align="center" m="0 0 24px 0">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Discounts
          </Text>
        </Div>
        {superAdmin && (
          <Select
            label={"Select Practice"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray800}
            background={theme.colors.gray800}
            color={theme.colors.gray400}
            width={"348px"}
            defaultValue=""
            value={clinicId}
            options={optionsClinics}
            onChange={(val) => {
              setClinicId(val);
              setForceUpdate(!forceUpdate);
            }}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.label?.props?.children || "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
          />
        )}
      </Row>
      {superAdmin && !clinicId && (
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h4}
          color={theme.colors.green}
        >
          Select a practice to view the discounts
        </Text>
      )}
      {clinicId && (
        <Row m="30px 0 0 0 " gap="16px">
          <Div direction="column" gap="24px" width="579px">
            {isLoadingApp &&
              range(0, 4, 1).map(() => (
                <Skeleton.Input
                  active
                  block
                  size="large"
                  style={{
                    height: "92px",
                    borderRadius: "25px",
                    backgroundColor: theme.colors.gray800,
                  }}
                />
              ))}
            {!isLoadingApp &&
              listDiscounts
                .filter((dis) => Number(dis.clinicId) === Number(clinicId))
                .map((discount, index) => (
                  <DiscountCard
                    key={index}
                    item={discount}
                    index={index + 1}
                    catName={
                      listCategories.find(
                        (cat) => Number(cat.id) === Number(discount.categoryId)
                      )?.name || ""
                    }
                    isOpen={isOpenCard[discount.id]}
                    catList={filterCat}
                    handleDelete={handleOpenModal}
                    handleDiscountType={handleDiscountType}
                    handleSave={handleSave}
                    handleOpenCard={handleOpenCard}
                    handleChangeCat={handleChangeCat}
                    handleCancel={handleCancel}
                    handleAddDiscountType={handleAddDiscountType}
                    handleChangeInput={handleChangeInput}
                    handleRemoveItemDiscount={handleRemoveItemDiscount}
                    handleChangeName={handleChangeName}
                  />
                ))}
            <Div gap="10px">
              <Button
                variant={"outlined"}
                width="194px"
                padding="0px 0px 0px 16px"
                radius="28px"
                weight={theme.fonts.weight.semibold}
                onClick={handleAddDiscount}
                disabled={listDiscounts.find((ele) => ele.id === -1)}
              >
                Add discount
              </Button>
              {listDiscounts.find((ele) => ele.id === -1) && (
                <Button onClick={handleCancelDiscount}>Cancel</Button>
              )}
            </Div>

            {listDiscounts.find((ele) => ele.id === -1) && (
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.xs}
                color={theme.colors.white}
              >
                Save the new discount or cancel before add a new one
              </Text>
            )}
          </Div>
          <WithoutDiscountCard
            catList={filterCat}
            productsTreatmentsList={filterProductTreatmentList}
            clinicId={clinicId}
            withoutList={listWithoutDiscounts}
            handleSaveWithout={handleSaveWithout}
          />
        </Row>
      )}
    </Container>
  );
};
