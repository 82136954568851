import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Col, Row, Text } from "../../styles/Common";
import { Card } from "../../components/Card";
import { Modal } from "../../components/Modal";
import { theme } from "../../styles/theme";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import useSendEmailResetPassword from "./hooks/useSendEmailResetPassword";
import { validateEmailRequired } from "../../utilities/validations";
import { EmailOpenedIcon, PasswordIcon } from "../../assets/icons";
import imageForgotPassword from "../../assets/images/imageForgotPassword.svg";
import logo from "../../assets/images/dividermLog.svg";
import { Link } from "../../components/Link";

export const ResetPasswordRequest = () => {
  const navigate = useNavigate();
  const resetPassword = useSendEmailResetPassword();
  const { isLoading } = resetPassword;
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorValidation, setErrorValidation] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [forceUpdate, setForceUpdate] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    setError(false);
    setForceUpdate(!forceUpdate);
  };

  const handleSend = () => {
    setShowModal(false);
    toast.remove();
    const validation = validateEmailRequired.email.validate(email, {
      abortEarly: false,
    });

    if (validation.error) {
      setError(true);
      setErrorValidation("Invalid email");
      setForceUpdate(!forceUpdate);
    } else {
      resetPassword.reset();
      resetPassword.mutate(email, {
        onSuccess: () => {
          setShowModal(true);
        },
        onError: (err) => {
          toast.error(err.response.data.error.message);
        },
      });
    }
  };

  const handleLogin = () => {
    navigate("/");
  };

  const handleClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <>
      <Modal open={showModal} onCancel={handleClose} width={"406px"} centered>
        <Modal.Header
          icon={
            <PasswordIcon
              fill={theme.colors.green}
              width={"50px"}
              height={"39px"}
            />
          }
          bordercolor={theme.colors.green}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"39px"}
          align="-webkit-center"
          padding={"26px 0 16px 0"}
          title={"Check your email"}
        />
        <Modal.Body
          margin="5px 0 30px 0"
          height={425}
          width={417}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0 20px 0px 20px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.blac100}
              >
                We sent a reset link. If you don’t see it, check your spam
                folder.
              </Text>
            </Row>
            <Row width="100%" p="24px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleSend}
                width="100%"
                loading={isLoading}
                background={theme.colors.green}
                color={theme.colors.white}
                hBackground={theme.colors.green600}
                hActiveBackground={theme.colors.white}
                hActvieBorder={theme.colors.gray500}
                hActviecolor={theme.colors.gray500}
              >
                Resend Link
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <>
        <Row
          width="100%"
          height="100%"
          style={{ background: theme.colors.white50 }}
        >
          <Col width="50%" height="100vh" align="center" justify="center">
            <img
              src={imageForgotPassword}
              alt="image Forgot Password"
              width="573px"
              height="580px"
            />
          </Col>
          <Col width="50%" height="100vh" align="center" justify="center">
            <Card
              width="490px"
              height="500px"
              align="center"
              justify="center"
              padding="57px 45.9px 0px 45px"
              style={{
                borderRadius: "23px",
                background:
                  "linear-gradient(121deg, rgba(255, 255, 255, 0.45) -42.27%, rgba(217, 217, 217, 0.45) 145.68%)",
                border: "1.5px solid rgba(0, 0, 0, 0.55)",
              }}
            >
              <Row width="100%" justify="center">
                <img alt="logo" src={logo} width="250px" height="75px" />
              </Row>

              <Row width="100%" m="24px 0 0 0" p="0px 18px 0px 18px">
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.h5}
                  color={theme.colors.black}
                >
                  We get it, running an aesthetic practice keeps you busy. Enter
                  your email to get a secure reset link.
                </Text>
              </Row>
              <Row width="100%" m="24px 0 0 0">
                <Input
                  label="Email address"
                  placeholder={"you@example.com"}
                  background={theme.colors.white50}
                  id="email"
                  width="100%"
                  weightLabel={theme.fonts.weight.regular}
                  onChange={handleChange}
                  error={error}
                  helper={error ? errorValidation : ""}
                />
              </Row>

              <Row m="33px 0 0 0">
                <Button
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.semibold}
                  onClick={handleSend}
                  width="100%"
                  loading={isLoading}
                  background={theme.colors.green}
                  color={theme.colors.white}
                  hBackground={theme.colors.green600}
                  hActiveBackground={theme.colors.white}
                  hActvieBorder={theme.colors.gray500}
                  hActviecolor={theme.colors.gray500}
                >
                  Send Link
                </Button>
              </Row>
              <Row width="100%" m="16px 0 0 0" justify="center" align="center">
                <Link
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.medium}
                  onClick={handleLogin}
                  width="100%"
                  color={theme.colors.gray600}
                  style={{ justifyContent: "center" }}
                  underline
                >
                  Back
                </Link>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    </>
  );
};
