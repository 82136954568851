import React from "react";
import {
  Page,
  Document,
  View,
  Image,
  Text,
  Font,
  Svg,
  Path,
} from "@react-pdf/renderer";
import _ from "lodash";
import { styles } from "./styles";
import { theme } from "../../../../styles/theme";
import { formatPriceToDollarPdf } from "../../../../utilities/helpers";
import poppinsRegular from "../../../../assets/fonts/Poppins-Regular.ttf";
import poppinsMedium from "../../../../assets/fonts/Poppins-Medium.ttf";
import poppinsSemibold from "../../../../assets/fonts/Poppins-SemiBold.ttf";
import poppinsBold from "../../../../assets/fonts/Poppins-Bold.ttf";

Font.register({
  family: "Poppins",
  src: poppinsRegular,
});
Font.register({
  family: "Poppins",
  src: poppinsMedium,
  fontWeight: "medium",
});
Font.register({
  family: "Poppins",
  src: poppinsSemibold,
  fontWeight: "semibold",
});
Font.register({
  family: "Poppins",
  src: poppinsBold,
  fontWeight: "bold",
});

export const CreateQuotePDF = ({ pdfData }) => {
  const PhoneSvg = () => (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <Path
        d="M2.01111 4.32778C2.81111 5.9 4.1 7.18889 5.67222 7.98889L6.89444 6.76667C7.05 6.61111 7.26667 6.56667 7.46111 6.62778C8.08333 6.83333 8.75 6.94444 9.44444 6.94444C9.59179 6.94444 9.7331 7.00298 9.83728 7.10716C9.94147 7.21135 10 7.35266 10 7.5V9.44444C10 9.59179 9.94147 9.7331 9.83728 9.83728C9.7331 9.94147 9.59179 10 9.44444 10C6.93962 10 4.53739 9.00496 2.76621 7.23379C0.995037 5.46261 0 3.06038 0 0.555556C0 0.408213 0.0585316 0.266905 0.162718 0.162718C0.266905 0.0585316 0.408213 0 0.555556 0H2.5C2.64734 0 2.78865 0.0585316 2.89284 0.162718C2.99702 0.266905 3.05556 0.408213 3.05556 0.555556C3.05556 1.25 3.16667 1.91667 3.37222 2.53889C3.43333 2.73333 3.38889 2.95 3.23333 3.10556L2.01111 4.32778Z"
        fill={pdfData?.brandColor || theme.colors.green}
      />
    </Svg>
  );

  const InstaSvg = () => (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <Path
        d="M2.9 0H7.1C8.7 0 10 1.3 10 2.9V7.1C10 7.86913 9.69447 8.60675 9.15061 9.15061C8.60675 9.69447 7.86913 10 7.1 10H2.9C1.3 10 0 8.7 0 7.1V2.9C0 2.13087 0.305535 1.39325 0.84939 0.84939C1.39325 0.305535 2.13087 0 2.9 0ZM2.8 1C2.32261 1 1.86477 1.18964 1.52721 1.52721C1.18964 1.86477 1 2.32261 1 2.8V7.2C1 8.195 1.805 9 2.8 9H7.2C7.67739 9 8.13523 8.81036 8.47279 8.47279C8.81036 8.13523 9 7.67739 9 7.2V2.8C9 1.805 8.195 1 7.2 1H2.8ZM7.625 1.75C7.79076 1.75 7.94973 1.81585 8.06694 1.93306C8.18415 2.05027 8.25 2.20924 8.25 2.375C8.25 2.54076 8.18415 2.69973 8.06694 2.81694C7.94973 2.93415 7.79076 3 7.625 3C7.45924 3 7.30027 2.93415 7.18306 2.81694C7.06585 2.69973 7 2.54076 7 2.375C7 2.20924 7.06585 2.05027 7.18306 1.93306C7.30027 1.81585 7.45924 1.75 7.625 1.75ZM5 2.5C5.66304 2.5 6.29893 2.76339 6.76777 3.23223C7.23661 3.70107 7.5 4.33696 7.5 5C7.5 5.66304 7.23661 6.29893 6.76777 6.76777C6.29893 7.23661 5.66304 7.5 5 7.5C4.33696 7.5 3.70107 7.23661 3.23223 6.76777C2.76339 6.29893 2.5 5.66304 2.5 5C2.5 4.33696 2.76339 3.70107 3.23223 3.23223C3.70107 2.76339 4.33696 2.5 5 2.5ZM5 3.5C4.60218 3.5 4.22064 3.65804 3.93934 3.93934C3.65804 4.22064 3.5 4.60218 3.5 5C3.5 5.39782 3.65804 5.77936 3.93934 6.06066C4.22064 6.34196 4.60218 6.5 5 6.5C5.39782 6.5 5.77936 6.34196 6.06066 6.06066C6.34196 5.77936 6.5 5.39782 6.5 5C6.5 4.60218 6.34196 4.22064 6.06066 3.93934C5.77936 3.65804 5.39782 3.5 5 3.5Z"
        fill={pdfData?.brandColor || theme.colors.green}
      />
    </Svg>
  );

  const WebSvg = () => (
    <Svg
      width="10"
      height="10"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M8.8883 16.5147C4.90129 16.5147 1.66919 13.2826 1.66919 9.29559C1.66919 5.30858 4.90129 2.07648 8.8883 2.07648C12.8753 2.07648 16.1074 5.30858 16.1074 9.29559C16.1074 13.2826 12.8753 16.5147 8.8883 16.5147ZM7.23514 14.8308C6.54023 13.357 6.11423 11.7315 6.02037 10.0175H3.15769C3.44365 12.3106 5.074 14.1863 7.23514 14.8308ZM7.46664 10.0175C7.57521 11.7781 8.07817 13.4319 8.8883 14.8918C9.69843 13.4319 10.2014 11.7781 10.31 10.0175H7.46664ZM14.6189 10.0175H11.7562C11.6624 11.7315 11.2364 13.357 10.5415 14.8308C12.7026 14.1863 14.333 12.3106 14.6189 10.0175ZM3.15769 8.57368H6.02037C6.11423 6.85969 6.54023 5.23417 7.23514 3.76038C5.074 4.40488 3.44365 6.28053 3.15769 8.57368ZM7.46664 8.57368H10.31C10.2014 6.81309 9.69843 5.15922 8.8883 3.69933C8.07817 5.15922 7.57521 6.81309 7.46664 8.57368ZM10.5415 3.76038C11.2364 5.23417 11.6624 6.85969 11.7562 8.57368H14.6189C14.333 6.28053 12.7026 4.40488 10.5415 3.76038Z"
        fill={pdfData?.brandColor || theme.colors.green}
      />
    </Svg>
  );

  const AlertSvg = () => (
    <Svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M6.5 12.75C3.04822 12.75 0.25 9.95175 0.25 6.5C0.25 3.04822 3.04822 0.25 6.5 0.25C9.95175 0.25 12.75 3.04822 12.75 6.5C12.75 9.95175 9.95175 12.75 6.5 12.75ZM6.5 11.5C9.26144 11.5 11.5 9.26144 11.5 6.5C11.5 3.73857 9.26144 1.5 6.5 1.5C3.73857 1.5 1.5 3.73857 1.5 6.5C1.5 9.26144 3.73857 11.5 6.5 11.5ZM5.875 3.375H7.125V4.625H5.875V3.375ZM5.875 5.875H7.125V9.625H5.875V5.875Z"
        fill={pdfData?.brandColor || theme.colors.green}
      />
    </Svg>
  );

  const HeartSvg = () => (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <Path
        d="M5 10L4.275 9.28065C1.7 6.7357 0 5.05177 0 2.99728C0 1.31335 1.21 0 2.75 0C3.62 0 4.455 0.441417 5 1.13352C5.545 0.441417 6.38 0 7.25 0C8.79 0 10 1.31335 10 2.99728C10 5.05177 8.3 6.7357 5.725 9.28065L5 10Z"
        fill={pdfData?.brandColor || theme.colors.green}
      />
    </Svg>
  );

  const formatPhoneNumber = (phoneNumber) => {
    const phone = phoneNumber ? phoneNumber?.toString().replace(/\D/g, "") : "";
    if (phone.length === 10) {
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
    }
    return phone;
  };

  const splitText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }

    const words = text.split(" ");
    let currentLine = "";
    let lines = [];

    words.forEach((word) => {
      if ((currentLine + word).length <= maxLength) {
        currentLine += (currentLine ? " " : "") + word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    });

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines.join("\n");
  };

  const calculateSubtotal = () => {
    const subtotal = _.sumBy(
      pdfData?.items,
      (treatmentPlan) =>
        _.sumBy(
          treatmentPlan.products,
          (item) => item.price * item.quantity - item.discount
        ) +
        _.sumBy(
          treatmentPlan.treatments,
          (item) => item.price * item.quantity - item.discount
        )
    );
    return subtotal;
  };

  const calculateTotal = () => {
    let calculation;
    calculation = _.sumBy(
      pdfData?.items,
      (treatmentPlan) =>
        _.sumBy(treatmentPlan.products, (item) => item.price * item.quantity) +
        _.sumBy(treatmentPlan.treatments, (item) => item.price * item.quantity)
    );

    if (
      pdfData?.discountConsultationFee &&
      pdfData?.consultationDiscountValue
    ) {
      if (pdfData?.consultationDiscountType === "dollar") {
        calculation -= pdfData?.consultationDiscountValue;
      } else if (pdfData?.consultationDiscountType === "percentage") {
        calculation -=
          (calculateSubtotal() * pdfData?.consultationDiscountValue) / 100;
      }
    }

    calculation -= _.sumBy(
      pdfData?.items,
      (treatmentPlan) =>
        _.sumBy(treatmentPlan.products, (item) => item.discount) +
        _.sumBy(treatmentPlan.treatments, (item) => item.discount)
    );

    if (pdfData?.additionalDiscountValue) {
      if (pdfData?.additionalDiscountType === "dollar") {
        calculation -= pdfData?.additionalDiscountValue;
      } else {
        calculation -=
          (calculateSubtotal() * pdfData?.additionalDiscountValue) / 100;
      }
    }
    if (pdfData?.additionalDiscountValue1) {
      if (pdfData?.additionalDiscountType1 === "dollar") {
        calculation -= pdfData?.additionalDiscountValue1;
      } else {
        calculation -=
          (calculateSubtotal() * pdfData?.additionalDiscountValue1) / 100;
      }
    }
    if (pdfData?.additionalDiscountValue2) {
      if (pdfData?.additionalDiscountType2 === "dollar") {
        calculation -= pdfData?.additionalDiscountValue2;
      } else {
        calculation -=
          (calculateSubtotal() * pdfData?.additionalDiscountValue2) / 100;
      }
    }
    if (pdfData?.additionalDiscountValue3) {
      if (pdfData?.additionalDiscountType3 === "dollar") {
        calculation -= pdfData?.additionalDiscountValue3;
      } else {
        calculation -=
          (calculateSubtotal() * pdfData?.additionalDiscountValue3) / 100;
      }
    }

    calculation += _.sumBy(
      pdfData?.items,
      (treatmentPlan) =>
        _.sumBy(treatmentPlan.products, (item) => item.taxes) +
        _.sumBy(treatmentPlan.treatments, (item) => item.taxes)
    );

    return calculation;
  };

  return (
    <Document>
      <Page size="letter" style={styles.body}>
        <View style={styles.header} fixed>
          <View style={{ gap: "10px" }}>
            <View style={styles.rowsHeader}>
              <View style={styles.firstColumn}>
                <Text
                  style={[
                    styles.patientTitle,
                    { color: pdfData?.brandColor || theme.colors.green },
                  ]}
                >
                  {pdfData?.customerNaming
                    ? pdfData?.customerNaming.charAt(0).toUpperCase() +
                      pdfData?.customerNaming.slice(1)
                    : "Patient"}
                </Text>
                <Text style={styles.patientName}>
                  {splitText(pdfData?.patientName, 11)}
                </Text>
              </View>
              <View style={styles.columnSocialInfo}>
                {pdfData?.instagram && (
                  <View style={styles.row}>
                    <InstaSvg />
                    {pdfData?.instagram && (
                      <Text
                        style={styles.socialInfo}
                      >{`@${pdfData?.instagram}`}</Text>
                    )}
                  </View>
                )}
                <View style={styles.row}>
                  <PhoneSvg />
                  <Text style={styles.socialInfo}>{`${formatPhoneNumber(
                    pdfData?.phone
                  )}`}</Text>
                </View>
                {pdfData?.webPage ? (
                  <View style={styles.row}>
                    <WebSvg />
                    <Text
                      style={styles.socialInfo}
                    >{`${pdfData?.webPage}`}</Text>
                  </View>
                ) : (
                  <View style={[styles.row, { height: "10px" }]} />
                )}
                {!pdfData?.instagram && (
                  <View style={[styles.row, { height: "10px" }]} />
                )}
              </View>
            </View>

            <View style={styles.rowsHeader}>
              <View style={styles.firstColumn}>
                <Text style={styles.invoiceTitle}>INVOICE</Text>
                <Text
                  style={styles.invoiceNumber}
                >{`No. ${pdfData?.invoiceNumber}`}</Text>
              </View>

              <View style={styles.columnSocialInfo}>
                <View style={styles.rowEffectiveDate}>
                  <Text
                    style={[
                      styles.dateTitles,
                      { color: pdfData?.brandColor || theme.colors.green },
                    ]}
                  >
                    Effective Date
                  </Text>
                  <Text
                    style={styles.socialInfo}
                  >{`${pdfData?.effectiveDate}`}</Text>
                </View>
                <View style={styles.rowValidUntil}>
                  <Text
                    style={[
                      styles.dateTitles,
                      { color: pdfData?.brandColor || theme.colors.green },
                    ]}
                  >
                    Valid Until
                  </Text>
                  <Text
                    style={styles.socialInfo}
                  >{`${pdfData?.validUntil}`}</Text>
                </View>
              </View>
            </View>
          </View>
          <Image style={styles.image} src={pdfData?.clinicImage} />
        </View>

        {pdfData?.items &&
          pdfData?.items.map((treatmentPlan, index) => (
            <>
              <View style={{ flexDirection: "row" }} key={index}>
                <View style={styles.packageTitleContainer}>
                  <Text style={styles.packageTitle}>
                    {`${index + 1}. `}
                    {splitText(treatmentPlan.name, 7)}
                  </Text>
                </View>

                <View style={styles.table}>
                  {treatmentPlan.treatments.length > 0 && (
                    <>
                      <View style={styles.tableHead}>
                        <Text
                          style={{
                            width: "300px",
                            color: pdfData?.brandColor || theme.colors.green,
                          }}
                        >
                          {`At ${pdfData?.clinicName}`}
                        </Text>
                        <Text>Qty</Text>
                      </View>
                      {treatmentPlan.treatments.map((treatment, idx) => (
                        <View
                          style={[
                            styles.row,
                            {
                              marginBottom: "5px",
                              borderBottom:
                                treatmentPlan.treatments.length === idx + 1 &&
                                treatmentPlan.products.length === 0
                                  ? "1px dashed #D9D9D9"
                                  : null,
                            },
                          ]}
                          key={idx}
                        >
                          <Text style={styles.productsNames}>
                            {treatment.name}
                          </Text>
                          <Text style={styles.productsQty}>
                            {treatment.quantity}
                          </Text>
                        </View>
                      ))}
                    </>
                  )}
                  {treatmentPlan.products.length > 0 && (
                    <>
                      <View
                        style={[
                          styles.tableHead,
                          {
                            marginTop:
                              treatmentPlan.treatments.length > 0
                                ? "15px"
                                : null,
                          },
                        ]}
                      >
                        <Text
                          style={{
                            width: "300px",
                            color: pdfData?.brandColor || theme.colors.green,
                          }}
                        >
                          Products
                        </Text>
                        <Text>Qty</Text>
                      </View>
                      {treatmentPlan.products.length > 0 &&
                        treatmentPlan.products.map((product, idx) => (
                          <View
                            style={[
                              styles.row,
                              {
                                marginBottom: "5px",
                                borderBottom:
                                  treatmentPlan.products.length === idx + 1
                                    ? "1px dashed #D9D9D9"
                                    : null,
                              },
                            ]}
                            key={idx}
                          >
                            <Text style={styles.productsNames}>
                              {product.name}
                            </Text>
                            <Text style={styles.productsQty}>
                              {product.quantity}
                            </Text>
                          </View>
                        ))}
                    </>
                  )}
                </View>

                <View style={styles.totalTittle}>
                  <Text></Text>
                  {_.sumBy(treatmentPlan.treatments, "discount") +
                    _.sumBy(treatmentPlan.products, "discount") ===
                    0 && (
                    <Text style={{ width: "40px", textAlign: "center" }}></Text>
                  )}
                  {_.sumBy(treatmentPlan.treatments, "taxes") +
                    _.sumBy(treatmentPlan.products, "taxes") !==
                  0 ? (
                    <Text style={{ width: "40px", textAlign: "center" }}>
                      Tax
                    </Text>
                  ) : (
                    <Text style={{ width: "40px", textAlign: "center" }} />
                  )}
                  {_.sumBy(treatmentPlan.treatments, "discount") +
                    _.sumBy(treatmentPlan.products, "discount") !==
                    0 && (
                    <Text style={{ width: "40px", textAlign: "center" }}>
                      Savings
                    </Text>
                  )}
                  <Text
                    style={[
                      styles.totalQuoteText,
                      { width: "50px", textAlign: "center" },
                    ]}
                  >
                    Total
                  </Text>
                </View>
              </View>
              <View style={styles.totalValues}>
                <View style={{ width: "380px" }} />
                <Text></Text>
                {_.sumBy(treatmentPlan.treatments, "discount") +
                  _.sumBy(treatmentPlan.products, "discount") ===
                  0 && <Text style={styles.taxSavignsValues}>{``}</Text>}
                {_.sumBy(treatmentPlan.treatments, "taxes") +
                  _.sumBy(treatmentPlan.products, "taxes") !==
                0 ? (
                  <Text style={styles.taxSavignsValues}>
                    {`+${formatPriceToDollarPdf(
                      _.sumBy(treatmentPlan.treatments, "taxes") +
                        _.sumBy(treatmentPlan.products, "taxes")
                    )}`}
                  </Text>
                ) : (
                  <Text style={styles.taxSavignsValues}>{``}</Text>
                )}
                {_.sumBy(treatmentPlan.treatments, "discount") +
                  _.sumBy(treatmentPlan.products, "discount") !==
                  0 && (
                  <Text style={styles.taxSavignsValues}>
                    {`-${formatPriceToDollarPdf(
                      _.sumBy(treatmentPlan.treatments, "discount") +
                        _.sumBy(treatmentPlan.products, "discount")
                    )}`}
                  </Text>
                )}
                <Text style={styles.totalFormat}>
                  {formatPriceToDollarPdf(
                    _.sumBy(
                      treatmentPlan.treatments,
                      (item) =>
                        item.price * item.quantity + item.taxes - item.discount
                    ) +
                      _.sumBy(
                        treatmentPlan.products,
                        (item) =>
                          item.price * item.quantity +
                          item.taxes -
                          item.discount
                      )
                  )}
                </Text>
              </View>
            </>
          ))}

        <View style={styles.resumeWrapper}>
          <View style={styles.resume} wrap>
            <View style={{ alignItems: "center" }}>
              <View style={styles.qrBackground}>
                {pdfData?.quoteImage && (
                  <Image style={styles.qrImage} src={pdfData?.quoteImage} />
                )}
              </View>
            </View>

            <View style={{ alignItems: "center" }}>
              <Text
                style={[
                  styles.resumeTableTitle,
                  { color: pdfData?.brandColor || theme.colors.green },
                ]}
              >
                Payment Plans
              </Text>
              <View style={styles.resumeTableRow}>
                <Text style={styles.paymentConcept}>6 MONTHS</Text>
                <Text
                  style={[
                    styles.paymentValue,
                    { color: pdfData?.brandColor || theme.colors.green },
                  ]}
                >
                  {formatPriceToDollarPdf(calculateTotal() / 6)}
                </Text>
              </View>
              <View style={styles.resumeTableRow}>
                <Text style={styles.paymentConcept}>12 MONTHS</Text>
                <Text
                  style={[
                    styles.paymentValue,
                    { color: pdfData?.brandColor || theme.colors.green },
                  ]}
                >
                  {formatPriceToDollarPdf(calculateTotal() / 12)}
                </Text>
              </View>
              <View style={styles.resumeTableRow}>
                <Text style={styles.paymentConcept}>24 MONTHS</Text>
                <Text
                  style={[
                    styles.paymentValue,
                    { color: pdfData?.brandColor || theme.colors.green },
                  ]}
                >
                  {formatPriceToDollarPdf(calculateTotal() / 24)}
                </Text>
              </View>
              <View style={styles.paymentAlert}>
                <View style={{ flexDirection: "row", gap: "3px" }}>
                  <AlertSvg />
                  <Text style={styles.textAlert}>
                    If you choose a payment plan, you will
                  </Text>
                </View>
                <Text style={styles.text2Alert}>
                  only pay the first installment today.
                </Text>
              </View>
            </View>

            <View style={{ alignItems: "center" }}>
              <Text
                style={[
                  styles.resumeTableTitle,
                  { color: pdfData?.brandColor || theme.colors.green },
                ]}
              >
                Investment
              </Text>
              <View style={styles.resumeTableRow}>
                <Text style={styles.paymentConcept}>Subtotal</Text>
                <Text style={styles.paymentValue}>
                  {formatPriceToDollarPdf(
                    _.sumBy(
                      pdfData?.items,
                      (treatmentPlan) =>
                        _.sumBy(
                          treatmentPlan.products,
                          (item) => item.price * item.quantity
                        ) +
                        _.sumBy(
                          treatmentPlan.treatments,
                          (item) => item.price * item.quantity
                        )
                    )
                  )}
                </Text>
              </View>
              {pdfData?.discountConsultationFee && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>
                    {pdfData?.consultationDiscountName ||
                      "Consultation Discount"}
                  </Text>
                  <Text style={styles.paymentValue}>
                    {pdfData?.consultationDiscountType === "dollar"
                      ? `-${formatPriceToDollarPdf(
                          pdfData?.consultationDiscountValue
                        )}`
                      : `-${formatPriceToDollarPdf(
                          (calculateSubtotal() *
                            pdfData?.consultationDiscountValue) /
                            100
                        )}`}
                  </Text>
                </View>
              )}

              {_.sumBy(
                pdfData?.items,
                (treatmentPlan) =>
                  _.sumBy(treatmentPlan.products, (item) => item.discount) +
                  _.sumBy(treatmentPlan.treatments, (item) => item.discount)
              ) !== 0 && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>Savings</Text>
                  <Text style={styles.paymentValue}>
                    {`-${formatPriceToDollarPdf(
                      _.sumBy(
                        pdfData?.items,
                        (treatmentPlan) =>
                          _.sumBy(
                            treatmentPlan.products,
                            (item) => item.discount
                          ) +
                          _.sumBy(
                            treatmentPlan.treatments,
                            (item) => item.discount
                          )
                      )
                    )}`}
                  </Text>
                </View>
              )}

              {pdfData?.additionalDiscountName && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>
                    {pdfData?.additionalDiscountName}
                  </Text>
                  <Text style={styles.paymentValue}>
                    {pdfData?.additionalDiscountType === "dollar"
                      ? `-${formatPriceToDollarPdf(
                          pdfData?.additionalDiscountValue
                        )}`
                      : `${formatPriceToDollarPdf(
                          (calculateSubtotal() *
                            pdfData?.additionalDiscountValue) /
                            100
                        )}`}
                  </Text>
                </View>
              )}
              {pdfData?.additionalDiscountName1 && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>
                    {pdfData?.additionalDiscountName1}
                  </Text>
                  <Text style={styles.paymentValue}>
                    {pdfData?.additionalDiscountType1 === "dollar"
                      ? `-${formatPriceToDollarPdf(
                          pdfData?.additionalDiscountValue1
                        )}`
                      : `-${formatPriceToDollarPdf(
                          (calculateSubtotal() *
                            pdfData?.additionalDiscountValue1) /
                            100
                        )}`}
                  </Text>
                </View>
              )}
              {pdfData?.additionalDiscountName2 && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>
                    {pdfData?.additionalDiscountName2}
                  </Text>
                  <Text style={styles.paymentValue}>
                    {pdfData?.additionalDiscountType2 === "dollar"
                      ? `-${formatPriceToDollarPdf(
                          pdfData?.additionalDiscountValue2
                        )}`
                      : `-${formatPriceToDollarPdf(
                          (calculateSubtotal() *
                            pdfData?.additionalDiscountValue2) /
                            100
                        )}`}
                  </Text>
                </View>
              )}
              {pdfData?.additionalDiscountName3 && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>
                    {pdfData?.additionalDiscountName3}
                  </Text>
                  <Text style={styles.paymentValue}>
                    {pdfData?.additionalDiscountType3 === "dollar"
                      ? `-${formatPriceToDollarPdf(
                          pdfData?.additionalDiscountValue3
                        )}`
                      : `-${formatPriceToDollarPdf(
                          (calculateSubtotal() *
                            pdfData?.additionalDiscountValue3) /
                            100
                        )}`}
                  </Text>
                </View>
              )}
              {_.sumBy(
                pdfData?.items,
                (treatmentPlan) =>
                  _.sumBy(treatmentPlan.products, (item) => item.taxes) +
                  _.sumBy(treatmentPlan.treatments, (item) => item.taxes)
              ) !== 0 && (
                <View style={styles.resumeTableRow}>
                  <Text style={styles.paymentConcept}>Products Tax</Text>
                  <Text style={styles.paymentValue}>
                    {`+${formatPriceToDollarPdf(
                      _.sumBy(
                        pdfData?.items,
                        (treatmentPlan) =>
                          _.sumBy(
                            treatmentPlan.products,
                            (item) => item.taxes
                          ) +
                          _.sumBy(
                            treatmentPlan.treatments,
                            (item) => item.taxes
                          )
                      )
                    )}`}
                  </Text>
                </View>
              )}
              <View style={styles.totalInvestment}>
                <Text style={styles.totalDescription}>Total</Text>
                <Text style={styles.totalValue}>
                  {formatPriceToDollarPdf(calculateTotal())}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.footer} fixed>
          <View style={styles.footerInfo}>
            <Text>{`Dividerm.com`}</Text>
            <HeartSvg />
            <Text>{`${pdfData?.clinicName}`}</Text>
          </View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `Page ${pageNumber} of ${totalPages}`
            }
          />
        </View>
      </Page>
    </Document>
  );
};
