import { useEffect, useMemo } from "react";
import { theme } from "../../../../styles/theme";
import { BarChart, Bar, XAxis, Tooltip } from "recharts";
import { Text, Row, Col, Div } from "../../../../styles/Common";
import _ from "lodash";
import { DownloadIcon } from "../../../../assets/icons";
import { formatPriceToDollar } from "../../../../utilities/helpers";
import { GraphTooltip } from "../GraphTooltip";
import { graphReportTimeExpresion } from "../../helpers";

export const CostProfit = ({ groupedCostProfits, selectedReportTime }) => {
  const summedData = useMemo(() => {
    return _.map(groupedCostProfits, (group, idx) => ({
      date: graphReportTimeExpresion(selectedReportTime.value, idx),
      costTotal: _.sumBy(group, "costTotal"),
      gainGain: _.sumBy(group, "gainGain"),
    }));
  }, [groupedCostProfits, selectedReportTime]);

  useEffect(() => {}, [groupedCostProfits, selectedReportTime]);

  return (
    <Div
      height="235px"
      width="494px"
      m="0 0 25px 0"
      radius="20px"
      background={theme.colors.gray950}
      direction="column"
    >
      <Row m="21px 21px 0 21px" justify="space-between" align="center">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          Costs and Profits
        </Text>
        <Div
          radius="50%"
          background="rgba(255, 255, 255, 0.2)"
          height="45px"
          width="45px"
          justify="center"
          align="center"
        >
          <DownloadIcon />
        </Div>
      </Row>
      <Row>
        <BarChart
          width={400}
          height={150}
          data={summedData}
          margin={{ top: 10, right: 25, left: 20, bottom: 0 }}
          barGap={3}
        >
          <Tooltip
            cursor={{ fill: theme.colors.green100 }}
            content={<GraphTooltip />}
          />
          <Bar
            dataKey="costTotal"
            fill={theme.colors.gray800}
            name="Costs"
            radius={8}
            barSize={30}
          />
          <Bar
            dataKey="gainGain"
            fill={theme.colors.green}
            name="Profits"
            radius={8}
            barSize={32}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tick={{ fontSize: theme.fonts.size.sm, fill: theme.colors.white }}
          />
        </BarChart>
        <Col justify="center" style={{ gap: "14px" }}>
          <Row align="center" gap="8px">
            <Div
              radius="50%"
              background={theme.colors.gray800}
              width="5px"
              height="5px"
            />
            <Text
              size={theme.fonts.size.sm}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray400}
            >
              Costs
            </Text>
          </Row>
          <Row align="center" gap="8px">
            <Div
              radius="50%"
              background={theme.colors.green}
              width="5px"
              height="5px"
            />
            <Text
              size={theme.fonts.size.sm}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray400}
            >
              Profits
            </Text>
          </Row>
        </Col>
      </Row>
    </Div>
  );
};
