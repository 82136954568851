import { useState, useEffect } from "react";
import Joi from "joi";
import { Col, Container, Row, Text } from "../../../../../styles/Common";
import { Input } from "../../../../../components/Input";
import { Modal } from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { Radio } from "../../../../../components/Radio";
import { theme } from "../../../../../styles/theme";
import { DiscountIcon } from "../../../../../assets/icons";

const ModalAdditionalDiscount = ({
  showModalDiscount,
  setShowModalDiscount,
  additionalDiscount,
  setAdditionalDiscount,
}) => {
  const initialErrors = {
    bonusType: {
      error: false,
      message: "",
    },
    bonusValue: {
      error: false,
      message: "",
    },
    discountName: {
      error: false,
      message: "",
    },
  };

  const [errorForm, setErrorForm] = useState(initialErrors);
  const [forceUpdate, setForceUpdate] = useState(false);

  const initialValues = {
    bonusType: "dollar",
    bonusValue: 0,
    discountName: "",
  };
  const [discountForm, setDiscountForm] = useState(initialValues);

  const handleRadio = (event) => {
    const { value, name } = event.target;
    const newErrorForm = { ...errorForm };

    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    const newDiscount = { ...discountForm };
    newDiscount[name] = value;
    setDiscountForm(newDiscount);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeDiscount = (event) => {
    const { value, id } = event.target;

    const newErrorForm = { ...errorForm };
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newDiscount = { ...discountForm };
    newDiscount[id] = value;
    setDiscountForm(newDiscount);
    setForceUpdate(!forceUpdate);
  };

  const handleClose = () => {
    setErrorForm(initialErrors);
    setShowModalDiscount(false);
  };

  const handleAddDiscount = () => {
    const validateAddDiscountForm = Joi.object({
      bonusType: Joi.string().required(),
      bonusValue: Joi.number().required(),
      discountName: Joi.string().required(),
    });
    const validation = validateAddDiscountForm.validate(discountForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
      });
      setForceUpdate(!forceUpdate);
    } else {
      setAdditionalDiscount([...additionalDiscount, discountForm]);
      setDiscountForm(initialValues);
      handleClose();
    }
  };

  return (
    <Container>
      <Modal
        open={showModalDiscount}
        onCancel={() => {
          setDiscountForm(initialValues);
          handleClose();
        }}
        width={"407px"}
      >
        <Modal.Header
          icon={<DiscountIcon stroke={theme.colors.green} />}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          title={"Additional Discount"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="15px 0 24px 0"
          maxHeight={576}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" m="0 0 30px 0" p="0 27px 0px 27px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                Add an additional discount to the quote, you can choose a
                percentage or an absolute value.
              </Text>
            </Row>
            <Row m="0 0 15px 0">
              <Input
                label={"Discount Name"}
                placeholder={"Discount description"}
                id="discountName"
                value={discountForm?.discountName}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray400}
                background={theme.colors.white}
                color={theme.colors.gray600}
                width={"246px"}
                onChange={handleChangeDiscount}
                error={errorForm.discountName.error}
                helper={
                  errorForm.discountName.error
                    ? errorForm.discountName.message
                    : ""
                }
              />
            </Row>
            <Row m="0px 0px 0px 0px" gap="15px">
              <Radio.Group
                name="bonusType"
                value={discountForm?.bonusType}
                key={discountForm?.bonusType}
                onChange={handleRadio}
                gap={"15px"}
                direction="row"
                error={errorForm.bonusType.error}
                helper={
                  errorForm.bonusType.error ? errorForm.bonusType.message : ""
                }
              >
                <Radio value="dollar" background={theme.colors.white} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Dollar
                </Text>
                <Radio value="percentage" background={theme.colors.white} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Percentage
                </Text>
              </Radio.Group>
            </Row>
            <Row m="5px 0px 24px 0px">
              <Input
                label={"Discount"}
                id="bonusValue"
                type="Number"
                prefix={discountForm?.bonusType === "dollar" && "$"}
                suffix={discountForm?.bonusType === "percentage" && "%"}
                value={discountForm?.bonusValue}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray400}
                background={theme.colors.white}
                color={theme.colors.gray600}
                width={"246px"}
                onChange={handleChangeDiscount}
                error={errorForm.bonusValue.error}
                helper={
                  errorForm.bonusValue.error ? errorForm.bonusValue.message : ""
                }
              />
            </Row>
            <Row width="100%" justify="center" m="0 0 16px 0">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleAddDiscount}
                width="226px"
                variant="filled"
              >
                Add discount
              </Button>
            </Row>
            <Row width="100%" justify="center">
              <Button
                variant={"outlined"}
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={() => {
                  setDiscountForm(initialValues);
                  handleClose();
                }}
                width="226px"
              >
                Cancel
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ModalAdditionalDiscount;
