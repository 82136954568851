import React from "react";
import { theme } from "../../../../../styles/theme";
import { Col, Row, Text, Div } from "../../../../../styles/Common";
import { BilledCard } from "../../styles";
import { DatePicker } from "../../../../../components/DatePicker";
import { Input } from "../../../../../components/Input";

export const BilledInfo = ({
  selectedClinic,
  handleSelect,
  selectedDueDate,
  saveQuoteErrorForm,
  packageName,
  handleChangePackageName,
}) => {
  return (
    <BilledCard>
      <Col width={"290px"} justify="space-between">
        <DatePicker
          label={"Expiration date"}
          id="dueDate"
          sizeLabel={theme.fonts.size.sm}
          colorLabel={theme.colors.gray400}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray50}
          width={"290px"}
          placeholder={""}
          placeholderColor={theme.colors.red}
          value={selectedDueDate}
          onChange={(value, date) => handleSelect(value, date, "dueDate")}
          error={saveQuoteErrorForm.dueDate.error}
          helper={
            saveQuoteErrorForm.dueDate.error
              ? saveQuoteErrorForm.dueDate.message
              : ""
          }
        />
        <Row width="100%" m="12px 0 5px 0">
          <Input
            label={"Package Name"}
            id="packageName"
            value={packageName}
            sizeLabel={theme.fonts.size.sm}
            colorLabel={theme.colors.gray400}
            color={theme.colors.gray400}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            weightLabel={theme.fonts.weight.regular}
            width={"100%"}
            onChange={handleChangePackageName}
            error={saveQuoteErrorForm.packageName.error}
            helper={
              saveQuoteErrorForm.packageName.error
                ? saveQuoteErrorForm.packageName.message
                : ""
            }
          />
        </Row>
      </Col>
      <Col width="290px">
        <Row m={"0 0 18px 0"} justify="right">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h5}
            color={theme.colors.gray50}
          >
            Practice Information
          </Text>
        </Row>
        <Row m={"0 0 9px 0"} justify="right">
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h5}
            color={theme.colors.gray400}
          >
            {selectedClinic?.label}
          </Text>
        </Row>
      </Col>
    </BilledCard>
  );
};
