import React, { useState, useEffect, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQueryClient } from "react-query";
import { toast } from "react-hot-toast";
import { orderBy, groupBy } from "lodash";
import { useSelector } from "react-redux";
import { Row, Div, Text, Container, Col } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Select } from "../../components/Select";
import { Skeleton } from "antd";
import { theme } from "../../styles/theme";
import { CategoryIcon } from "../../assets/icons";
import DrawerCategory from "./componenets/DrawerCategory";
import ModalCategory from "./componenets/ModalCategory";
import ModalDelete from "./componenets/ModalDelete";
import DraggableCategoryCard from "./componenets/DraggableCategoryCard";
import { CategoryCard, Grid } from "./styles";
import useGetCategories from "./hooks/useGetCategories";
import { isSuperAdmin } from "../../utilities/helpers";
import useEditCategory from "./hooks/useEditCategory";

export const Categories = () => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const editCategory = useEditCategory();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [searchText, setSearchText] = useState(null);

  const [selectedClinic, setSelectedClinic] = useState(null);

  const initialValues = {
    id: null,
    name: "",
    type: null,
    bonus: null,
    bonusType: null,
    clinicId: null,
    clinicName: "",
  };

  const getCategories = useGetCategories();
  const { data, isLoading, isFetching } = getCategories;
  const [categoriesList, setCategoriesList] = useState([]);

  const [actualInfo, setActualInfo] = useState(initialValues);
  const [categoryToEdit, setCategoryToEdit] = useState();

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      setCategoriesList(data.data.data);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {}, [actualInfo]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const filterCategories = (searchText, selectedClinic) => {
    return categoriesList.filter((category) => {
      const categoryTypeString =
        category.category_type === 1 ? "Treatment" : "Product";

      if (selectedClinic && category.clinic.id !== selectedClinic) {
        return false;
      }

      if (
        !searchText ||
        category.name.toLowerCase().includes(searchText.toLowerCase())
      ) {
        return true;
      }

      if (categoryTypeString.toLowerCase().includes(searchText.toLowerCase())) {
        return true;
      }

      return false;
    });
  };

  const filteredCategories = useMemo(() => {
    const prevFiltered = filterCategories(searchText, selectedClinic);

    const groupedCategories = groupBy(prevFiltered, (category) => {
      return category.category_type === 1 ? "treatments" : "products";
    });
    const sortedCategories = {};
    Object.keys(groupedCategories).forEach((key) => {
      sortedCategories[key] = orderBy(
        groupedCategories[key],
        "position",
        "asc"
      );
    });
    return sortedCategories;
  }, [searchText, selectedClinic, categoriesList]);

  const handleSelect = (value, id) => {
    setSelectedClinic(value);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawerSuccess = () => {
    setShowDrawer(false);
    setShowModal(true);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseModal = () => {
    setCategoryToEdit({});
    setShowModal(false);
    setIsEditing(false);
  };

  const handleCloseDelete = () => {
    setShowModalDelete(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  const handleDrop = async (
    sourcePosition,
    targetPosition,
    sourceId,
    targetId
  ) => {
    const sourceIndex = categoriesList.findIndex(
      (category) => category.id === sourceId
    );
    const targetIndex = categoriesList.findIndex(
      (category) => category.id === targetId
    );

    const sourceCategory = {
      id: categoriesList[sourceIndex].id,
      name: categoriesList[sourceIndex].name,
      category_type: categoriesList[sourceIndex].category_type,
      clinicId: categoriesList[sourceIndex].clinic.id,
      bonus_type: categoriesList[sourceIndex].bonus_type,
      bonus_value: categoriesList[sourceIndex].bonus_value,
      position: targetPosition,
    };
    const targetCategory = {
      id: categoriesList[targetIndex].id,
      name: categoriesList[targetIndex].name,
      category_type: categoriesList[targetIndex].category_type,
      clinicId: categoriesList[targetIndex].clinic.id,
      bonus_type: categoriesList[targetIndex].bonus_type,
      bonus_value: categoriesList[targetIndex].bonus_value,
      position: sourcePosition,
    };

    if (sourceCategory.category_type === targetCategory.category_type) {
      toast.remove();
      try {
        await Promise.all([
          editCategory.mutateAsync(sourceCategory),
          editCategory.mutateAsync(targetCategory),
        ]);
        queryClient.invalidateQueries(["getCategories"]);
      } catch (err) {
        toast.error(
          err?.response?.data?.error?.message ||
            "Error trying to update the position"
        );
      }
    } else {
      toast.remove();
      toast.error("Change the positions of the same category type");
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container style={{ margin: "0px 0px 0px 10px" }}>
        <ModalCategory
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          categoryToEdit={categoryToEdit}
          isEditing={isEditing}
        />
        <ModalDelete
          showModal={showModalDelete}
          handleCloseModal={handleCloseDelete}
          categoryId={actualInfo.id}
        />

        <DrawerCategory
          showDrawer={showDrawer}
          closeDrawer={handleCloseDrawer}
          handleCloseDrawerSuccess={handleCloseDrawerSuccess}
          actualInfo={actualInfo}
          optionsClinics={optionsClinics}
          setIsEditing={setIsEditing}
          setCategoryToEdit={setCategoryToEdit}
        />
        {superAdmin && (
          <Row justify="space-between" align="center" m="0px 0px 20px 0px">
            <Div
              style={{ borderLeft: `5px solid ${theme.colors.green}` }}
              p="0px 0px 0px 16px"
              weight="5px"
              height="20px"
              align="center"
            >
              <Text
                weight={theme.fonts.weight.semibold}
                size={theme.fonts.size.h2}
                color={theme.colors.white}
              >
                Categories
              </Text>
            </Div>
            <Select
              label={"Select Practice"}
              id="clinic"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray800}
              background={theme.colors.gray800}
              color={theme.colors.gray400}
              width={"286px"}
              defaultValue=""
              value={actualInfo?.clinicId?.toString()}
              options={optionsClinics}
              onChange={(val) => handleSelect(val, "clinic")}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
            />
          </Row>
        )}
        <Row
          height="40px"
          justify={superAdmin ? "right" : "space-between"}
          align="center"
          m="0px 0px 37px 0px"
        >
          {!superAdmin && (
            <Div
              style={{ borderLeft: `5px solid ${theme.colors.green}` }}
              p="0px 0px 0px 16px"
              weight="5px"
              height="20px"
              align="center"
            >
              <Text
                weight={theme.fonts.weight.semibold}
                size={theme.fonts.size.h2}
                color={theme.colors.white}
              >
                Categories
              </Text>
            </Div>
          )}
          <Div gap="10px" align="center">
            <Input
              placeholder="Search and filter by category name or category type"
              color={theme.colors.gray400}
              width="472px"
              border={theme.colors.gray800}
              onChange={handleChangeSearch}
              background={theme.colors.gray800}
            />
            <Button width="208px" onClick={() => setShowDrawer(true)}>
              <Row width="100%" justify="center">
                <Div gap={"8px"} align={"center"}>
                  <CategoryIcon stroke={theme.colors.black50} />
                  Create Category
                </Div>
              </Row>
            </Button>
          </Div>
        </Row>

        <Row m={"0px 0px 40px 0px"}>
          <Text
            weight={theme.fonts.weight.regular}
            size={theme.fonts.size.default}
            color={theme.colors.gray400}
          >
            Arrange the categories in the way you prefer; the order in which you
            move them will be the order shown to the users. To move the cards,
            you just need to click and hold, then drag them
          </Text>
        </Row>

        {superAdmin && selectedClinic === null ? (
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h4}
            color={theme.colors.green}
          >
            Select a practice to display categories
          </Text>
        ) : (
          <Col gap="16px">
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.green}
            >
              Treatments
            </Text>
            <Grid>
              {isLoading || isFetching ? (
                Array(6)
                  .fill()
                  .map((_, index) => (
                    <CategoryCard key={index}>
                      <Skeleton.Input active block />
                    </CategoryCard>
                  ))
              ) : filteredCategories?.treatments?.length > 0 ? (
                filteredCategories?.treatments.map((category) => (
                  <DraggableCategoryCard
                    key={category.id}
                    setShowDrawer={setShowDrawer}
                    setActualInfo={setActualInfo}
                    category={category}
                    handleDrop={handleDrop}
                    setShowModalDelete={setShowModalDelete}
                  />
                ))
              ) : (
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.default}
                  color={theme.colors.red}
                >
                  No treatment categories found. Please try a different search.
                </Text>
              )}
            </Grid>
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.green}
            >
              Products
            </Text>
            <Grid>
              {isLoading || isFetching ? (
                Array(6)
                  .fill()
                  .map((_, index) => (
                    <CategoryCard key={index}>
                      <Skeleton.Input active block />
                    </CategoryCard>
                  ))
              ) : filteredCategories?.products?.length > 0 ? (
                filteredCategories?.products.map((category) => (
                  <DraggableCategoryCard
                    key={category.id}
                    setShowDrawer={setShowDrawer}
                    setActualInfo={setActualInfo}
                    category={category}
                    handleDrop={handleDrop}
                    setShowModalDelete={setShowModalDelete}
                  />
                ))
              ) : (
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.default}
                  color={theme.colors.red}
                >
                  No products categories found. Please try a different search.
                </Text>
              )}
            </Grid>
          </Col>
        )}
      </Container>
    </DndProvider>
  );
};
