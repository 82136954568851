import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Div, Row, Text } from "../../styles/Common";
import { Input } from "../../components/Input";
import useGetQuotes from "./hooks/useGetQuotes";
import { theme } from "../../styles/theme";
import useDebounce from "../../core/hooks/useDebounce";
import {
  ArchiveIcon,
  DeleteIcon,
  EditIcon,
  OpenIcon,
} from "../../assets/icons";
import Table from "../../components/Table";
import { Skeleton } from "../../components/Skeleton";
import { Link } from "../../components/Link";
import { Select } from "../../components/Select";
import { capitalize } from "lodash";
import { ButtonViewQuote, StatusMark, statusColor } from "./styles";
import {
  downloadPDF,
  formatPriceToDollar,
  formattedDate,
  isRegular,
  isSuperAdmin,
} from "../../utilities/helpers";
import { setSelectedQuotationInformation } from "../../core/store/quotations";
import PDFModal from "../../components/PDFModal";
import ModalDeleteQuote from "./ModalQuote";
import ModalVoid from "./components/ModalVoid";
import useGetPdfData from "./hooks/useGetPdfData";
import { Tooltip } from "antd";

export const QuoteHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [sortOptions, setSortOptions] = useState({
    sortBy: null,
    sortDirection: null,
  });
  const [totalItems, setTotalItems] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(false);
  const searchValue = useDebounce(searchText, 600);
  const [clinicFilter, setClinicFilter] = useState(null);
  const [showModalVoid, setShowModalVoid] = useState(false);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
    sortDirection: sortOptions.sortDirection,
    sortBy: sortOptions.sortBy,
    searchBy: searchValue,
    clinicId: clinicFilter,
  });
  const [quoteList, setQuoteList] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [modalPdfopen, setModalPdfopen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [selectedQuoteId, setSelectedQuoteId] = useState(null);

  const { isLoading, isFetching } = useGetQuotes(payloadOptions, {
    onSuccess: (apiData) => {
      const dataTable = apiData.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: (
              <Tooltip title="Edit" color={theme.colors.gray950}>
                <EditIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              dispatch(setSelectedQuotationInformation(row));
              navigate("edit-quotation");
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: (
              <Tooltip title="Delete" color={theme.colors.gray950}>
                <DeleteIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setSelectedQuoteId(row.id);
              setModalDeleteOpen(true);
            },
          },
          !isRegular() && {
            key: `1${i}-void`,
            id: ele.id,
            icon: (
              <Tooltip title="Void" color={theme.colors.gray950}>
                <ArchiveIcon
                  fill={theme.colors.gray100}
                  style={{
                    cursor: ele.state !== "paid" && "not-allowed",
                  }}
                />
              </Tooltip>
            ),
            onClick: (row) => {
              if (ele.state === "paid") {
                setSelectedQuoteId(row.id);
                setShowModalVoid(true);
              }
            },
          },
        ],
        open: {
          key: `download`,
          id: ele.id,
          icon: <OpenIcon fill={theme.colors.gray100} />,
          onClick: (row) => {
            setSelectedQuoteId(row.id);
            setModalPdfopen(true);
          },
        },
      }));
      setQuoteList(dataTable);
      setTotalItems(apiData.data.data.total);
    },
  });

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
      sortBy: sortOptions.sortBy,
      sortDirection: sortOptions.sortDirection,
      searchBy: searchValue,
      clinicId: clinicFilter,
    });
  }, [pages, sortOptions, searchValue, clinicFilter]);

  const getPdfData = useGetPdfData(selectedQuoteId);
  const { data: dataPdf, isLoading: isLoadingDataPdf } = getPdfData;
  const [pdfData, setPdfData] = useState(null);
  useEffect(() => {
    if (dataPdf?.data?.data) {
      setPdfData(dataPdf?.data?.data);
    }
  }, [dataPdf, isLoadingDataPdf]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleSelectClinic = (value) => {
    setClinicFilter(value);
    setSearchText(null);
  };

  const handleChangeTable = (pagination, filter, sort) => {
    if (sort && sort.columnKey) {
      setSortOptions({
        sortBy: sort.columnKey,
        sortDirection: sort.order === "ascend" ? "ASC" : "DESC",
      });
    } else {
      setSortOptions({ sortBy: null, sortDirection: null });
    }

    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setForceUpdate(!forceUpdate);
  };

  const handleCloseModals = () => {
    setSelectedQuoteId(null);
    setShowModalVoid(false);
    setModalDeleteOpen(false);
    setModalPdfopen(false);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "C. Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 150,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => {
          return `${formattedDate(value)}`;
        },
      },
      {
        title: "Practice Name",
        dataIndex: "clinic",
        key: "clinicName",
        width: 213,
        sorter: true,
        showSorterTooltip: false,
        render: (value) => `${value ? `${value.name}` : ""}`,
      },
      {
        title: "Patient Name",
        dataIndex: "patient",
        key: "patientName",
        width: 208,
        sorter: true,
        showSorterTooltip: false,
        render: (value) => `${value ? `${value.name} ${value.lastname}` : ""}`,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        sorter: false,
        showSorterTooltip: false,
        width: 173,
        render: (value) =>
          `${formatPriceToDollar(value) ? formatPriceToDollar(value) : ""}`,
      },
      {
        title: "Status",
        dataIndex: "state",
        key: "state",
        sorter: false,
        showSorterTooltip: false,
        width: 173,
        render: (value) =>
          value ? (
            <Row gap="5px" align="center">
              <StatusMark status={value} />
              <Text color={statusColor(value)}>{capitalize(value)}</Text>{" "}
            </Row>
          ) : (
            ""
          ),
      },
      {
        title: "Expiration Date",
        dataIndex: "dueDate",
        key: "dueDate",
        width: 180,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => {
          return `${formattedDate(value)}`;
        },
      },

      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (actions, row) => {
          return (
            <Div gap="4px">
              {actions &&
                actions.map((action, i) => (
                  <Link
                    key={i}
                    onClick={() => action.onClick(row)}
                    color={theme.colors.gray50}
                    icon={action.icon}
                    styles={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  />
                ))}
            </Div>
          );
        },
      },
      {
        title: "Open",
        dataIndex: "open",
        key: "download",
        width: 140,
        render: (download, row) => {
          return (
            <Div gap="10px">
              {download && (
                <ButtonViewQuote onClick={() => download.onClick(row)}>
                  {download.icon}
                  View Quote
                </ButtonViewQuote>
              )}
            </Div>
          );
        },
      },
    ],
    [quoteList]
  );

  return (
    <Div height="max-content" width="1070px" direction="column">
      <ModalDeleteQuote
        showModal={modalDeleteOpen}
        handleCloseModal={handleCloseModals}
        selectedQuoteId={selectedQuoteId}
        payloadOptions={payloadOptions}
      />
      <PDFModal
        modalOpen={modalPdfopen}
        handleCloseModal={handleCloseModals}
        pdfData={pdfData}
        isLoadingDataPdf={isLoadingDataPdf}
      />
      <ModalVoid
        showModal={showModalVoid}
        handleCloseModal={handleCloseModals}
        selectedQuoteId={selectedQuoteId}
      />
      <Row
        height="40px"
        justify="space-between"
        align="center"
        m={isSuperAdmin() ? "0px 0px 20px 0px" : "0px 0px 37px 0px"}
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Quote History
          </Text>
        </Div>

        <Div gap="10px" align="center">
          {isSuperAdmin() && (
            <Select
              id="clinic"
              placeholder="Filter by practice"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray800}
              background={theme.colors.gray800}
              color={theme.colors.gray400}
              width={"190px"}
              value={clinicFilter}
              options={optionsClinics}
              onChange={handleSelectClinic}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
            />
          )}
          <Input
            placeholder="Search and filter by patient or status"
            color={theme.colors.gray400}
            width="597px"
            border={theme.colors.gray800}
            onChange={handleChangeSearch}
            background={theme.colors.gray800}
            value={searchText}
          />
        </Div>
      </Row>
      <Row justify="end" m={"0px 10px 10px 0px"}>
        <Link
          onClick={() => {
            if (isSuperAdmin()) {
              setClinicFilter(null);
            }
            setSearchText(null);
          }}
        >
          Clear Filters
        </Link>
      </Row>
      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={quoteList}
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              } table-row-hover`
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "results", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Div>
  );
};
