import React, { useState, useMemo, useEffect } from "react";
import { uniqBy } from "lodash";
import { NoDiscountCard } from "./styles";
import { Div, Row, Text } from "../../../../styles/Common";
import { Select } from "../../../../components/Select";
import { theme } from "../../../../styles/theme";
import { Divider } from "antd";
import { CloseIcon } from "../../../../assets/icons";
import { Link } from "../../../../components/Link";
import { Tag } from "../../../../components/Tag";
import { Button } from "../../../../components/Button";

const WithoutDiscountCard = ({
  catList,
  withoutList,
  productsTreatmentsList,
  clinicId,
  handleSaveWithout,
}) => {
  const [arrayCat, setArrayCat] = useState([]);
  const [productsTreatmentsSelected, setproductsTreatmentsSelected] = useState(
    []
  );

  useEffect(() => {
    const ptWithout = withoutList.filter(
      (dis) => Number(dis.clinicId) === Number(clinicId)
    );
    setproductsTreatmentsSelected(ptWithout);
    setArrayCat([]);
  }, [clinicId]);

  const productsTreatmentsFilterList = useMemo(() => {
    const idsCat = arrayCat.map((idc) => Number(idc.id));

    return productsTreatmentsList.filter((pt) =>
      idsCat.includes(Number(pt.cat))
    );
  }, [arrayCat.length]);

  const handleChangeCat = (catSelected) => {
    const catNameSelected = catList.find(
      (cat) => Number(cat.value) === Number(catSelected)
    ).label;
    setArrayCat(
      uniqBy([...arrayCat, { id: catSelected, name: catNameSelected }], "id")
    );
  };

  const removeCategory = (catRemove) => {
    const filterCat = arrayCat.filter(
      (cat) => Number(cat.id) !== Number(catRemove)
    );
    setArrayCat(uniqBy(filterCat, "id"));
  };

  const handleSelectProductTreatment = (ptSelected) => {
    const ptSelValue = productsTreatmentsList.find(
      (ptVal) => Number(ptVal.value) === Number(ptSelected)
    );
    const ptNew = {
      productTreatmentName: ptSelValue.label,
      productTreatmentId: ptSelValue.value,
    };
    setproductsTreatmentsSelected(
      uniqBy([...productsTreatmentsSelected, ptNew], "productTreatmentId")
    );
  };

  const handleRemovePT = (ptRemove) => {
    const ptNewValues = productsTreatmentsSelected.filter(
      (ptVal) => Number(ptVal.productTreatmentId) !== Number(ptRemove)
    );

    setproductsTreatmentsSelected(uniqBy(ptNewValues, "productTreatmentId"));
  };

  return (
    <NoDiscountCard>
      <Row>
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h5}
          color={theme.colors.gray50}
        >
          Without Discounts
        </Text>
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.white}
        >
          Select the products and treatments that don’t qualify for discounts.
        </Text>
      </Row>
      <Row align="center">
        <Select
          label={"Categories"}
          options={catList}
          id="Category"
          value={null}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"348px"}
          onChange={handleChangeCat}
          autoComplete="off"
          optionFilterProp="children"
          filterOption={(input, option) => {
            const optionText = option?.label?.props?.children || "";
            return optionText.toLowerCase().includes(input.toLowerCase());
          }}
          showSearch
        />
      </Row>
      {arrayCat.length > 0 && (
        <Row width="100%" align="left" style={{ flexWrap: "wrap" }}>
          {arrayCat.map((cat, idx) => (
            <Tag
              key={idx}
              closable
              onClose={() => removeCategory(cat.id)}
              closeIcon={<CloseIcon stroke={theme.colors.gray500} />}
              color={theme.colors.gray50}
              width="150px"
            >
              <Text
                size={theme.fonts.size.sm}
                weight={theme.fonts.weight.medium}
                color={theme.colors.gray500}
              >
                {cat.name}
              </Text>
            </Tag>
          ))}
        </Row>
      )}
      <Row align="center">
        <Select
          label={"Products and Treatments"}
          id="ProductsAndTreatments"
          value={null}
          options={productsTreatmentsFilterList}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width={"348px"}
          onChange={handleSelectProductTreatment}
          autoComplete="off"
          optionFilterProp="children"
          filterOption={(input, option) => {
            const optionText = option?.label?.props?.children || "";
            return optionText.toLowerCase().includes(input.toLowerCase());
          }}
          showSearch
        />
      </Row>
      <Row>
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h5}
          color={theme.colors.gray50}
        >
          Products and treatments selected:
        </Text>
      </Row>
      <Row>
        <Div direction="column" width="100%" gap="16px">
          {productsTreatmentsSelected.map((prodTreatment) => (
            <>
              <Div justify="space-between">
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.sm}
                  color={theme.colors.white}
                >
                  {prodTreatment.productTreatmentName}
                </Text>
                <Link
                  onClick={() =>
                    handleRemovePT(prodTreatment.productTreatmentId)
                  }
                >
                  <CloseIcon width={20} stroke={theme.colors.gray50} />
                </Link>
              </Div>
              <Divider
                style={{
                  height: "1px",
                  backgroundColor: theme.colors.gray600,
                  margin: 0,
                }}
              />
            </>
          ))}
        </Div>
      </Row>
      <Div direction="column" height="100%" justify="flex-end">
        <Button
          onClick={() => handleSaveWithout(productsTreatmentsSelected)}
          width="119px"
          height="50px"
        >
          Save
        </Button>
      </Div>
    </NoDiscountCard>
  );
};

export default WithoutDiscountCard;
