import { useState, useEffect } from "react";
import { Upload } from "antd";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Input } from "../../../components/Input";
import { Link } from "../../../components/Link";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { AddPhotoIcon, ChevronLeftIcon } from "../../../assets/icons";
import success from "../../../assets/icons/checkuplist.svg";
import { theme } from "../../../styles/theme";
import useEditClinic from "./hooks/useEditClinic";
import { ClinicCard, InfoCard, LogoCard } from "./styles";
import {
  convertFileToBase64,
  createURLFromBase64,
  isAccountOwner,
} from "../../../utilities/helpers";
import { validateEditClinicForm } from "../../../utilities/validations";
import useGetClinicById from "./hooks/useGetClinicById";
import { SetUpClinic } from "../CreateClinic/components/SetUpClinic";
import useGetProfile from "../../Profile/hooks/useGetProfile";

export const EditClinic = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const editClinic = useEditClinic();

  const { clinics } = useSelector((state) => state);
  const clinic = clinics?.selectedClinic?.clinic || {};
  const user = clinics?.selectedClinic?.user || {};

  const getClinicById = useGetClinicById(clinic?.id);
  const { data: dataClinic, isSuccess: isSuccessDataClinic } = getClinicById;

  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [clinicForm, setClinicForm] = useState({
    clinicName: "",
    ein: "",
    address: "",
    clinicPhone: null,
    taxes: null,
    website: "",
    image: "",
    ig: "",
    fb: "",
  });

  const [quoteImg, setQuoteImg] = useState(null);
  const [prevQuoteImage, setPrevQuoteImage] = useState(null);
  const [setUpClinicForm, setSetUpClinicForm] = useState({
    quoteImage: null,
    brandColor: "#2CBBAF",
    expirationDays: "",
    customerNaming: "",
    consultationFeeName: "",
    consultationFeeType: "",
    consultationFeeValue: null,
    consultationFeeOnbyDefault: false,
    itemizedPricingOnbyDefault: false,
  });

  useEffect(() => {
    if (isSuccessDataClinic && dataClinic?.data?.data) {
      setClinicForm({
        clinicName: dataClinic?.data?.data.name,
        ein: dataClinic?.data?.data.ein,
        address: dataClinic?.data?.data.address,
        clinicPhone: dataClinic?.data?.data.phone,
        taxes: dataClinic?.data?.data.taxes,
        website: dataClinic?.data?.data.website,
        image: dataClinic?.data?.data.image,
        ig: dataClinic?.data?.data.ig,
        fb: dataClinic?.data?.data.fb,
      });
      setSetUpClinicForm({
        quoteImage: dataClinic?.data?.data.quoteImage || null,
        brandColor: dataClinic?.data?.data.brandColor || "#2CBBAF",
        expirationDays: dataClinic?.data?.data.expirationDays || null,
        customerNaming: dataClinic?.data?.data.customerNaming || "",
        consultationFeeName: dataClinic?.data?.data.consultationFeeName || "",
        consultationFeeType: dataClinic?.data?.data.consultationFeeType || "",
        consultationFeeValue:
          dataClinic?.data?.data.consultationFeeValue || null,
        consultationFeeOnbyDefault:
          dataClinic?.data?.data.consultationFeeOnbyDefault || null,
        itemizedPricingOnbyDefault:
          dataClinic?.data?.data.itemizedPricingOnbyDefault || null,
      });
      setQuoteImg(dataClinic?.data?.data.quoteImage || null);
      setPrevQuoteImage(dataClinic?.data?.data.quoteImage || null);
    }
  }, [dataClinic, isSuccessDataClinic]);

  const getProfile = useGetProfile();
  const { data: dataProfile, isLoading } = getProfile;
  const [profile, setProfile] = useState({ user: {} });
  useEffect(() => {
    if (dataProfile) {
      setProfile(dataProfile?.data?.data);
    }
  }, [dataProfile]);

  const [errorForm, setErrorForm] = useState({
    clinicName: {
      error: false,
      message: "",
    },
    ein: {
      error: false,
      message: "",
    },
    address: {
      error: false,
      message: "",
    },
    clinicPhone: {
      error: false,
      message: "",
    },
    taxes: {
      error: false,
      message: "",
    },
    website: {
      error: false,
      message: "",
    },
    image: {
      error: false,
      message: "",
    },
    ig: {
      error: false,
      message: "",
    },
    fb: {
      error: false,
      message: "",
    },
  });
  const [imageLogo, setImageLogo] = useState(null);
  const [prevImageLogo, setPrevImageLogo] = useState(null);
  useEffect(() => {
    if (!clinics?.selectedClinic?.clinic) {
      navigate("/clinics");
    } else {
      setImageLogo(clinic.image);
      setPrevImageLogo(`${clinic.image}?${new Date().getTime()}`);
    }
  }, [clinics?.selectedClinic]);

  const handleUploadLogo = async (dataLogo) => {
    const file = dataLogo.file;
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      toast.error("The image must be .png or .jpg file");
    } else {
      const urlBase64 = await toast.promise(convertFileToBase64(file), {
        loading: "Uploading...",
        success: "Upload completed",
        error: "Upload failed, try again",
      });
      setImageLogo(urlBase64);
      setPrevImageLogo(createURLFromBase64(urlBase64, "image/jpeg"));
    }
  };

  const handleChangeClinic = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newClinicForm = clinicForm;

    newClinicForm[id] = value;

    setClinicForm(newClinicForm);
    setForceUpdate(!forceUpdate);
  };

  const isValidColor = (color) => {
    const s = new Option().style;
    s.color = color;
    return s.color !== "";
  };

  const handleEditClinic = () => {
    toast.remove();
    const validation = validateEditClinicForm.validate(clinicForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else if (!isValidColor(setUpClinicForm?.brandColor)) {
      setForceUpdate(!forceUpdate);
    } else {
      editClinic.reset();
      editClinic.mutate(
        {
          id: clinic.id,
          name: clinicForm.clinicName,
          ein: clinicForm.ein,
          address: clinicForm.address,
          phone: clinicForm.clinicPhone,
          taxes: clinicForm.taxes,
          image:
            imageLogo === null || imageLogo.includes("http")
              ? null
              : `data:image/png;base64,${imageLogo}`,
          website: clinicForm.website,
          ig: clinicForm.ig,
          fb: clinicForm.fb,
          quoteImage:
            quoteImg === null || quoteImg.includes("http")
              ? null
              : `data:image/png;base64,${quoteImg}`,
          brandColor: setUpClinicForm.brandColor,
          expirationDays: Number(setUpClinicForm.expirationDays),
          customerNaming: setUpClinicForm.customerNaming,
          consultationFeeName: setUpClinicForm.consultationFeeName,
          consultationFeeType: setUpClinicForm.consultationFeeType,
          consultationFeeValue: Number(setUpClinicForm.consultationFeeValue),
          consultationFeeOnbyDefault:
            setUpClinicForm.consultationFeeOnbyDefault,
          itemizedPricingOnbyDefault:
            setUpClinicForm.itemizedPricingOnbyDefault,
        },
        {
          onSuccess: () => {
            setShowModal(true);
          },
          onError: (err) => {
            toast.error(err.response.data.error.message);
          },
        }
      );
    }
  };

  const handleClose = () => {
    if (!isAccountOwner()) {
      navigate("/clinics");
    } else {
      queryClient.invalidateQueries(["getClinicById"]);
    }
    setShowModal(false);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Modal open={showModal} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={success}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 83px 0px 83px"}
          title={""}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="15px 0 24px 0"
          maxHeight={200}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0 27px 0px 27px">
              <Text
                size={theme.fonts.size.h4}
                weight={theme.fonts.weight.semibold}
                color={theme.colors.gray950}
              >
                The information has been updated successfully
              </Text>
            </Row>
            <Row width="100%" p="30px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleClose}
                width="100%"
                variant="filled"
              >
                {!isAccountOwner() ? "Go to practice list" : "My Practice"}
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      {!isAccountOwner() && (
        <Row height="18px" align="center" m="0px 0px 37px 0px">
          <Link
            onClick={() => navigate(-1)}
            size={theme.fonts.size.sm}
            color={theme.colors.green}
            style={{ gap: "5px" }}
          >
            <ChevronLeftIcon stroke={theme.colors.green} />
            Go back
          </Link>
        </Row>
      )}

      <Row height="40px" align="center" m="0px 0px 37px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Edit Practice
          </Text>
        </Div>
      </Row>

      <Row align="center" m="0px 0px 28px 0px" justify="center">
        <ClinicCard>
          <Col m="0px 28px 0px 28px" width="100%">
            <Row align="center" m="0px 0px 28px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Practice Information
              </Text>
            </Row>
            <Row
              align="center"
              m="0px 0px 27px 0px"
              style={{ justifyContent: "center" }}
            >
              <Upload
                multiple={false}
                showUploadList={false}
                customRequest={handleUploadLogo}
              >
                {imageLogo ? (
                  <LogoCard background={prevImageLogo} />
                ) : (
                  <LogoCard>
                    <AddPhotoIcon stroke={theme.colors.gray500} />
                    <Link
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray500}
                    >
                      Add Logo
                    </Link>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.xs}
                      color={theme.colors.gray500}
                    >
                      Size: W 177 px - H 130 px
                    </Text>
                  </LogoCard>
                )}
                <Link
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.sm}
                  color={theme.colors.green}
                >
                  Change Logo
                </Link>
              </Upload>
            </Row>
            <Row align="center" m="0px 0px 15px 0px">
              <Input
                label={"Practice Name*"}
                id="clinicName"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                value={clinicForm.clinicName}
                onChange={handleChangeClinic}
                error={errorForm.clinicName.error}
                helper={
                  errorForm.clinicName.error ? errorForm.clinicName.message : ""
                }
              />
            </Row>
            <Row align="center" m="0px 0px 15px 0px">
              <Input
                label={"URL Website"}
                id="website"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                value={clinicForm.website}
                onChange={handleChangeClinic}
                error={errorForm.website.error}
                helper={
                  errorForm.website.error ? errorForm.website.message : ""
                }
              />
            </Row>
            <Row align="center" m="0px 0px 10px 0px">
              <Input
                label={"Address*"}
                id="address"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                width="100%"
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                value={clinicForm.address}
                onChange={handleChangeClinic}
                error={errorForm.address.error}
                helper={
                  errorForm.address.error ? errorForm.address.message : ""
                }
              />
            </Row>
          </Col>
        </ClinicCard>

        <InfoCard>
          <Col m="0px 28px 0px 40px" width="100%">
            <Row align="center" m="0px 0px 12px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                More Information
              </Text>
            </Row>
            <Row align="center" m="0px 0px 20px 0px" justify="space-between">
              <Input
                label={"Practice Phone Number*"}
                id="clinicPhone"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                value={clinicForm.clinicPhone}
                onChange={handleChangeClinic}
                error={errorForm.clinicPhone.error}
                helper={
                  errorForm.clinicPhone.error
                    ? errorForm.clinicPhone.message
                    : ""
                }
              />
              <Input
                label={"Taxes*"}
                id="taxes"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"111px"}
                suffix={"%"}
                value={clinicForm.taxes}
                onChange={handleChangeClinic}
                error={errorForm.taxes.error}
                helper={errorForm.taxes.error ? errorForm.taxes.message : ""}
              />
              <Input
                label={"EIN (Optional)"}
                id="ein"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"148px"}
                value={clinicForm.ein}
                onChange={handleChangeClinic}
                error={errorForm.ein.error}
                helper={errorForm.ein.error ? errorForm.ein.message : ""}
              />
            </Row>
            <Row align="center" m="0px 0px 40px 0px" justify="space-between">
              <Input
                label={"Instagram (Optional)"}
                id="ig"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                value={clinicForm.ig}
                onChange={handleChangeClinic}
              />
              <Input
                label={"Facebook (Optional)"}
                id="fb"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"288px"}
                value={clinicForm.fb}
                onChange={handleChangeClinic}
              />
            </Row>
            <Row align="center" m="0px 0px 10px 0px">
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.h4}
                color={theme.colors.white}
              >
                Admin Information
              </Text>
            </Row>
            <Row align="center" m="0px 0px 19px 0px">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.default}
                color={theme.colors.gray400}
              >
                To edit admin information, please go to the Users page.
              </Text>
            </Row>
            <Row align="center" m="0px 0px 15px 0px" justify="space-between">
              <Input
                label={"First Name*"}
                id="adminName"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                value={user?.name}
                disabled
              />
              <Input
                label={"Last Name*"}
                id="lastName"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                value={user?.lastName}
                disabled
              />
            </Row>
            <Row align="center" m="0px 0px 15px 0px" justify="space-between">
              <Input
                label={"Phone number*"}
                id="adminPhone"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                value={!isAccountOwner() ? user?.phone : profile?.user?.phone}
                disabled
              />
              <Input
                label={"Email*"}
                id="email"
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                background={theme.colors.gray600}
                color={theme.colors.gray400}
                width={"285px"}
                value={
                  !isAccountOwner()
                    ? clinics?.selectedClinic?.email
                    : profile?.email
                }
                disabled
              />
            </Row>
          </Col>
        </InfoCard>
      </Row>

      <Row align="center" m="0px 0px 28px 0px" justify="left">
        <SetUpClinic
          setUpClinicForm={setUpClinicForm}
          setSetUpClinicForm={setSetUpClinicForm}
          quoteImg={quoteImg}
          setQuoteImg={setQuoteImg}
          prevQuoteImage={prevQuoteImage}
          setPrevQuoteImage={setPrevQuoteImage}
        />
      </Row>

      <Row align="center" m="0px 0px 37px 0px">
        <Button
          variant={"outlined"}
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          onClick={() => navigate(-1)}
          width="190px"
          m="0 24px 0 0"
        >
          Cancel
        </Button>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          onClick={handleEditClinic}
          width="190px"
          loading={editClinic.isLoading}
        >
          Save Information
        </Button>
      </Row>
    </Container>
  );
};
