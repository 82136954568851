import React, { useRef } from "react";
import { Text } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { CustomRangePicker } from "./styles";
import { ConfigProvider } from "antd";
import { CalendarNewIcon, ChevronCalendar } from "../../assets/icons";

export const DateRangePicker = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  sizeLabel,
  weightLabel,
  colorLabel,
  ...rest
}) => {
  const rangePickerRef = useRef(null);

  const handleClose = () => {
    if (rangePickerRef.current) {
      rangePickerRef.current.blur();
    }
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgElevated: theme.colors.gray700,
          colorBgContainerDisabled: theme.colors.gray950,
          colorPrimary: theme.colors.purple400,
          colorText: theme.colors.gray400,
          colorTextHeading: theme.colors.gray400,
          colorTextDisabled: theme.colors.gray500,
          colorTextLightSolid: theme.colors.white,
          colorTextTertiary: theme.colors.purple200,
          controlItemBgActive: theme.colors.purple400T,
          controlItemBgHover: theme.colors.purple,
        },
      }}
    >
      <div style={{ width }}>
        {label && (
          <Text
            weight={weightLabel}
            size={sizeLabel || theme.fonts.size.sm}
            color={colorLabel || theme.colors.gray500}
            mt="0px"
            mb="7px"
            ml="6px"
            align="left"
          >
            {label}
          </Text>
        )}
        <CustomRangePicker
          placeholder={placeholder}
          suffix={suffix}
          suffixIcon={<CalendarNewIcon />}
          separator={<ChevronCalendar />}
          status={error ? "error" : ""}
          disabled={disabled}
          onChange={onChange}
          format="MMM-DD-YY"
          style={{ width: width }}
          className={helper ? "has-helper" : ""}
          ref={rangePickerRef}
          renderExtraFooter={() => (
            <Text
              onClick={handleClose}
              mr="25px"
              color={theme.colors.white150}
              style={{ cursor: "pointer", textAlign: "right" }}
            >
              Close
            </Text>
          )}
          {...rest}
        />
        {helper && (
          <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
            {helper}
          </Text>
        )}
      </div>
    </ConfigProvider>
  );
};
