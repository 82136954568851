import styled from "styled-components";
import { theme } from "../../styles/theme";

export const statusColor = (status) => {
  switch (status) {
    case "inProgress":
      return theme.colors.blue;
    case "overdue":
      return theme.colors.red;
    case "pending":
      return theme.colors.yellow;
    case "paid":
      return theme.colors.green;
    case "voided":
      return theme.colors.purple200;
    default:
      return theme.colors.gray50;
  }
};

export const StatusMark = styled.div`
  width: 11px;
  height: 5px;

  background: ${({ status }) => statusColor(status)};
  border-radius: 5px;
`;

export const ButtonViewQuote = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 10px;
  color: ${theme.colors.gray50};
  gap: 5px;
  border: 1px solid ${theme.colors.gray50};
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.gray700};
    border: 1px solid ${theme.colors.green};
    color: ${theme.colors.green};
  }

  &:hover > svg {
    fill: ${theme.colors.green};
  }
`;
