import toast from "react-hot-toast";
import { Col, Container, Row, Text } from "../../../../../styles/Common";
import { Modal } from "../../../../../components/Modal";
import { Button } from "../../../../../components/Button";
import { Tag } from "../../../../../components/Tag";
import { theme } from "../../../../../styles/theme";
import { AlertIcon } from "../../../../../assets/icons";
import usePayQuoteById from "../../hooks/usePayQuoteById";
import { useQueryClient } from "react-query";

const ModalPaidInFull = ({
  showModalPaid,
  setShowModalPaid,
  quoteId,
  handleCloseModalPay,
  selectedCloser,
  setSelectedCloser,
  selectedProviders,
}) => {
  const queryClient = useQueryClient();
  const payQuote = usePayQuoteById();

  const handlePayQuote = () => {
    payQuote.mutate(
      { formData: { closer: selectedCloser?.value }, quoteId },
      {
        onSuccess: () => {
          toast.success("The quotation has been paid in full");
          queryClient.invalidateQueries(["getQuotateById", quoteId]);
          setShowModalPaid(false);
          setSelectedCloser({
            value: null,
            label: "Organic",
          });
        },
        onError: (err) => {
          toast.error(
            err.response.data.error.description ||
              "Error trying to paid the quote"
          );
        },
      }
    );
  };

  return (
    <Container>
      <Modal
        open={showModalPaid}
        onCancel={handleCloseModalPay}
        width={"407px"}
      >
        <Modal.Header
          icon={<AlertIcon stroke={theme.colors.yellow} />}
          iconBorderColor={theme.colors.yellow}
          iconWidth="50px"
          iconHeight="50px"
          align="-webkit-center"
          title="Mark as paid"
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="15px 0 24px 0"
          maxHeight={454}
          align="center"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" m="0 0 10px 0" p="0 27px 0px 27px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                This action will mark this treatment as 'paid.' Once confirmed,
                this quote cannot be edited.
              </Text>
            </Row>
            <Row
              width="100%"
              m="0 0 18px 0"
              justify="center"
              style={{ flexWrap: "wrap" }}
            >
              {selectedProviders.length > 0 ? (
                <>
                  <Text
                    size={theme.fonts.size.xs}
                    weight={theme.fonts.weight.semibold}
                    color={theme.colors.gray800}
                    style={{ padding: "0 27px 0px 27px" }}
                  >
                    The sale will be registered under the following staff
                    members:
                  </Text>
                  {selectedProviders?.map((provider, idx) => (
                    <Tag key={idx} color={theme.colors.green40} width="110px">
                      <Text
                        size={theme.fonts.size.xs}
                        weight={theme.fonts.weight.semibold}
                        color={theme.colors.gray800}
                      >
                        {provider.name}
                      </Text>
                    </Tag>
                  ))}
                </>
              ) : (
                <Text
                  size={theme.fonts.size.xs}
                  weight={theme.fonts.weight.semibold}
                  color={theme.colors.gray800}
                >
                  No staff members assigned
                </Text>
              )}
            </Row>
            <Row
              width="100%"
              m="0 0 10px 0"
              p="0 27px 0px 27px"
              justify="center"
            >
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                Are you sure you want to continue?
              </Text>
            </Row>
            <Row width="100%" justify="center" m="0 0 16px 0">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handlePayQuote}
                width="226px"
                loading={payQuote.isLoading}
                variant="filled"
              >
                Yes, continue
              </Button>
            </Row>
            <Row width="100%" justify="center">
              <Button
                variant={"outlined"}
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleCloseModalPay}
                width="226px"
              >
                No, cancel
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default ModalPaidInFull;
