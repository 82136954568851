import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { ClientsIcon } from "../../../../assets/icons";

const ModalPatient = ({ showModal, handleCloseModal }) => {
  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"407px"}>
      <Modal.Header
        icon={
          <ClientsIcon
            stroke={theme.colors.green}
            width={"40px"}
            height={"40px"}
          />
        }
        iconBorderColor={theme.colors.green}
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        padding={"20px 63px 0px 63px"}
        title={"New Patient has been created"}
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" p="0 27px 0px 27px">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              {"The patient has been created successfully"}
            </Text>
          </Row>
          <Row width="100%" p="30px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleCloseModal}
              width="100%"
              variant="filled"
            >
              {"Continue"}
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPatient;
