import React, { useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row, Text, Div } from "../../styles/Common";
import { Card } from "../../components/Card";
import { theme } from "../../styles/theme";
import { Button } from "../../components/Button";
import { Password } from "../../components/Password";
import useResetPassword from "./hooks/useResetPassword";
import { validateResetPassword } from "../../utilities/validations";
import { Modal } from "../../components/Modal";
import success from "../../assets/icons/check-password.svg";
import imageForgotPassword from "../../assets/images/imageForgotPassword.svg";
import { Link } from "../../components/Link";

export const ResetPassword = () => {
  const resetPassword = useResetPassword();
  const { isLoading } = resetPassword;
  const navigate = useNavigate();
  const { search } = useLocation();
  const hash = new URLSearchParams(search).get("hash");
  const [passwordForm, setPasswordForm] = useState({
    password: "",
    passwordConfirm: "",
  });

  const [errorForm, setErrorForm] = useState({
    password: {
      error: false,
      message: "",
    },
    passwordConfirm: {
      error: false,
      message: "",
    },
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newPasswordForm = passwordForm;
    newPasswordForm[id] = value;
    setPasswordForm(newPasswordForm);
    setForceUpdate(!forceUpdate);
  };

  const handleReset = () => {
    toast.remove();
    const validation = validateResetPassword.validate(passwordForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else if (passwordForm.password === passwordForm.passwordConfirm) {
      resetPassword.reset();
      resetPassword.mutate(
        { resetPassToken: hash, password: passwordForm.password },
        {
          onSuccess: () => {
            setShowSuccess(true);
          },
          onError: (err) => {
            toast.error(err.response.data.error.message);
          },
        }
      );
    } else {
      toast.error("passwords must be equal");
    }
  };
  const handleClose = () => {
    setShowSuccess(false);
    navigate("/");
  };

  return (
    <>
      <Modal open={showSuccess} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={success}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"26px 0 0px 0"}
          title={"New Password Successful"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="18px 0 24px 0"
          maxHeight={200}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="0 20px 0px 20px">
              <Text
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.regular}
                color={theme.colors.gray500}
              >
                Your password has been updated and now you can login
              </Text>
            </Row>
            <Row width="100%" p="30px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleClose}
                width="100%"
                loading={isLoading}
                background={theme.colors.green}
                color={theme.colors.white}
                hBackground={theme.colors.green600}
                hActiveBackground={theme.colors.white}
                hActvieBorder={theme.colors.gray500}
                hActviecolor={theme.colors.gray500}
              >
                Login
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <>
        <Row
          width="100%"
          height="100%"
          style={{ background: theme.colors.white50 }}
        >
          <Col width="50%" height="100vh" align="center" justify="center">
            <img
              src={imageForgotPassword}
              alt="image Forgot Password"
              width="573px"
              height="580px"
            />
          </Col>
          <Col width="50%" height="100vh" align="center" justify="center">
            <Card
              width="417px"
              height="650px"
              style={{
                borderRadius: "23px",
                background:
                  "linear-gradient(121deg, rgba(255, 255, 255, 0.45) -42.27%, rgba(217, 217, 217, 0.45) 145.68%)",
                border: "1.5px solid rgba(0, 0, 0, 0.55)",
              }}
            >
              <Row width="100%" m="2px 0 0 0" justify="center">
                <Text
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.h4}
                  color={theme.colors.gray950}
                >
                  Reset your password
                </Text>
              </Row>
              <Row width="100%" m="24px 0 0 0" p="0px 18px 0px 18px">
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Create a new password that meets the following requirements.
                </Text>
              </Row>
              <Row width="100%" m="23px 0 0 0">
                <Password
                  label="New password"
                  id="password"
                  width="100%"
                  background={theme.colors.white250}
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  border={theme.colors.gray500}
                  onChange={handleChange}
                  error={errorForm.password.error}
                  helper={
                    errorForm.password.error ? errorForm.password.message : ""
                  }
                  backgroundIcon={theme.colors.white150}
                />
              </Row>
              <Row width="100%" m="16px 0 0 0">
                <Password
                  label="Repeat password"
                  id="passwordConfirm"
                  width="100%"
                  background={theme.colors.white250}
                  weightLabel={theme.fonts.weight.regular}
                  sizeLabel={theme.fonts.size.sm}
                  border={theme.colors.gray500}
                  onChange={handleChange}
                  error={errorForm.passwordConfirm.error}
                  helper={
                    errorForm.passwordConfirm.error
                      ? errorForm.passwordConfirm.message
                      : ""
                  }
                  backgroundIcon={theme.colors.white150}
                />
              </Row>

              <Div
                style={{
                  background: theme.colors.gray350,
                  borderRadius: "11px",
                  marginTop: "24px",
                  minwidth: "331px",
                  minheight: "133px",
                }}
              >
                <Col width="330px" height="auto">
                  <Row width="100%" m="18px 0 0 24px" align="baseline">
                    <Div
                      style={{
                        background: theme.colors.green,
                        borderRadius: "100%",
                        width: "8px",
                        height: "8px",
                        marginRight: "6px",
                        alignItems: "left",
                      }}
                    ></Div>
                    <Text
                      weight={theme.fonts.weight.regular}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray500}
                      align={"-webkit-left"}
                    >
                      8 to 16 characters long
                    </Text>
                  </Row>
                  <Row width="100%" m="8px 0 0 24px" align="center">
                    <Div
                      style={{
                        background: theme.colors.green,
                        borderRadius: "100%",
                        width: "8px",
                        height: "8px",
                        marginRight: "6px",
                      }}
                    ></Div>
                    <Text
                      weight={theme.fonts.weight.regular}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray500}
                    >
                      At least one uppercase letter
                    </Text>
                  </Row>
                  <Row width="100%" m="8px 0 0 24px" align="center">
                    <Div
                      style={{
                        background: theme.colors.green,
                        borderRadius: "100%",
                        width: "8px",
                        height: "8px",
                        marginRight: "6px",
                      }}
                    ></Div>
                    <Text
                      weight={theme.fonts.weight.regular}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray500}
                    >
                      At least one lowercase letter
                    </Text>
                  </Row>
                  <Row width="100%" m="8px 0 0 24px" align="center">
                    <Div
                      style={{
                        background: theme.colors.green,
                        borderRadius: "100%",
                        width: "8px",
                        height: "8px",
                        marginRight: "6px",
                      }}
                    ></Div>
                    <Text
                      weight={theme.fonts.weight.regular}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray500}
                    >
                      At least one number
                    </Text>
                  </Row>
                  <Row width="100%" m="8px 0 18px 24px" align="baseline">
                    <Div
                      style={{
                        background: theme.colors.green,
                        borderRadius: "100%",
                        width: "8px",
                        height: "8px",
                        marginRight: "6px",
                      }}
                    ></Div>
                    <Text
                      weight={theme.fonts.weight.regular}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray500}
                      align={"-webkit-left"}
                    >
                      At least one special character (#?!@$%^&*-.)
                    </Text>
                  </Row>
                </Col>
              </Div>

              <Row m="22px 0 0 0">
                <Button
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.semibold}
                  onClick={handleReset}
                  width="100%"
                  loading={isLoading}
                  background={theme.colors.green}
                  color={theme.colors.white}
                  hBackground={theme.colors.green600}
                  hActiveBackground={theme.colors.white}
                  hActvieBorder={theme.colors.gray500}
                  hActviecolor={theme.colors.gray500}
                >
                  Reset password
                </Button>
              </Row>
              <Row width="100%" m="16px 0 0 0">
                <Link
                  size={theme.fonts.size.default}
                  weight={theme.fonts.weight.semibold}
                  onClick={handleClose}
                  width="100%"
                  style={{ justifyContent: "center" }}
                  color={theme.colors.gray600}
                  underline
                >
                  Cancel
                </Link>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    </>
  );
};
