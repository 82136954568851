import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { chunk, sumBy } from "lodash";
import dayjs from "dayjs";
import { Container } from "../../styles/Common";
import { Row, Div, Text, Col } from "../../styles/Common";
import { theme } from "../../styles/theme";
import { InfoCard, SalesCard } from "./styles";
import { Select } from "../../components/Select";
import { Button } from "../../components/Button";
import {
  isSuperAdmin,
  userClinic,
  isRegular,
  getUserInfo,
  downloadFile,
  isProvider,
} from "../../utilities/helpers";
import { ChevronDownIcon } from "../../assets/icons";
import { reportTimeExpresion, reportTimeOptions } from "./helpers";
import { DateRangePicker } from "../../components/DateRangePicker";
import useGetSales from "./hooks/useGetSales";
import useGetCostsProfits from "./hooks/useGetCostsProfits";
import useGetTaxes from "./hooks/useGetTaxes";
import useGetTopSellers from "./hooks/useGetTopSellers";
import { formatPriceToDollar } from "../../utilities/helpers";
import { AreaChart, Area, XAxis, Tooltip } from "recharts";
import { CostProfit } from "./components/CostProfit";
import { GraphTooltip } from "./components/GraphTooltip";
import { Taxes } from "./components/Taxes";
import { TopSellers } from "./components/TopSellers";
import { RegularUser } from "./components/RegularUser";
import toast from "react-hot-toast";
import { axiosClient, getLocalUser } from "../../core/services/axiosInstance";

export const Home = () => {
  const superAdmin = isSuperAdmin();
  const regularUser = isRegular() || isProvider();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const { name: userClinicName, id: userClinicId } = userClinic();
  const { id: userId } = getUserInfo();
  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );

  const [selectedReportTime, setSelectedReportTime] = useState({
    value: 5,
    label: "This Month",
  });
  const [showCalendar, setShowCalendar] = useState(false);
  const [customInitialDate, setCustomInitialDate] = useState(
    dayjs().startOf("month").startOf("day")
  );
  const [customFinalDate, setCustomFinalDate] = useState(
    dayjs().endOf("month").endOf("day")
  );

  const [payloadOptions, setPayloadOptions] = useState({
    clinicId: selectedClinic?.value || 0,
    days: selectedReportTime.value,
    startDate: null,
    endDate: null,
    userId: null,
  });

  const getSales = useGetSales(payloadOptions);
  const { data: dataSales, isLoading } = getSales;
  const [salesList, setSalesList] = useState([]);
  const [groupedSales, setGroupedSales] = useState();

  const getCostsProfits = useGetCostsProfits(payloadOptions);
  const { data: dataCostsProfits, isLoading: isLoadingCostsProfits } =
    getCostsProfits;
  const [costsProfitsList, setCostsProfitsList] = useState([]);
  const [groupedCostProfits, setGropuedCostProfits] = useState();

  const getTaxes = useGetTaxes(payloadOptions);
  const { data: dataTaxes, isLoading: isLoadingTaxes } = getTaxes;
  const [taxesList, setTaxesList] = useState([]);
  const [groupedTaxesList, setGroupedTaxesList] = useState();

  const getTopSellers = useGetTopSellers(payloadOptions);
  const { data: dataTopSellers, isLoading: isLoadingTopSellers } =
    getTopSellers;
  const [topSellersList, setTopSellersList] = useState([]);

  useEffect(() => {
    if (selectedClinic && selectedClinic.value) {
      setPayloadOptions({
        clinicId: selectedClinic.value,
        days: 30,
        startDate: null,
        endDate: null,
        userId: regularUser ? userId : null,
      });
    }
  }, [selectedClinic]);

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "clinic":
        setCustomInitialDate(dayjs().startOf("month").startOf("day"));
        setCustomFinalDate(dayjs().endOf("month").endOf("day"));
        setSelectedClinic(option);
        setSelectedReportTime({ value: 5, label: "This Month" });
        break;
      case "reportTime":
        setCustomInitialDate();
        setCustomFinalDate();
        setSelectedReportTime(option);
        if (option.label.props.children === "Today") {
          const today = new Date();
          today.setHours(0, 0, 0, 0);

          const endOfDay = new Date(today);
          endOfDay.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(today));
          setCustomFinalDate(dayjs(endOfDay));
        }
        if (option.label.props.children === "Yesterday") {
          const yesterday = new Date();
          yesterday.setDate(yesterday.getDate() - 1);
          yesterday.setHours(0, 0, 0, 0);

          const endOfDay = new Date(yesterday);
          endOfDay.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(yesterday));
          setCustomFinalDate(dayjs(endOfDay));
        }
        if (option.label.props.children === "This Week") {
          const today = new Date();
          const dayOfWeek = today.getDay();
          const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() + diffToMonday);
          startOfWeek.setHours(0, 0, 0, 0);

          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(startOfWeek.getDate() + 6);
          endOfWeek.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(startOfWeek));
          setCustomFinalDate(dayjs(endOfWeek));
        }
        if (option.label.props.children === "Last Week") {
          const today = new Date();
          const dayOfWeek = today.getDay();
          const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;

          const startOfLastWeek = new Date(today);
          startOfLastWeek.setDate(today.getDate() - 7 + diffToMonday);
          startOfLastWeek.setHours(0, 0, 0, 0);

          const endOfLastWeek = new Date(startOfLastWeek);
          endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);
          endOfLastWeek.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(startOfLastWeek));
          setCustomFinalDate(dayjs(endOfLastWeek));
        }
        if (option.label.props.children === "This Month") {
          const today = new Date();

          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth(),
            1
          );
          startOfMonth.setHours(0, 0, 0, 0);

          const endOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() + 1,
            0
          );
          endOfMonth.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(startOfMonth));
          setCustomFinalDate(dayjs(endOfMonth));
        }
        if (option.label.props.children === "Last Month") {
          const today = new Date();

          const startOfMonth = new Date(
            today.getFullYear(),
            today.getMonth() - 1,
            1
          );
          startOfMonth.setHours(0, 0, 0, 0);

          const endOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);
          endOfMonth.setHours(23, 59, 59, 999);

          setCustomInitialDate(dayjs(startOfMonth));
          setCustomFinalDate(dayjs(endOfMonth));
        }
        if (option.label.props.children === "This Year") {
          const today = new Date();
          const startOfYear = new Date(today.getFullYear(), 0, 1);
          startOfYear.setHours(0, 0, 0, 0);

          const endOfYear = new Date(today.getFullYear(), 11, 31);
          endOfYear.setHours(23, 59, 59, 999);
          setCustomInitialDate(dayjs(startOfYear));
          setCustomFinalDate(dayjs(endOfYear));
        }
        if (option.label.props.children === "Last Year") {
          const today = new Date();
          const startOfYear = new Date(today.getFullYear() - 1, 0, 1);
          startOfYear.setHours(0, 0, 0, 0);

          const endOfYear = new Date(today.getFullYear() - 1, 11, 31);
          endOfYear.setHours(23, 59, 59, 999);
          setCustomInitialDate(dayjs(startOfYear));
          setCustomFinalDate(dayjs(endOfYear));
        }

        if (option.label.props.children === "Custom") {
          setShowCalendar(true);
        }
        break;
    }
  };

  const handleChangeDate = (value, date) => {
    setSelectedReportTime({ value: 0, label: "Custom" });
    setCustomInitialDate(value[0]);
    setCustomFinalDate(value[1]);
  };

  const handleGenerateReport = async () => {
    let urlString = `/quote/general-report/byperiod?clinicId=${payloadOptions.clinicId}`;
    if (payloadOptions.days) {
      urlString += `&days=${payloadOptions.days}`;
    }

    if (!payloadOptions.days) {
      urlString += `&startDate=${payloadOptions.startDate}&endDate=${payloadOptions.endDate}`;
    }

    await toast.promise(
      downloadFile(
        urlString,
        `Report-${payloadOptions.clinicId}.xlsx`,
        axiosClient,
        getLocalUser()
      ),
      {
        loading: `Loading download.....`,
        success: "Download completed",
        error: "Download failed",
      }
    );
  };

  const groupItems = (data) => {
    switch (selectedReportTime.value) {
      case 1:
      case 2:
      case 3:
      case 4:
        return chunk(data, 1);
      case 5:
      case 6:
        return chunk(data, 8);
      case 7:
      case 8:
        return chunk(data, 31);
      default:
        return chunk(data, 7);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      const groupedSales = groupItems(dataSales?.data?.data);
      setSalesList(dataSales?.data?.data);
      setGroupedSales(groupedSales);
    }
  }, [dataSales, isLoading]);

  useEffect(() => {
    if (!isLoadingCostsProfits) {
      const groupuedCP = groupItems(dataCostsProfits?.data?.data);
      setCostsProfitsList(dataCostsProfits?.data?.data);
      setGropuedCostProfits(groupuedCP);
    }
  }, [dataCostsProfits, isLoadingCostsProfits]);

  useEffect(() => {
    if (!isLoadingTaxes) {
      const groupuedTax = groupItems(dataTaxes?.data?.data);
      setTaxesList(dataTaxes?.data?.data);
      setGroupedTaxesList(groupuedTax);
    }
  }, [dataTaxes, isLoadingTaxes]);

  useEffect(() => {
    if (!isLoadingTopSellers) {
      setTopSellersList(dataTopSellers?.data?.data);
    }
  }, [dataTopSellers, isLoadingTopSellers]);

  useEffect(() => {
    if (selectedClinic?.value) {
      setPayloadOptions({
        clinicId: selectedClinic.value,
        days: null,
        startDate: !isNaN(new Date(customInitialDate).getTime())
          ? new Date(customInitialDate)
          : new Date(),
        endDate: !isNaN(new Date(customFinalDate).getTime())
          ? new Date(customFinalDate)
          : new Date(),
        userId: regularUser ? userId : null,
      });
    }
  }, [selectedReportTime, customInitialDate, customFinalDate]);

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Row
        height="40px"
        align="center"
        m="0px 0px 30px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Dashboard
          </Text>
        </Div>
      </Row>
      <InfoCard>
        <Row style={{ justifyContent: "space-between" }}>
          <Row gap="40px">
            <Select
              label={"Practice"}
              id="clinic"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.white}
              border={theme.colors.gray500}
              background={theme.colors.gray800}
              color={theme.colors.white150}
              width={"155px"}
              defaultValue=""
              value={selectedClinic}
              options={optionsClinics}
              suffixIcon={<ChevronDownIcon stroke={theme.colors.gray400} />}
              onChange={(val, option) => handleSelect(val, option, "clinic")}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              disabled={!superAdmin}
              showSearch
            />

            <Select
              label={"Reporting period"}
              id="reportTime"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.white}
              border={theme.colors.gray500}
              background={theme.colors.gray800}
              color={theme.colors.white150}
              width={"139px"}
              defaultValue=""
              value={selectedReportTime}
              options={reportTimeOptions}
              onChange={(val, option) =>
                handleSelect(val, option, "reportTime")
              }
            />

            <DateRangePicker
              label={"Date range"}
              id="rangeDate"
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.white}
              border={theme.colors.gray500}
              background={theme.colors.gray800}
              color={theme.colors.white150}
              width={"235px"}
              value={[customInitialDate, customFinalDate]}
              onChange={(value, date) => handleChangeDate(value, date)}
              open={showCalendar}
              onOpenChange={(open) => setShowCalendar(open)}
            />
          </Row>
          <Col>
            <Div m="9px" />
            {!regularUser && (
              <Button
                variant={"outlined"}
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleGenerateReport}
                width="246.4px"
                height={"49.47px"}
              >
                Generate reports
              </Button>
            )}
          </Col>
        </Row>
      </InfoCard>
      {!regularUser ? (
        <Div
          direction="row"
          width="1005px"
          height="max-content"
          m="40px 0"
          gap="16px"
        >
          {superAdmin && selectedClinic === null ? (
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h4}
              color={theme.colors.green}
            >
              Select a practice to get the reports
            </Text>
          ) : (
            <>
              <Col>
                <SalesCard>
                  <Row
                    width="100%"
                    align="center"
                    justify="space-between"
                    p="0 15px"
                  >
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.h4}
                      color={theme.colors.white}
                    >
                      Sales
                    </Text>

                    <Button
                      variant={"outlined"}
                      size={theme.fonts.size.default}
                      weight={theme.fonts.weight.semibold}
                      onClick={() => console.log("details")}
                      width="144px"
                    >
                      Details
                    </Button>
                  </Row>
                  <Div
                    height="235px"
                    width="454px"
                    m="25px 0"
                    radius="20px"
                    background={theme.colors.gray950}
                    direction="column"
                  >
                    <Row m="21px 21px 0 21px" justify="space-between">
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.h4}
                        color={theme.colors.white}
                      >
                        {`${sumBy(salesList, "amount")} Sales`}
                      </Text>
                      <Text
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.h4}
                        color={theme.colors.green}
                      >
                        {formatPriceToDollar(sumBy(salesList, "value"))}
                      </Text>
                    </Row>
                    <AreaChart
                      width={454}
                      height={200}
                      data={salesList || []}
                      margin={{ top: 0, right: 12, left: 12, bottom: 0 }}
                    >
                      <defs>
                        <linearGradient
                          id="salesGradient"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="0%"
                            stopColor={theme.colors.green}
                            stopOpacity={1}
                          />
                          <stop
                            offset="100%"
                            stopColor={theme.colors.green}
                            stopOpacity={0.2}
                          />
                        </linearGradient>
                      </defs>
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke={theme.colors.green}
                        fill={`url(#salesGradient)`}
                      />
                      <Tooltip content={<GraphTooltip />} />
                      <XAxis dataKey="date" tick={false} />
                    </AreaChart>
                  </Div>
                  {groupedSales?.map((salesGroup, idx) => {
                    const totalSales = salesGroup.reduce(
                      (acc, sale) => acc + sale.amount,
                      0
                    );
                    const totalValue = salesGroup.reduce(
                      (acc, sale) => acc + sale.value,
                      0
                    );
                    return (
                      <Div
                        key={idx}
                        height="57px"
                        width="454px"
                        m="0 0 10px 0"
                        p="0 21px 0 26px"
                        radius="12px"
                        background="rgba(141, 141, 141, 0.18)"
                      >
                        <Row
                          width="100%"
                          align="center"
                          justify="space-between"
                        >
                          <Text
                            weight={theme.fonts.weight.light}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                            style={{ width: "100px", textAlign: "left" }}
                          >
                            {reportTimeExpresion(selectedReportTime.value, idx)}
                          </Text>
                          <Text
                            weight={theme.fonts.weight.regular}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                          >
                            {`${totalSales} sales`}
                          </Text>
                          <Text
                            weight={theme.fonts.weight.semibold}
                            size={theme.fonts.size.h5}
                            color={theme.colors.gray400}
                            style={{ width: "100px", "text-align": "right" }}
                          >
                            {formatPriceToDollar(totalValue)}
                          </Text>
                        </Row>
                      </Div>
                    );
                  })}
                </SalesCard>
                <Taxes
                  taxesList={taxesList}
                  groupedTaxesList={groupedTaxesList}
                  selectedReportTime={selectedReportTime}
                />
              </Col>
              <Col>
                <CostProfit
                  groupedCostProfits={groupedCostProfits}
                  selectedReportTime={selectedReportTime}
                />
                <TopSellers topSellersList={topSellersList} />
              </Col>
            </>
          )}
        </Div>
      ) : (
        <RegularUser
          selectedReportTime={selectedReportTime}
          groupItems={groupItems}
          payloadOptions={payloadOptions}
        />
      )}
    </Container>
  );
};
