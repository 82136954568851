import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

const { TextArea } = Input;

export const CustomInput = styled(TextArea)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  color: ${(props) => props.color || theme.colors.gray950};
  width: ${({ width }) => width || null};
  border: 1px solid ${({ border }) => border || theme.colors.gray500};
  border-radius: ${({ radius }) => radius || "25px"};
  background: ${({ background }) => background || theme.colors.white};
  color: ${({ color }) => color || theme.colors.gray950};
  padding: 0 16px;
  opacity: 1;

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  && {
    &.ant-input,
    &.ant-input-affix-wrapper {
      color: ${({ color }) => color || theme.colors.gray950};
      size: 16px;

      &::placeholder {
        color: ${theme.colors.gray600};
      }

      &:focus {
        outline: 0;
        border: 1px solid ${({ border }) => border || "transparent"} !important;
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${({ border }) => border || theme.colors.gray500} !important;
        box-shadow: initial;
      }

      &:disabled {
        /* Estilos para cuando el input está deshabilitado */
        background: ${({ background }) => background || theme.colors.white};
        cursor: not-allowed;
        color: ${theme.colors.gray400};
        border: 1px solid ${({ border }) => border || theme.colors.gray300};
      }
    }

    &.ant-input-affix-wrapper {
      &-focused {
        outline: 0;
        border: 1px solid ${theme.colors.gray400} !important;
        box-shadow: initial;
      }

      input {
        background: ${({ background }) => background || theme.colors.white};
        color: ${({ color }) => color || theme.colors.gray950};
        font-family: ${theme.fonts.family};

        &::placeholder {
          color: ${theme.colors.gray100};
        }
      }
    }
  }
`;
