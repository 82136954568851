import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { orderBy } from "lodash";
import { Row, Text, Div } from "../../../../styles/Common";
import { Drawer } from "../../../../components/Drawer";
import { Button } from "../../../../components/Button";
import { Radio } from "../../../../components/Radio";
import { Input } from "../../../../components/Input";
import { Select } from "../../../../components/Select";
import { CloseIcon } from "../../../../assets/icons";
import { theme } from "../../../../styles/theme";
import {
  isAdmin,
  isAccountOwner,
  isSuperAdmin,
  userClinic,
} from "../../../../utilities/helpers";
import { validateProductTreatmentForm } from "../../../../utilities/validations";
import useCreateProduct from "../../hooks/useCreateProduct";
import useEditProduct from "../../hooks/useEditProduct";

const DrawerProduct = ({
  showDrawer,
  closeDrawer,
  handleCloseDrawerSuccess,
  actualInfo,
  optionsClinics,
  optionsCategories,
}) => {
  const queryClient = useQueryClient();
  const superAdmin = isSuperAdmin();
  const { name: userClinicName, id: userClinicId } = userClinic();

  const createProduct = useCreateProduct();
  const editProduct = useEditProduct();

  const initialErrors = {
    name: {
      error: false,
      message: "",
    },
    categoryId: {
      error: false,
      message: "",
    },
    clinic: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    staffPrice: {
      error: false,
      message: "",
    },
    cost: {
      error: false,
      message: "",
    },
    bonusType: {
      error: false,
      message: "",
    },
    bonusValue: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    sku: {
      error: false,
      message: "",
    },
  };

  const [forceUpdate, setForceUpdate] = useState(false);
  const [optionsCategoriesFiltered, setOptionsCategoriesFiltered] = useState(
    []
  );
  const [productForm, setProductForm] = useState({
    type: "product",
    clinic: actualInfo?.clinicId?.toString(),
    categoryId: actualInfo.categoryId?.toString(),
    name: actualInfo.name,
    price: actualInfo.price,
    staffPrice: actualInfo.staffPrice,
    cost: actualInfo.cost,
    bonusType: actualInfo.bonusType,
    bonusValue: actualInfo.bonusValue,
    isTaxIncluded: actualInfo.isTaxIncluded,
    sku: actualInfo.sku,
  });

  const [errorForm, setErrorForm] = useState(initialErrors);

  useEffect(() => {
    setProductForm({
      type: "product",
      clinic: actualInfo?.clinicId?.toString(),
      categoryId: actualInfo.categoryId?.toString(),
      name: actualInfo.name,
      price: actualInfo.price,
      staffPrice: actualInfo.staffPrice,
      cost: actualInfo.cost,
      bonusType: actualInfo.bonusType,
      bonusValue: actualInfo.bonusValue,
      isTaxIncluded: actualInfo.isTaxIncluded,
      sku: actualInfo.sku,
    });
  }, [actualInfo]);

  useEffect(() => {
    if (
      optionsCategories &&
      (productForm.clinic || ((isAdmin() || isAccountOwner()) && userClinicId))
    ) {
      const clinicIdentificator =
        isAdmin() || isAccountOwner() ? userClinicId : productForm.clinic;
      const filterCategory = optionsCategories.filter(
        (cat) =>
          cat.category_type === 2 &&
          Number(cat.clinic.id) === Number(clinicIdentificator)
      );

      setOptionsCategoriesFiltered(
        orderBy(filterCategory, "position", "asc").map((ele) => {
          return {
            value: ele.id,
            label: ele.name,
          };
        })
      );
    }
  }, [optionsCategories, productForm.clinic, userClinicId]);

  const handleChangeProduct = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newProductForm = productForm;

    newProductForm[id] = value;

    setProductForm(newProductForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, id) => {
    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newProductForm = productForm;
    newProductForm[id] = value;

    if (id === "clinic") {
      newProductForm.categoryId = null;
    }

    if (id === "categoryId") {
      const clinicIdentificator =
        isAdmin() || isAccountOwner() ? userClinicId : productForm.clinic;
      const filterCategory = optionsCategories.filter(
        (cat) =>
          cat.category_type === 2 &&
          Number(cat.clinic.id) === Number(clinicIdentificator)
      );
      const selectedCategory = filterCategory.find((cat) => cat.id === value);

      let newBonusType;
      switch (selectedCategory.bonus_type) {
        case 1:
          newBonusType = "dollar";
          break;
        case 2:
          newBonusType = "percentage";
          break;
        case 3:
          newBonusType = "commission";
          break;
      }
      newProductForm.bonusType = newBonusType;
      newProductForm.bonusValue = selectedCategory.bonus_value;
    }

    setProductForm(newProductForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseDrawer = () => {
    closeDrawer();
    setErrorForm(initialErrors);
  };

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    const newProductForm = productForm;
    if (value === "commission") {
      newProductForm[name] = 0;
    }
    newProductForm[name] = value;
    setProductForm(newProductForm);
    setForceUpdate(!forceUpdate);
  };

  const handleCreateProduct = () => {
    toast.remove();

    if (!productForm["clinic"]) {
      const newProductForm = productForm;
      newProductForm["clinic"] = userClinicId;
      setProductForm(newProductForm);
    }

    const validation = validateProductTreatmentForm.validate(productForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      if (actualInfo.id) {
        editProduct.reset();
        editProduct.mutate(
          {
            id: actualInfo.id,
            type: "product",
            clinicId: productForm?.clinic,
            categoryId: productForm.categoryId,
            name: productForm.name,
            price: Number(productForm.price),
            staffPrice: Number(productForm.staffPrice),
            cost: Number(productForm.cost),
            bonusType: productForm.bonusType,
            bonusValue: Number(productForm.bonusValue),
            isTaxIncluded: productForm.isTaxIncluded,
            sku: productForm.sku,
          },
          {
            onSuccess: () => {
              toast.success("The information has been successfully saved");
              handleCloseDrawer();
              queryClient.invalidateQueries(["productList"]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to edit a product"
              );
            },
          }
        );
      } else {
        createProduct.reset();
        createProduct.mutate(
          {
            type: "product",
            clinicId: productForm?.clinic,
            categoryId: productForm.categoryId,
            name: productForm.name,
            price: Number(productForm.price),
            staffPrice: Number(productForm.staffPrice),
            cost: Number(productForm.cost),
            bonusType: productForm.bonusType,
            bonusValue: Number(productForm.bonusValue),
            isTaxIncluded: productForm.isTaxIncluded,
            sku: productForm.sku,
          },
          {
            onSuccess: () => {
              handleCloseDrawerSuccess();
              queryClient.invalidateQueries(["productList", 0, 10]);
            },
            onError: (err) => {
              toast.error(
                err.response.data.error.message ||
                  "Error trying to create a patient"
              );
            },
          }
        );
      }
    }
  };

  return (
    <Drawer
      closeIcon={<CloseIcon stroke={theme.colors.green} />}
      open={showDrawer}
      onClose={handleCloseDrawer}
      actualInfo={actualInfo}
    >
      <Div
        style={{ borderLeft: `5px solid ${theme.colors.green}` }}
        p="0px 0px 0px 16px"
        weight="5px"
        height="20px"
        align="center"
        m="6px 0px 0px 0px"
      >
        <Text
          weight={theme.fonts.weight.semibold}
          size={theme.fonts.size.h2}
          color={theme.colors.gray800}
        >
          {actualInfo.id ? "Edit Product" : "New Product"}
        </Text>
      </Div>
      <Row m="25px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.default}
          color={theme.colors.gray500}
        >
          {actualInfo.id
            ? "Complete the following information to edit product."
            : "Complete the following information to create a new product."}
        </Text>
      </Row>
      <Row m="24px 0px 0px 0px">
        {superAdmin ? (
          <Select
            label={actualInfo.id ? "Practice" : "Select Practice"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            defaultValue=""
            value={productForm.clinic}
            options={optionsClinics}
            onChange={(val) => handleSelect(val, "clinic")}
            disabled={actualInfo.id ? true : false}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.label?.props?.children || "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
            error={errorForm.clinic.error}
            helper={errorForm.clinic.error ? errorForm.clinic.message : ""}
          />
        ) : (
          <Input
            label={"Practice"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            value={userClinicName}
            disabled
          />
        )}
      </Row>
      <Row m="17px 0px 0px 0px">
        <Select
          label={"Category"}
          id="categoryId"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          defaultValue=""
          value={productForm.categoryId}
          options={optionsCategoriesFiltered}
          onChange={(val) => handleSelect(val, "categoryId")}
          autoComplete="off"
          optionFilterProp="children"
          filterOption={(input, option) => {
            const optionText = option?.label?.props?.children || "";
            return optionText.toLowerCase().includes(input.toLowerCase());
          }}
          showSearch
          error={errorForm.categoryId.error}
          helper={
            errorForm.categoryId.error ? errorForm.categoryId.message : ""
          }
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Input
          label={"Product Name"}
          id="name"
          value={productForm.name}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"286px"}
          onChange={handleChangeProduct}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Input
          label={"SKU"}
          id="sku"
          value={productForm.sku}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeProduct}
          error={errorForm.sku.error}
          helper={errorForm.sku.error ? errorForm.sku.message : ""}
        />
        <Input
          label={"Price"}
          id="price"
          type="Number"
          prefix="$"
          value={productForm.price}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeProduct}
          error={errorForm.price.error}
          helper={errorForm.price.error ? errorForm.price.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Input
          label={"Staff Price"}
          id="staffPrice"
          type="Number"
          prefix="$"
          value={productForm.staffPrice}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeProduct}
          error={errorForm.staffPrice.error}
          helper={
            errorForm.staffPrice.error ? errorForm.staffPrice.message : ""
          }
        />
        <Input
          label={"Product Cost"}
          id="cost"
          type="Number"
          prefix="$"
          value={productForm.cost}
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray400}
          background={theme.colors.white}
          color={theme.colors.gray600}
          width={"50%"}
          onChange={handleChangeProduct}
          error={errorForm.cost.error}
          helper={errorForm.cost.error ? errorForm.cost.message : ""}
        />
      </Row>
      <Row m="17px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Service Provider Bonus
        </Text>
      </Row>
      <Row m="17px 0px 0px 0px" gap="15px">
        <Radio.Group
          name="bonusType"
          value={productForm.bonusType}
          key={productForm.bonusType}
          onChange={handleRadio}
          gap={"5px"}
          direction="column"
          error={errorForm.bonusType.error}
          helper={errorForm.bonusType.error ? errorForm.bonusType.message : ""}
        >
          <Radio
            value="dollar"
            background={theme.colors.white}
            label={
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
              >
                Dollar
              </Text>
            }
          />

          <Radio
            value="percentage"
            background={theme.colors.white}
            label={
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
              >
                Percentage
              </Text>
            }
          />

          <Radio
            value="commission"
            background={theme.colors.white}
            label={
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
              >
                Commission
              </Text>
            }
          />
        </Radio.Group>
      </Row>
      {productForm.bonusType !== "commission" && (
        <Row m="17px 0px 0px 0px">
          <Input
            label={"Bonus"}
            id="bonusValue"
            type="Number"
            prefix={productForm.bonusType === "dollar" && "$"}
            suffix={productForm.bonusType === "percentage" && "%"}
            value={productForm.bonusValue}
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray400}
            background={theme.colors.white}
            color={theme.colors.gray600}
            width={"286px"}
            onChange={handleChangeProduct}
            error={errorForm.bonusValue.error}
            helper={
              errorForm.bonusValue.error ? errorForm.bonusValue.message : ""
            }
          />
        </Row>
      )}

      <Row m="17px 0px 0px 0px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Includes Taxes
        </Text>
      </Row>
      <Row m="17px 0px 150px 0px" gap="15px">
        <Radio.Group
          name={"isTaxIncluded"}
          value={productForm.isTaxIncluded}
          key={productForm.isTaxIncluded}
          onChange={handleRadio}
          gap={"15px"}
          direction="row"
          error={errorForm.isTaxIncluded.error}
          helper={
            errorForm.isTaxIncluded.error ? errorForm.isTaxIncluded.message : ""
          }
        >
          <Radio value={true} background={theme.colors.white} />
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            Yes
          </Text>
          <Radio value={false} background={theme.colors.white} />
          <Text
            weight={theme.fonts.weight.medium}
            size={theme.fonts.size.default}
            color={theme.colors.gray500}
          >
            No
          </Text>
        </Radio.Group>
      </Row>
      <Div
        style={{ position: "fixed", bottom: "0px" }}
        direction="column"
        background={theme.colors.white}
        width="340px"
      >
        <Row m="59px 0px 16px 0px">
          <Button
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            width="290px"
            onClick={handleCreateProduct}
            loading={createProduct.isLoading || editProduct.isLoading}
            variant="filled"
          >
            {actualInfo.id ? "Save Information" : "Create Product"}
          </Button>
        </Row>
        {actualInfo.id ? (
          <Row m="0px 0px 16px 0px">
            <Button
              variant={"outlined"}
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleCloseDrawer}
              width="290px"
            >
              Cancel
            </Button>
          </Row>
        ) : (
          <></>
        )}
      </Div>
    </Drawer>
  );
};

export default DrawerProduct;
