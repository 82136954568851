import React, { useState } from "react";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Row, Col, Text, Div, Span } from "../../styles/Common";
import { Card } from "./components/Card";
import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Password } from "../../components/Password";
import { theme } from "../../styles/theme";
import { validateLoginForm } from "../../utilities/validations";
import logo from "../../assets/images/dividermLog.svg";
import useAuth from "./hooks/useAuth";
import { setLocalUser, setRefresh } from "../../core/services/axiosInstance";
import { Link } from "../../components/Link";
import { emailLength, setLocalValue } from "../../utilities/helpers";
import { setFirstTime } from "../../core/store/common";
import {
  CopyrightIcon,
  DocumentsIcon,
  GraphicIcon,
  ScrewIcon,
  SyringeIcon,
} from "../../assets/icons";
import loginCarousel1 from "../../assets/images/login1.svg";
import loginCarousel2 from "../../assets/images/login2.webp";
import loginCarousel3 from "../../assets/images/login3.webp";
import loginCarousel4 from "../../assets/images/login4.svg";
import Carousel from "../../components/Carousel";

export const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authentication = useAuth();
  const { isLoading } = authentication;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const [errorForm, setErrorForm] = useState({
    email: {
      error: false,
      message: "",
    },
    password: {
      error: false,
      message: "",
    },
  });

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newLoginForm = loginForm;
    newLoginForm[id] = value;
    setLoginForm(newLoginForm);
    setForceUpdate(!forceUpdate);
  };

  const handleLogin = () => {
    toast.remove();
    const validation = validateLoginForm.validate(loginForm, {
      abortEarly: false,
    });

    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      authentication.reset();
      authentication.mutate(
        { email: loginForm.email, password: loginForm.password },
        {
          onSuccess: (res) => {
            setLocalUser(res.data.data.token);
            setRefresh(res.data.data.refreshToken);

            const urlToken = res.data.data.token
              ? decode(res.data.data.token).logoUrl
              : null;

            setLocalValue("urlLogo", urlToken);
            navigate("/home");
            dispatch(setFirstTime(true));
          },
          onError: (err) => {
            toast.error(err.response.data.error.message);
          },
        }
      );
    }
  };

  const handleReset = () => {
    navigate("/reset-password-request");
  };

  return (
    <>
      <Row width="100%" height="100vh">
        <Col
          width="50%"
          height="100vh"
          align="center"
          justify="center"
          style={{
            backgroundColor: theme.colors.gray700,
          }}
        >
          <Carousel>
            <Div direction="column">
              <Div height="350px" align="end" justify="center">
                <img
                  src={loginCarousel1}
                  alt="Imagen 1"
                  width="405px"
                  height="267.457px"
                />
              </Div>

              <Row
                align="center"
                width="100%"
                gap="19px"
                justify="center"
                style={{ marginTop: "auto" }}
              >
                <GraphicIcon width="55px" height="49.74px" />
                <Text
                  size={theme.fonts.size.h1}
                  color={theme.colors.green}
                  weight={theme.fonts.weight.bold}
                >
                  Detailed Reports
                </Text>
              </Row>
              <Text size={theme.fonts.size.h4} color={theme.colors.white}>
                Get real-time insights into sales, staff performance, and
                revenue trends.
              </Text>
            </Div>

            <Div direction="column" width="100%">
              <Div height="400px" align="end" justify="center">
                <img
                  src={loginCarousel2}
                  alt="Imagen 2"
                  width="327px"
                  height="356px"
                />
              </Div>

              <Row align="center" width="100%" gap="19px" justify="center">
                <ScrewIcon width="55px" height="44px" />
                <Text
                  size={theme.fonts.size.h1}
                  color={theme.colors.green}
                  weight={theme.fonts.weight.bold}
                >
                  Smart Management
                </Text>
              </Row>
              <Text
                size={theme.fonts.size.h4}
                color={theme.colors.white}
                style={{ padding: "0 80px" }}
              >
                Build your own database.
              </Text>
            </Div>

            <Div direction="column">
              <img
                src={loginCarousel3}
                alt="Imagen 3"
                width="407px"
                height="405px"
              />
              <Row align="center" width="100%" gap="19px" justify="center">
                <DocumentsIcon width="55px" height="49.74px" />
                <Text
                  size={theme.fonts.size.h1}
                  color={theme.colors.green}
                  weight={theme.fonts.weight.bold}
                >
                  Effortless Quotes
                </Text>
              </Row>
              <Text size={theme.fonts.size.h4} color={theme.colors.white}>
                Create, print, and boost sales with customized, professional
                quotes in seconds.
              </Text>
            </Div>

            <Div direction="column">
              <img
                src={loginCarousel4}
                alt="Imagen 4"
                width="564px"
                height="397px"
              />
              <Row align="center" width="100%" gap="19px" justify="center">
                <SyringeIcon width="55px" height="49.74px" />
                <Text
                  size={theme.fonts.size.h1}
                  color={theme.colors.green}
                  weight={theme.fonts.weight.bold}
                >
                  For Aesthetic Practices
                </Text>
              </Row>
              <Text size={theme.fonts.size.h4} color={theme.colors.white}>
                From consultations to sales—grow your practice and save time
                with a system built exclusively for the aesthetics industry.
              </Text>
            </Div>
          </Carousel>
        </Col>

        <Col
          width="50%"
          height="100vh"
          align="center"
          justify="center"
          style={{ backgroundColor: theme.colors.white50 }}
        >
          <Card>
            <Row width="100%" justify="center" m="43px 0 0 0">
              <img alt="logo" src={logo} width="250px" height="75px" />
            </Row>
            <Row width="100%" m="11px 0 23px 0" justify="center" p="0 50px">
              <Text
                weight={theme.fonts.weight.regular}
                size={theme.fonts.size.h5}
                color={theme.colors.black150}
                align={"center"}
              >
                Grow your aesthetic practice with{" "}
                <Span
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.h5}
                  color={theme.colors.black150}
                >
                  effortless quotes
                </Span>{" "}
                and{" "}
                <Span
                  weight={theme.fonts.weight.semibold}
                  size={theme.fonts.size.h5}
                  color={theme.colors.black150}
                >
                  higher sales.
                </Span>{" "}
              </Text>
            </Row>
            <Row width="100%" m="5px 0 0 0">
              <Input
                label={"Email"}
                id="email"
                placeholder={"you@example.com"}
                background={theme.colors.white250}
                maxLength={emailLength}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                onChange={handleChange}
                error={errorForm.email.error}
                helper={errorForm.email.error ? errorForm.email.message : ""}
                width="100%"
                border={theme.colors.gray500}
              />
            </Row>
            <Row width="100%" m="16px 0 0 0">
              <Password
                label={"Password"}
                id="password"
                placeholder={"Password"}
                background={theme.colors.white250}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray500}
                width="100%"
                onChange={handleChange}
                error={errorForm.password.error}
                helper={
                  errorForm.password.error ? errorForm.password.message : ""
                }
                onPressEnter={handleLogin}
                autoComplete="none"
                backgroundIcon={theme.colors.white150}
              />
            </Row>
            <Row width="100%" m="8px 0 0 0" justify="end">
              <Link
                size={theme.fonts.size.sm}
                weight={theme.fonts.weight.bold}
                color={theme.colors.green}
                onClick={handleReset}
                underline
              >
                Forgot Password
              </Link>
            </Row>
            <Row width="100%" m="22px 0 0 0">
              <Button
                onClick={handleLogin}
                width="100%"
                loading={isLoading}
                background={theme.colors.green}
                color={theme.colors.white}
                hBackground={theme.colors.green600}
                hActiveBackground={theme.colors.white}
                hActvieBorder={theme.colors.gray500}
                hActviecolor={theme.colors.gray500}
              >
                Log in
              </Button>
            </Row>

            <Row width="100%" m="54px 0 20px 0" justify="center">
              <Div gap={"8px"} align={"center"}>
                <CopyrightIcon />
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray500}
                >
                  2025. All Rights Reserved
                </Text>
              </Div>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};
