import React from "react";
import { CustomCarousel } from "./styles";

const Carousel = ({ children }) => {
  return (
    <div>
      <CustomCarousel
        dots={true}
        autoplay={true}
        autoplayInterval={4000}
        dotPosition="bottom"
        style={{
          height: "100vh",
          width: "600px",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        {children}
      </CustomCarousel>
    </div>
  );
};

export default Carousel;
