import styled from "styled-components";
import { theme } from "../../styles/theme";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const CategoryCard = styled.div`
  display: flex;
  width: 494px;
  height: 77px;
  border-radius: 18px;
  margin: 0px 0px 24px 0px;
  padding: 0px 24px 0px 24px;
  background: ${({ background }) => background || theme.colors.gray800} 0% 0%
    no-repeat padding-box;
  align-items: center;
  justify-content: space-around;

  ${(props) =>
    props.isDragging &&
    `
      border: 2px dashed ${theme.colors.green};
      opacity: 1;
    `}

  ${(props) =>
    props.isOver &&
    `
        border: 2px dashed ${theme.colors.white};
        opacity: 1;
      `}
`;

export const WraperIcon = styled.div`
  width: 30px;
  height: 30px;
  align-content: center;
  justify-items: center;

  &:hover {
    background: ${theme.colors.gray600};
    border-radius: 50%;
  }
`;
