import styled from "styled-components";
import { Input } from "antd";
import { theme } from "../../styles/theme";

export const CustomInputPassword = styled(Input.Password)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  width: ${({ width }) => width || null};
  height: 50px;
  border: 1px solid ${({ border }) => border || theme.colors.gray500};
  background: ${({ background }) => background || theme.colors.white250};
  color: ${({ color }) => color || theme.colors.black150};
  border-radius: 25px;
  opacity: 1;

  && {
    &.ant-input-affix-wrapper {
      .ant-input {
        background: ${({ background }) => background || theme.colors.white};
        color: ${({ color }) => color || theme.colors.gray950};
        font-family: ${theme.fonts.family};
        font-size: ${theme.fonts.size.sm};
        size: 16px;

        &::placeholder {
          color: ${theme.colors.gray600};
        }
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px
            ${({ background }) => background || theme.colors.white} inset;
          box-shadow: 0 0 0 30px
            ${({ background }) => background || theme.colors.white} inset;
          -webkit-text-fill-color: ${({ color }) =>
            color || theme.colors.gray950};
        }
      }

      &-focused {
        border: 1px solid ${theme.colors.gray500} !important;
        box-shadow: initial;
      }

      &:hover {
        border: 1px solid ${theme.colors.gray500};
      }

      .anticon.ant-input-password-icon {
        color: ${theme.colors.gray400};

        svg {
          width: 22px;
          height: 18px;
        }
      }
    }
  }
`;
