import { toast } from "react-hot-toast";
import { Col, Row, Text } from "../../../../styles/Common";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { theme } from "../../../../styles/theme";
import { CategoryIcon, AlertIcon } from "../../../../assets/icons";
import useUpdateBonusByCategory from "../../hooks/useUpdateBonusByCategory";

const ModalCategory = ({
  showModal,
  handleCloseModal,
  categoryToEdit,
  isEditing,
}) => {
  const updateBonusByCategory = useUpdateBonusByCategory();
  const handleUpdateBonus = () => {
    toast.remove();
    updateBonusByCategory.reset();
    updateBonusByCategory.mutate(
      {
        clinicId: Number(categoryToEdit.clinicId),
        categoryId: Number(categoryToEdit.id),
        bonusType: categoryToEdit.bonus_type === 1 ? "dollar" : "percentage",
        bonusValue: Number(categoryToEdit.bonus_value),
      },
      {
        onSuccess: () => {
          toast.success("All existing bonus values were updated");
          handleCloseModal();
        },
        onError: (err) => {
          toast.error(err.response.data.error.message);
          handleCloseModal();
        },
      }
    );
  };
  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"600px"}>
      <Modal.Header
        icon={
          isEditing ? (
            <AlertIcon
              stroke={theme.colors.yellow}
              width={"40px"}
              height={"40px"}
            />
          ) : (
            <CategoryIcon
              stroke={theme.colors.green}
              width={"40px"}
              height={"40px"}
            />
          )
        }
        iconBorderColor={isEditing ? theme.colors.yellow : theme.colors.green}
        iconWidth={"50px"}
        iconHeight={"50px"}
        align="-webkit-center"
        padding={"20px 63px 0px 63px"}
        title={
          isEditing
            ? "Confirm Bonus Value Update"
            : "New Category has been created"
        }
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="15px 0 24px 0"
        maxHeight={isEditing ? 300 : 200}
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" align="center" justify="center">
          <Row width="100%" p="0 27px 0px 27px">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              {isEditing
                ? "You have updated the bonus value for the products/treatments in this category. Would you like to overwrite the current values for all items in this category, or apply the new bonus value only as the default for future items?"
                : "The category has been created successfully"}
            </Text>
          </Row>
          <Row width="100%" p="30px 60px 0px 60px">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={() => {
                toast.success("The information has been successfully saved");
                handleCloseModal();
              }}
              width="100%"
              variant="filled"
            >
              {isEditing ? "Apply to New Items Only" : "View all categories"}
            </Button>
          </Row>
          {isEditing && (
            <Row width="100%" p="10px 60px 0px 60px">
              <Button
                variant={"outlined"}
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleUpdateBonus}
                width="100%"
              >
                Overwrite All Values
              </Button>
            </Row>
          )}
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCategory;
