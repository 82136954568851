import { useState, useEffect, useMemo } from "react";
import { toast } from "react-hot-toast";
import _ from "lodash";
import { Col, Row, Text } from "../../../../styles/Common";
import { Input } from "../../../../components/Input";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";
import { Select } from "../../../../components/Select";
import { theme } from "../../../../styles/theme";
import { Radio } from "../../../../components/Radio";
import { packageNames } from "../../helpers";
import {
  validateItemQuotingForm,
  validateOpenItemQuotingForm,
} from "../../../../utilities/validations";

const ModalOpenItems = ({
  showModal,
  handleCloseModal,
  selectedClinic,
  actualInfo,
  errorForm,
  setErrorForm,
  openItems,
  setOpenItems,
  packageNames,
  clinicTax,
}) => {
  const [forceUpdate, setForceUpdate] = useState(false);
  const [openItemForm, setOpenItemForm] = useState({
    type: "product",
    name: "",
    price: null,
    tax: clinicTax,
    isTaxIncluded: null,
    package: "",
    custom: "",
  });

  useEffect(() => {
    setOpenItemForm({
      type: actualInfo.type,
      name: actualInfo.name,
      price: actualInfo.price,
      tax: actualInfo.tax,
      isTaxIncluded: actualInfo.isTaxIncluded,
      package: actualInfo.package,
    });
  }, [actualInfo]);

  const handleRadio = (event) => {
    const { value, name } = event.target;

    const newItemForm = { ...openItemForm };
    const newErrorForm = errorForm;
    newErrorForm[name].error = false;
    newErrorForm[name].message = "";
    setErrorForm(newErrorForm);

    if (name === "isTaxIncluded") {
      newItemForm[name] = value;
      newItemForm["tax"] = value ? clinicTax : 0;
    } else if (value === "treatment") {
      newItemForm["isTaxIncluded"] = false;
      newItemForm["tax"] = 0;
      newItemForm[name] = value;
    } else {
      newItemForm["isTaxIncluded"] = true;
      newItemForm["tax"] = clinicTax;
      newItemForm[name] = value;
    }

    setOpenItemForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSelect = (value, item, id) => {
    const newErrorForm = errorForm;
    if (id === "treatments" || id === "products") {
      newErrorForm.products.error = false;
      newErrorForm.products.message = "";
      newErrorForm.treatments.error = false;
      newErrorForm.treatments.message = "";
    }
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...openItemForm };
    if (id !== "packageName") {
      if (!newItemForm[id].some((ele) => ele.id === item.value)) {
        newItemForm[id] = [
          ...newItemForm[id],
          { id: item.value, name: item.label?.props?.children },
        ];
      }
    } else {
      newItemForm["package"] = !value ? null : item.label?.props?.children;
    }
    setOpenItemForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChange = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...openItemForm };

    if (id === "price" || id === "tax") {
      newItemForm[id] = Number(value);
    } else {
      newItemForm[id] = value;
    }

    setOpenItemForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const handleChangeCustomName = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newItemForm = { ...openItemForm };

    newItemForm[id] = value;

    setOpenItemForm(newItemForm);
    setForceUpdate(!forceUpdate);
  };

  const handleAddItem = () => {
    toast.remove();

    const validation = validateOpenItemQuotingForm.validate(openItemForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      const newOpenItem = { ...openItemForm };
      if (newOpenItem.package === "Custom") {
        newOpenItem.package = newOpenItem.custom;
        delete newOpenItem.custom;
      }
      newOpenItem.quantity = 1;
      newOpenItem.isOpenItem = true;
      newOpenItem.discount = 0;
      newOpenItem.tax = (newOpenItem.tax / 100) * newOpenItem.price;
      setOpenItems((prevOpenItems) => [...prevOpenItems, newOpenItem]);
      handleCloseModal();
    }
  };

  return (
    <Modal open={showModal} onCancel={handleCloseModal} width={"405px"}>
      <Modal.Header
        title={"Add new open item"}
        align="-webkit-center"
        weightTitle={theme.fonts.weight.semibold}
      />
      <Modal.Body
        margin="18px 0 0 0"
        maxHeight={"693px"}
        align={"center"}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Col width="100%" height="100%" justify="center">
          <Row width="100%" m="0 0 24px 0" justify="center">
            <Text
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.regular}
              color={theme.colors.gray500}
            >
              Select one open item to your quote.
            </Text>
          </Row>

          <Row justify="center">
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.gray500}
            >
              Select product or treatment
            </Text>
          </Row>
          <Row width="100%" m="16px 0 5px 0">
            <Select
              label={"Treatment Plan Name"}
              id="packageName"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"355px"}
              defaultValue=""
              value={openItemForm.package}
              options={packageNames}
              onChange={(val, item) => {
                handleSelect(val, item, "packageName");
              }}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              allowClear
              showSearch
              error={errorForm.packageName.error}
              helper={
                errorForm.packageName.error ? errorForm.packageName.message : ""
              }
            />
          </Row>
          <Row width="100%" m="0 0 32px 0">
            {openItemForm.package === "Custom" && (
              <Input
                id="custom"
                value={openItemForm.custom}
                weightLabel={theme.fonts.weight.regular}
                sizeLabel={theme.fonts.size.sm}
                border={theme.colors.gray400}
                background={theme.colors.white}
                color={theme.colors.gray600}
                width={"100%"}
                placeholder={"Write the custom treatment plan name"}
                onChange={handleChangeCustomName}
                error={errorForm.custom.error}
                helper={errorForm.custom.error ? errorForm.custom.message : ""}
              />
            )}
          </Row>

          <Row width="100%" m="16px 0px 0px 0px" gap="20px" justify="center">
            <Radio.Group
              name="type"
              value={openItemForm.type}
              gap={"7px"}
              direction="row"
              onChange={handleRadio}
              error={errorForm.type.error}
              helper={errorForm.type.error ? errorForm.type.message : ""}
            >
              <>
                <Radio
                  value={"treatment"}
                  key={1}
                  background={theme.colors.white}
                />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Treatment
                </Text>
              </>
              <>
                <Radio
                  value={"product"}
                  key={2}
                  background={theme.colors.white}
                />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  Product
                </Text>
              </>
            </Radio.Group>
          </Row>

          <Row width="100%" m="17px 0px 0px 0px">
            <Input
              label={
                openItemForm.type === "treatment"
                  ? "Treatment Name"
                  : "Product Name"
              }
              id="name"
              value={openItemForm.name}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"100%"}
              onChange={handleChange}
              error={errorForm.name.error}
              helper={errorForm.name.error ? errorForm.name.message : ""}
            />
          </Row>

          <Row width="100%" m="17px 0px 0px 0px" gap="15px">
            {openItemForm.type === "product" && (
              <Col width="50%" align="center">
                <Text
                  weight={theme.fonts.weight.regular}
                  size={theme.fonts.size.sm}
                  color={theme.colors.gray500}
                >
                  Includes taxes
                </Text>
                <Radio.Group
                  name={"isTaxIncluded"}
                  value={openItemForm.isTaxIncluded}
                  key={openItemForm.isTaxIncluded}
                  onChange={handleRadio}
                  gap={"16px"}
                  direction="row"
                  error={errorForm.isTaxIncluded.error}
                  helper={
                    errorForm.isTaxIncluded.error
                      ? errorForm.isTaxIncluded.message
                      : ""
                  }
                >
                  <Radio value={true} background={theme.colors.white} />
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray500}
                  >
                    Yes
                  </Text>
                  <Radio value={false} background={theme.colors.white} />
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray500}
                  >
                    No
                  </Text>
                </Radio.Group>
              </Col>
            )}
            <Input
              label={"Price"}
              id="price"
              type="Number"
              prefix="$"
              value={openItemForm.price}
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray400}
              background={theme.colors.white}
              color={theme.colors.gray600}
              width={"50%"}
              onChange={handleChange}
              error={errorForm.price.error}
              helper={errorForm.price.error ? errorForm.price.message : ""}
            />
          </Row>

          <Row width="100%" p="0px 0px 16px 0px" m="10px 0 0 0">
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleAddItem}
              width="100%"
              variant="filled"
            >
              Add items
            </Button>
          </Row>
        </Col>
      </Modal.Body>
    </Modal>
  );
};

export default ModalOpenItems;
