import React, { useState } from "react";
import { Text, Div } from "../../styles/Common";
import { CustomInputPassword } from "./styles";
import { theme } from "../../styles/theme";
import { passwordLength } from "../../utilities/helpers";
import { EyeIcon, ClosedEyeIcon } from "../../assets/icons";
import { Tooltip } from "antd";

export const Password = ({
  placeholder,
  suffix,
  helper,
  error,
  disabled,
  onChange,
  onPressEnter,
  width,
  label,
  sizeLabel,
  weightLabel,
  autoComplete,
  colorIcon,
  backgroundIcon,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredEyeOpen, setIsHoveredEyeOpen] = useState(false);

  return (
    <div style={{ width }}>
      <Text
        weight={weightLabel}
        size={sizeLabel || theme.fonts.size.sm}
        color={theme.colors.gray500}
        mt="0px"
        mb="7px"
        ml="6px"
        align="left"
      >
        {label}
      </Text>
      <CustomInputPassword
        placeholder={placeholder}
        suffix={suffix}
        status={error ? "error" : ""}
        disabled={disabled}
        onChange={onChange}
        onPressEnter={onPressEnter}
        maxLength={passwordLength}
        autoComplete={autoComplete}
        iconRender={(visible) =>
          visible ? (
            <Tooltip color={theme.colors.gray950} title={"Hide"}>
              <Div
                width="33.8px"
                height="33.8px"
                background={isHovered ? backgroundIcon : "transparent"}
                radius="20px"
                align="center"
                justify="center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <ClosedEyeIcon stroke={colorIcon || theme.colors.gray1000} />
              </Div>
            </Tooltip>
          ) : (
            <Tooltip color={theme.colors.gray950} title={"Show"}>
              <Div
                width="33.8px"
                height="33.8px"
                background={isHoveredEyeOpen ? backgroundIcon : "transparent"}
                radius="20px"
                align="center"
                justify="center"
                onMouseEnter={() => setIsHoveredEyeOpen(true)}
                onMouseLeave={() => setIsHoveredEyeOpen(false)}
              >
                <EyeIcon stroke={colorIcon || theme.colors.gray1000} />
              </Div>
            </Tooltip>
          )
        }
        {...rest}
      />
      {helper && (
        <Text size={theme.fonts.size.xs} color={theme.colors.red} mt="2px">
          {helper}
        </Text>
      )}
    </div>
  );
};
