import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { Skeleton, Tooltip } from "antd";
import { Row, Div, Text, Container, Col } from "../../styles/Common";
import { Button } from "../../components/Button";
import {
  ClinicIcon,
  PinIcon,
  PhoneIcon,
  UserIcon,
  EmailOpenedIcon,
  OneUserIcon,
} from "../../assets/icons";
import { theme } from "../../styles/theme";
import { InfoCard, LogoCard, QuotingCard, ShadowCard } from "./styles";
import useGetProfile from "./hooks/useGetProfile";
import StaffQuote from "./Components/Staff Quote";
import {
  isSuperAdmin,
  userClinic,
  formattedDate,
  convertRoleName,
} from "../../utilities/helpers";
import ModalItems from "./Components/ModalStaffItems";
import useAddItem from "../Quoting Tool/hooks/useAddItem";

export const Profile = () => {
  const navigate = useNavigate();
  const superAdmin = isSuperAdmin();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};
  const { name: userClinicName, id: userClinicId } = userClinic();
  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );

  const getProfile = useGetProfile();
  const { data: dataProfile, isLoading } = getProfile;
  const [profile, setProfile] = useState({ user: {} });
  useEffect(() => {
    if (dataProfile) {
      setProfile(dataProfile?.data?.data);
    }
  }, [dataProfile]);

  const profileUserImage = useMemo(
    () =>
      profile?.user?.image
        ? `${profile?.user?.image}?${new Date().getTime()}`
        : null,
    [profile?.user?.image]
  );

  const addItem = useAddItem();

  const initialValues = {
    id: null,
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: "",
  };
  const [actualInfo, setActualInfo] = useState(initialValues);

  const itemInitialErrors = {
    treatmentsCategories: {
      error: false,
      message: "",
    },
    treatments: {
      error: false,
      message: "",
    },
    productsCategories: {
      error: false,
      message: "",
    },
    products: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [itemErrorForm, setItemErrorForm] = useState(itemInitialErrors);

  const [showModal, setShowModal] = useState(false);
  const initialQuotation = {
    clinicId: null,
    subtotal: 0,
    discount: 0,
    taxes: 0,
    total: 0,
    items: [],
  };
  const [quotation, setQuotation] = useState(initialQuotation);

  const handleSelect = (value, option, id) => {
    setSelectedClinic(option);
    setQuotation(initialQuotation);
  };

  const handleCloseModal = () => {
    setActualInfo(initialValues);
    setItemErrorForm(itemInitialErrors);
    setShowModal(false);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <ModalItems
        addItem={addItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfo}
        errorForm={itemErrorForm}
        setErrorForm={setItemErrorForm}
        quotation={quotation}
        setQuotation={setQuotation}
      />
      <Row
        height="40px"
        align="center"
        m="0px 0px 37px 0px"
        justify="space-between"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            My Profile
          </Text>
        </Div>
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          width="176px"
          onClick={() => navigate("edit-profile")}
        >
          Edit my profile
        </Button>
      </Row>
      <InfoCard>
        {isLoading ? (
          <Div
            direction="column"
            align="start"
            gap="10px"
            width="100%"
            height="100%"
            p="10px"
          >
            <Row width="100%" gap="20px">
              <Skeleton.Avatar active size={110} />
              <Skeleton active />
            </Row>
            <Row width="100%" justify="space-between">
              <Skeleton.Input active size={90} style={{ width: "280px" }} />
              <Skeleton.Input active size={90} style={{ width: "280px" }} />
              <Skeleton.Input active size={90} style={{ width: "280px" }} />
            </Row>
          </Div>
        ) : (
          <>
            <Row width="100%" style={{ justifyContent: "flex-end" }}>
              <Text
                weight={theme.fonts.weight.light}
                size={theme.fonts.size.default}
                color={theme.colors.gray400}
              >
                Creation date: {formattedDate(profile?.createdAt)}
              </Text>
            </Row>
            <Row m="0 0 20px 0 ">
              {profileUserImage?.includes("http") ? (
                <LogoCard
                  key={profileUserImage}
                  background={profileUserImage}
                />
              ) : (
                <LogoCard>
                  <UserIcon stroke={theme.colors.gray500} />
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray500}
                  >
                    Photo
                  </Text>
                </LogoCard>
              )}
              <Col m="0px 0px 0px 33px" width={"806px"}>
                <Row>
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h4}
                    color={theme.colors.white}
                  >
                    {profile?.user?.name} {profile?.user?.lastname}
                  </Text>
                </Row>
                <Row width={"100%"} gap="80px">
                  <Col>
                    <Row align="center" gap={"6px"} m={"16px 0 0 0"}>
                      <PhoneIcon stroke={theme.colors.green} />
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                      >
                        Phone Number
                      </Text>
                    </Row>
                    <Row align="center" m="0px 0px 32px 0px">
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.h5}
                        color={theme.colors.white}
                        align={"left"}
                        mt={"9px"}
                      >
                        {profile?.user?.phone}
                      </Text>
                    </Row>
                  </Col>
                  <Col>
                    <Row align="center" gap={"6px"} m={"16px 0 0 0"}>
                      <EmailOpenedIcon
                        stroke={theme.colors.green}
                        width={"20px"}
                        height={"20px"}
                      />
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.sm}
                        color={theme.colors.green}
                      >
                        Email
                      </Text>
                    </Row>
                    <Row align="center" m="0px 0px 32px 0px">
                      <Text
                        weight={theme.fonts.weight.regular}
                        size={theme.fonts.size.h5}
                        color={theme.colors.white}
                        align={"left"}
                        mt={"9px"}
                        style={{ width: "550px" }}
                      >
                        {profile?.email && profile?.email?.length > 50 ? (
                          <Tooltip
                            title={profile?.email}
                            color={theme.colors.gray950}
                          >
                            {`${profile?.email.slice(0, 50)}...`}
                          </Tooltip>
                        ) : (
                          profile?.email
                        )}
                      </Text>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row width="100%" justify={"space-between"}>
              <ShadowCard>
                <Div
                  width={"52px"}
                  height={"52px"}
                  background={theme.colors.gray800}
                  radius={"100%"}
                  align={"center"}
                  justify={"center"}
                >
                  <ClinicIcon
                    stroke={theme.colors.white}
                    width={"30px"}
                    height={"30px"}
                  />
                </Div>
                <Col m="0 35px 0 16px" width={"155px"}>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Practice
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.h5}
                    color={theme.colors.white}
                    align={"left"}
                    mt={"6px"}
                    style={{ "white-space": "nowrap" }}
                  >
                    {profile?.clinic?.name
                      ? profile?.clinic?.name
                      : "SuperAdmin"}
                  </Text>
                </Col>
              </ShadowCard>
              <ShadowCard>
                <Div
                  width={"52px"}
                  height={"52px"}
                  background={theme.colors.gray800}
                  radius={"100%"}
                  align={"center"}
                  justify={"center"}
                >
                  <OneUserIcon
                    stroke={theme.colors.white}
                    width={"30px"}
                    height={"30px"}
                  />
                </Div>
                <Col m="0 35px 0 16px" width={"155px"}>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Platform Role
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.h5}
                    color={theme.colors.white}
                    align={"left"}
                    mt={"6px"}
                  >
                    {convertRoleName[profile?.role?.name]}
                  </Text>
                </Col>
              </ShadowCard>
              <ShadowCard>
                <Div
                  width={"52px"}
                  height={"52px"}
                  background={theme.colors.gray800}
                  radius={"100%"}
                  align={"center"}
                  justify={"center"}
                >
                  <PinIcon
                    stroke={theme.colors.white}
                    width={"30px"}
                    height={"30px"}
                  />
                </Div>
                <Col m="0 35px 0 16px" width={"155px"}>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                  >
                    Address
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.h5}
                    color={theme.colors.white}
                    align={"left"}
                    mt={"6px"}
                  >
                    {profile?.user?.address}
                  </Text>
                </Col>
              </ShadowCard>
            </Row>
          </>
        )}
      </InfoCard>
      <QuotingCard>
        <StaffQuote
          superAdmin={superAdmin}
          selectedClinic={selectedClinic}
          optionsClinics={optionsClinics}
          handleSelect={handleSelect}
          actualInfo={actualInfo}
          quotation={quotation}
          setQuotation={setQuotation}
          setShowModal={setShowModal}
          addItem={addItem}
        />
      </QuotingCard>
    </Container>
  );
};
