export const reportTimeOptions = [
  { value: 1, label: "Today" },
  { value: 2, label: "Yesterday" },
  { value: 3, label: "This Week" },
  { value: 4, label: "Last Week" },
  { value: 5, label: "This Month" },
  { value: 6, label: "Last Month" },
  { value: 7, label: "This Year" },
  { value: 8, label: "Last Year" },
  { value: 0, label: "Custom" },
];

export const reportTimeExpresion = (value, index) => {
  switch (value) {
    case 1:
      return `Today`;
    case 2:
      return "Yesterday";
    case 3:
    case 4:
      return `Day ${index + 1}`;
    case 7:
    case 8:
      return `Month ${index + 1}`;
    default:
      return `Week ${index + 1}`;
  }
};

export const graphReportTimeExpresion = (value, index) => {
  switch (value) {
    case 1:
      return `Today`;
    case 2:
      return `Yesterday`;
    case 3:
    case 4:
      return `D${index + 1}`;
    case 7:
    case 8:
      return `M${index + 1}`;
    default:
      return `W${index + 1}`;
  }
};
