import { PhotoCard } from "../../styles";
import { AddPhotoIcon, InfoIcon } from "../../../../../assets/icons";
import { theme } from "../../../../../styles/theme";
import { Col, Div, Row, Text } from "../../../../../styles/Common";
import { Tooltip, Upload } from "antd";
import { Input } from "../../../../../components/Input";
import { Radio } from "../../../../../components/Radio";
import { convertRoleName } from "../../../../../utilities/helpers";

const FormUserCard = ({
  handleUploadLogo,
  imageLogo,
  prevImageLogo,
  handleChangeUser,
  errorForm,
  handleRadio,
  roles,
}) => {
  return (
    <Col m="0px 28px 0px 28px" width="100%">
      <Row
        align="center"
        m="0px 0px 10px 0px"
        style={{ justifyContent: "center" }}
      >
        <Upload
          multiple={false}
          showUploadList={false}
          customRequest={handleUploadLogo}
        >
          {imageLogo ? (
            <PhotoCard background={prevImageLogo} />
          ) : (
            <PhotoCard>
              <AddPhotoIcon stroke={theme.colors.gray500} />
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.sm}
                color={theme.colors.gray500}
              >
                Add photo
              </Text>
            </PhotoCard>
          )}
        </Upload>
      </Row>
      <Row align="center" m="0px 0px 15px 0px">
        <Input
          label={"First Name*"}
          id="name"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          onChange={handleChangeUser}
          error={errorForm.name.error}
          helper={errorForm.name.error ? errorForm.name.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 16px 0px">
        <Input
          label={"Last Name*"}
          id="lastname"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          width="100%"
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          onChange={handleChangeUser}
          error={errorForm.lastname.error}
          helper={errorForm.lastname.error ? errorForm.lastname.message : ""}
        />
      </Row>
      <Row align="center" m="0px 0px 16px 0px" gap="7px">
        <Text
          weight={theme.fonts.weight.regular}
          size={theme.fonts.size.sm}
          color={theme.colors.gray500}
        >
          Role*
        </Text>
        <Tooltip
          color={theme.colors.gray950}
          title={
            <>
              <Text color={theme.colors.white} size={theme.fonts.size.xs}>
                <span style={{ fontWeight: theme.fonts.weight.bold }}>
                  Manager –{" "}
                </span>
                Full system access, except for editing practice settings
                (reserved for the Account Owner).
              </Text>
              <Text color={theme.colors.white} size={theme.fonts.size.xs}>
                <span style={{ fontWeight: theme.fonts.weight.bold }}>
                  Admin Staff –
                </span>{" "}
                Can create quotes, access their performance dashboard, view the
                patient database, and review quote history. They can perform all
                tasks but do not have access to any settings.
              </Text>
              <Text color={theme.colors.white} size={theme.fonts.size.xs}>
                <span style={{ fontWeight: theme.fonts.weight.bold }}>
                  Service Provider –
                </span>{" "}
                Can view their performance dashboard and access only the
                patients and quotes assigned to them.
              </Text>
            </>
          }
        >
          <InfoIcon color={theme.colors.gray500} />
        </Tooltip>
      </Row>
      <Row m="0px 0px 10px 0px">
        <Radio.Group
          name="role"
          onChange={handleRadio}
          gap={"3px"}
          direction="column"
          error={errorForm.role.error}
          helper={errorForm.role.error ? errorForm.role.message : ""}
        >
          {roles
            .filter(
              (role) =>
                role.name !== "SuperAdmin" && role.name !== "AccountOwner"
            )
            .map((role, index) => (
              <Row gap="8px">
                <Radio value={role.id} key={role.id} />
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.default}
                  color={theme.colors.gray500}
                >
                  {convertRoleName[role.name]}
                </Text>
                {index < roles.length - 1 && <Div m="0 10px 0 0"></Div>}
              </Row>
            ))}
        </Radio.Group>
      </Row>
    </Col>
  );
};

export default FormUserCard;
