import styled from "styled-components";
import { Carousel } from "antd";
import { theme } from "../../styles/theme";

export const CustomCarousel = styled(Carousel)`
  .slick-dots li button {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    transition: none;
  }

  .slick-dots li.slick-active button {
    background-color: ${theme.colors.green};
  }

  .slick-dots {
    bottom: 30px; /* Posiciona los puntos hacia abajo */
    transition: none !important;
    position: absolute;
  }
`;
