import styled, { css } from "styled-components";
import { DatePicker } from "antd";
import { theme } from "../../styles/theme";

export const CustomRangePicker = styled(DatePicker.RangePicker)`
  font-family: ${theme.fonts.family};
  font-size: ${({ size }) => size || theme.fonts.size.sm};
  width: ${({ width }) => width || null};
  height: ${({ height }) => height || "44px"};
  border: 1.7px solid ${({ border }) => border || theme.colors.gray150};
  border-radius: ${({ radius }) => radius || "30px"};
  background: ${({ background }) => background || theme.colors.white};
  color: ${({ color }) => color || theme.colors.gray950};
  padding: 0 16px;
  opacity: 1;

  &:hover {
    background: ${(props) =>
      props.hoverBackground || theme.colors.purpleGradientSelects};
    box-shadow: 0px 4px 8px 0px #00000040;
    border-color: ${(props) => props.borderHover || theme.colors.gray150};
  }

  &:hover,
  &:focus {
    border-color: ${({ border }) => border || theme.colors.gray500} !important;
  }

  .ant-picker-input input::placeholder {
    color: ${({ placeholderColor }) =>
      placeholderColor || theme.colors.gray500};
  }

  &.ant-picker {
    &.has-helper {
      background: ${({ background }) => background || theme.colors.white};
      &:hover {
        background: ${({ background }) =>
          background || theme.colors.white} !important;
      }
    }
  }

  .ant-picker-input input:focus {
    text-decoration: underline;
    text-decoration-color: ${theme.colors.purple};
    text-decoration-thickness: 2px;
  }

  .ant-picker-input {
    input {
      color: ${({ color }) => color || theme.colors.gray950};
    }
  }

  .ant-picker-suffix {
    color: ${({ iconColor }) => iconColor || theme.colors.gray400};
  }
  .ant-picker-range-separator {
    .anticon {
      color: ${({ arrowColor }) => arrowColor || theme.colors.gray400};
    }
  }
  .ant-picker-clear {
    display: none !important;
  }
`;
