import React from "react";
import { theme } from "../../../../../styles/theme";
import { Col, Row, Text } from "../../../../../styles/Common";
import { BilledCard } from "../../../../Quoting Tool/styles";
import { Select } from "../../../../../components/Select";
import { DatePicker } from "../../../../../components/DatePicker";
import { Tag } from "../../../../../components/Tag";
import { CloseIcon } from "../../../../../assets/icons";

export const BilledInfo = ({
  selectedClinic,
  selectedPatient,
  selectedDueDate,
  paidFull,
  handleSelect,
  overDue,
  showModalPaid,
  selectedProviders,
  handleRemoveProvider,
  optionsProviders,
  optionsOpeners,
  selectedOpener,
  setSelectedOpener,
}) => {
  return (
    <BilledCard>
      <Col width={"290px"} justify="space-between">
        <Row m={"0 0 18px 0"}>
          <Text
            weight={theme.fonts.weight.bold}
            size={theme.fonts.size.h5}
            color={theme.colors.gray50}
          >
            Billed to
          </Text>
        </Row>
        <Row m={"0 0 16px 0"}>
          <Select
            label={"Patient Name"}
            id="patientName"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            colorLabel={theme.colors.gray400}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            color={theme.colors.gray50}
            width={"290px"}
            value={selectedPatient}
            disabled
          />
        </Row>
        <Row m={"0 0 18px 0"}>
          <DatePicker
            label={"Expiration date"}
            id="dueDate"
            sizeLabel={theme.fonts.size.sm}
            colorLabel={theme.colors.gray400}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            color={theme.colors.gray50}
            width={"290px"}
            placeholder={""}
            placeholderColor={theme.colors.red}
            value={selectedDueDate}
            onChange={(value, date) => handleSelect(value, date, "dueDate")}
            disabled={paidFull || overDue ? true : false}
          />
        </Row>
        {/* <Row>
          <Select
            label={"Opener"}
            id="opener"
            options={optionsOpeners}
            onChange={(val, item) => handleSelect(val, item, "opener")}
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            colorLabel={theme.colors.gray400}
            border={theme.colors.gray500}
            background={theme.colors.gray600}
            color={theme.colors.gray50}
            width={"290px"}
            defaultValue=""
            value={selectedOpener}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
            showSearch
          />
        </Row> */}
        {/* <Row
          width="100%"
          m="0 0 18px 0"
          justify="right"
          style={{ flexWrap: "wrap" }}
        >
          {selectedOpener?.label && (
            <Tag
              closable
              onClose={() => setSelectedOpener()}
              closeIcon={
                <CloseIcon
                  stroke={theme.colors.gray50}
                  width={"15px"}
                  height={"15px"}
                />
              }
              color={theme.colors.gray800}
              width="110px"
            >
              <Text
                size={theme.fonts.size.xs}
                weight={theme.fonts.weight.medium}
                color={theme.colors.gray400}
              >
                {selectedOpener?.label}
              </Text>
            </Tag>
          )}
        </Row> */}
      </Col>
      <Col width="290px" justify="space-between">
        <Col>
          <Row m={"0 0 18px 0"} justify="right">
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.gray50}
            >
              Practice Information
            </Text>
          </Row>
          <Row m={"0 0 9px 0"} justify="right">
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.h5}
              color={theme.colors.gray400}
            >
              {selectedClinic?.label}
            </Text>
          </Row>
        </Col>

        <Col>
          <Row justify="right">
            <Select
              label={"Staff Members"}
              id="selectedProviders"
              options={optionsProviders}
              onChange={(val, item) =>
                handleSelect(val, item, "selectedProviders")
              }
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              colorLabel={theme.colors.gray400}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray50}
              width={"270px"}
              defaultValue=""
              value=""
              disabled={selectedProviders.length >= 4}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
            />
          </Row>
          <Row
            width="100%"
            m="0 0 18px 0"
            justify="right"
            style={{ flexWrap: "wrap" }}
          >
            {selectedProviders?.map((provider, idx) => (
              <Tag
                key={idx}
                closable
                onClose={() => handleRemoveProvider(provider)}
                closeIcon={
                  <CloseIcon
                    stroke={theme.colors.gray50}
                    width={"15px"}
                    height={"15px"}
                  />
                }
                color={theme.colors.gray800}
                width="110px"
              >
                <Text
                  size={theme.fonts.size.xs}
                  weight={theme.fonts.weight.medium}
                  color={theme.colors.gray400}
                >
                  {provider.name}
                </Text>
              </Tag>
            ))}
          </Row>
        </Col>
      </Col>
    </BilledCard>
  );
};
