import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Row, Div, Text, Container } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Skeleton } from "../../components/Skeleton";
import Table from "../../components/Table";
import { theme } from "../../styles/theme";
import { DeleteIcon, EditIcon, FolderIcon } from "../../assets/icons";
import useGetTreatments from "./hooks/useGetTreatments";
import useDebounce from "../../core/hooks/useDebounce";
import { Link } from "../../components/Link";
import DrawerTreatment from "./components/DrawerTreatment";
import useCategory from "../../core/hooks/useCategory";
import {
  isSuperAdmin,
  userClinic,
  isAdmin,
  isAccountOwner,
} from "../../utilities/helpers";
import ModalTreatment from "./components/ModalTreatment";
import { Select } from "../../components/Select";
import { formatPriceToDollar } from "../../utilities/helpers";
import { Tooltip } from "antd";

export const Treatments = () => {
  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const { id: userClinicId } = userClinic();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isDeliting, setIsDeliting] = useState(false);
  const [selectedTreatmentId, setSelectedTreatmentId] = useState(null);

  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [sortOptions, setSortOptions] = useState({
    sortBy: null,
    sortDirection: null,
  });

  const [totalItems, setTotalItems] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const searchValue = useDebounce(searchText, 600);
  const [clinicFilter, setClinicFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
    sortDirection: sortOptions.sortDirection,
    sortBy: sortOptions.sortBy,
    searchBy: searchValue,
    clinicId: clinicFilter,
    categoryId: categoryFilter,
  });

  const initialValues = {
    type: "treatment",
    clinicId: null,
    categoryId: null,
    name: "",
    price: null,
    staffPrice: null,
    cost: null,
    bonusType: null,
    bonusValue: null,
    isTaxIncluded: false,
    sku: "",
  };
  const getTreatments = useGetTreatments(payloadOptions);
  const { data, isLoading, isFetching } = getTreatments;
  const [treatmentList, setTreatmentList] = useState([]);

  const getCategories = useCategory();
  const { data: dataCategories, isLoading: isLoadingCategories } =
    getCategories;
  const [optionsCategories, setOptionsCategories] = useState([]);
  const [optionsCategoriesFiltered, setOptionsCategoriesFiltered] = useState(
    []
  );

  const [actualInfo, setActualInfo] = useState(initialValues);

  useEffect(() => {
    if (dataCategories) {
      setOptionsCategories(dataCategories.data.data);
    }
  }, [dataCategories, actualInfo.clinicId]);

  useEffect(() => {
    if (optionsCategories) {
      const clinicIdentificator =
        isAdmin() || isAccountOwner() ? userClinicId : clinicFilter;
      const filterCategory = optionsCategories.filter(
        (cat) =>
          cat.category_type === 1 &&
          (clinicIdentificator === null ||
            Number(cat.clinic.id) === Number(clinicIdentificator))
      );

      setOptionsCategoriesFiltered(
        _.orderBy(filterCategory, "position", "asc").map((ele) => {
          return {
            value: ele.id,
            label: ele.name,
          };
        })
      );
    }
  }, [optionsCategories, clinicFilter, userClinicId]);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      const dataTable = data.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: (
              <Tooltip title="Edit" color={theme.colors.gray950}>
                <EditIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setActualInfo({
                clinicId: row.clinic.id,
                categoryId: row.Category.id,
                ...row,
              });
              setShowDrawer(true);
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: (
              <Tooltip title="Delete" color={theme.colors.gray950}>
                <DeleteIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setSelectedTreatmentId(row.id);
              setIsDeliting(true);
              setShowModal(true);
            },
          },
        ],
      }));
      setTreatmentList(dataTable);
      setTotalItems(data.data.data.total);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
      sortBy: sortOptions.sortBy,
      sortDirection: sortOptions.sortDirection,
      searchBy: searchValue,
      clinicId: clinicFilter,
      categoryId: categoryFilter,
    });
  }, [pages, sortOptions, searchValue, clinicFilter, categoryFilter]);

  useEffect(() => {}, [actualInfo]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleSelectClinic = (value) => {
    setClinicFilter(value);
    setCategoryFilter(null);
  };

  const handleChangeTable = (pagination, filter, sort) => {
    if (sort && sort.columnKey) {
      setSortOptions({
        sortBy: sort.columnKey === "Category" ? "categoryName" : sort.columnKey,
        sortDirection: sort.order === "ascend" ? "ASC" : "DESC",
      });
    } else {
      setSortOptions({ sortBy: null, sortDirection: null });
    }

    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setForceUpdate(!forceUpdate);
  };

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: "Treatment",
        dataIndex: "name",
        key: "name",
        ellipsis: true,
        width: 180,
        sorter: true,
        showSorterTooltip: false,
      },
      {
        title: "Category",
        dataIndex: "Category",
        key: "Category",
        width: 150,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => `${value ? value.name : ""}`,
      },

      {
        title: "Price",
        dataIndex: "price",
        key: "price",
        width: 120,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => `${formatPriceToDollar(value)}`,
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        width: 120,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => `${formatPriceToDollar(value)}`,
      },
      {
        title: "Staff Price",
        dataIndex: "staffPrice",
        key: "staffPrice",
        width: 130,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => `${formatPriceToDollar(value)}`,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 80,
        render: (actions, row) => {
          return (
            <Div gap="10px">
              {actions &&
                actions.map((action, i) => (
                  <Link
                    key={i}
                    onClick={() => action.onClick(row)}
                    color={theme.colors.gray50}
                    icon={action.icon}
                    styles={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  />
                ))}
            </Div>
          );
        },
      },
    ];
    if (isSuperAdmin()) {
      columns.splice(2, 0, {
        title: "Practice",
        dataIndex: "clinic",
        key: "clinicName",
        sorter: false,
        showSorterTooltip: false,
        width: 100,
        ellipsis: true,
        render: (value, item) => `${value ? value.name : ""}`,
      });
    }
    return columns;
  }, [treatmentList]);

  const handleCloseDrawerSuccess = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setShowModal(true);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseModal = () => {
    setIsDeliting(false);
    setSelectedTreatmentId(null);
    setShowModal(false);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
    setActualInfo(initialValues);
    setForceUpdate(!forceUpdate);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <ModalTreatment
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedTreatmentId={selectedTreatmentId}
        isDeliting={isDeliting}
        setShowDrawer={setShowDrawer}
      />

      <DrawerTreatment
        showDrawer={showDrawer}
        closeDrawer={handleCloseDrawer}
        handleCloseDrawerSuccess={handleCloseDrawerSuccess}
        actualInfo={actualInfo}
        optionsClinics={optionsClinics}
        optionsCategories={optionsCategories}
      />
      <Row
        height="40px"
        justify="space-between"
        align="center"
        m={isSuperAdmin() ? "0px 0px 20px 0px" : "0px 0px 37px 0px"}
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 10px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Treatments
          </Text>
        </Div>

        <Div gap="10px" align="center">
          <Input
            placeholder="Search by treatment name"
            color={theme.colors.gray400}
            width={isSuperAdmin() ? "200px" : "372px"}
            border={theme.colors.gray800}
            onChange={handleChangeSearch}
            background={theme.colors.gray800}
          />
          {isSuperAdmin() && (
            <Select
              id="clinic"
              placeholder="Filter by practice"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray800}
              background={theme.colors.gray800}
              color={theme.colors.gray400}
              width={"190px"}
              value={clinicFilter}
              options={optionsClinics}
              onChange={handleSelectClinic}
              autoComplete="off"
              optionFilterProp="children"
              filterOption={(input, option) => {
                const optionText = option?.label?.props?.children || "";
                return optionText.toLowerCase().includes(input.toLowerCase());
              }}
              showSearch
            />
          )}
          <Select
            placeholder={"Filter by category"}
            id="categoryId"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray800}
            background={theme.colors.gray800}
            color={theme.colors.gray400}
            width={"190px"}
            value={categoryFilter}
            options={optionsCategoriesFiltered}
            onChange={(val) => setCategoryFilter(val)}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.label?.props?.children || "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
          />
          <Button width="211px" onClick={() => setShowDrawer(true)}>
            <Row width="100%" justify="center">
              <Div gap={"8px"} align={"center"}>
                <FolderIcon stroke={theme.colors.black50} />
                Create Treatment
              </Div>
            </Row>
          </Button>
        </Div>
      </Row>
      <Row justify="end" m={"0px 10px 10px 0px"}>
        <Link
          onClick={() => {
            if (isSuperAdmin()) {
              setClinicFilter(null);
            }
            setCategoryFilter(null);
          }}
        >
          Clear Filters
        </Link>
      </Row>
      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={treatmentList}
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              } table-row-hover`
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "treatments", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching || isLoadingCategories ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Container>
  );
};
