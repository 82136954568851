import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { Row, Div, Text } from "../../styles/Common";
import { Button } from "../../components/Button";
import { Select } from "../../components/Select";
import { Link } from "../../components/Link";
import { theme } from "../../styles/theme";
import { isSuperAdmin, userClinic } from "../../utilities/helpers";
import { LateralCard, QuotingCard, TotalCard } from "./styles";
import { BilledInfo } from "./components/BilledInfo/Index";
import { ItemsInfo } from "./components/ItemsInfo/Index";
import ModalItems from "./components/ModalItems";
import ModalAdditionalDiscount from "./components/ModalAdditionalDiscount";
import useGetPatientsByClinicId from "./hooks/useGetPatientsByClinicId";
import useGetUserByRoles from "../../core/hooks/useGetUserByRoles";
import useGetOpeners from "../../core/hooks/useGetOpeners";
import useGetClinicById from "./hooks/useGetClinicById";
import { formatPriceToDollar } from "../../utilities/helpers";
import { AddIcon } from "../../assets/icons";
import { CloseIcon } from "../../assets/icons";
import { DeleteIcon } from "../../assets/icons";
import useSaveQuote from "./hooks/useSaveQuote";
import useAddItem from "./hooks/useAddItem";
import { validateQuotingForm } from "../../utilities/validations";
import DrawerPaymentMethod from "./components/DrawerPaymentMethod";
import DrawerPatient from "../Patients/componenets/DrawerPatient";
import ModalPatient from "./components/ModalPatient";
import { setSelectedQuotationInformation } from "../../core/store/quotations";
import Checkbox from "../../components/Checkbox";
import useParameters from "../../core/hooks/useParameters";
import ModalOpenItems from "./components/ModalOpenItems";
import ModalPackages from "./components/ModalPackages";
import useGetPackagesDropdown from "./hooks/useGetPackagesDropdown";

export const QuotingTool = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const superAdmin = isSuperAdmin();
  const queryClient = useQueryClient();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};
  const optionsCodes = common?.codesList || {};

  const { name: userClinicName, id: userClinicId } = userClinic();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalOpenItems, setShowModalOpenItems] = useState(false);
  const [showModalDiscount, setShowModalDiscount] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showDrawerPatient, setShowDrawerPatient] = useState(false);
  const [showModalPatient, setShowModalPatient] = useState(false);
  const [showModalPackages, setShowModalPackages] = useState(false);
  const [discountConsultationFee, setDiscountConsultationFee] = useState(false);
  const defaultDiscount = {
    name: "Discount",
    type: "dollar",
    value: 150,
  };
  const [discountConsultationValue, setDiscountConsultationValue] =
    useState(defaultDiscount);
  const [showItemizedPricing, setShowItemizedPricing] = useState(false);

  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );
  const [selectedPatient, setSelectedPatient] = useState({});
  const [selectedDueDate, setSelectedDueDate] = useState(
    dayjs().add(7, "days")
  );
  const [additionalDiscount, setAdditionalDiscount] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [selectedOpener, setSelectedOpener] = useState(null);

  const addItem = useAddItem();
  const saveQuote = useSaveQuote();
  const getPatients = useGetPatientsByClinicId(selectedClinic?.value);
  const { data: patients } = getPatients;
  const [optionsPatients, setOptionsPatients] = useState([]);
  const [listPatientsOrigin, setListPatientsOrigin] = useState([]);
  const [treatmentPlanNames, setTreatmentPlanNames] = useState([
    { value: -1, label: "Custom" },
  ]);

  const { data: dataProductsTreatments } = useParameters(
    "treatments_plans",
    {
      onSuccess: (apiData) => {
        const listPlans = apiData?.data?.data?.treatments_plans.map((pl) => ({
          value: pl.id,
          label: pl.name,
        }));
        setTreatmentPlanNames([{ value: -1, label: "Custom" }, ...listPlans]);
      },
    },
    selectedClinic?.value
  );
  useEffect(() => {
    if (dataProductsTreatments?.data?.data?.treatments_plans) {
      const listPlans =
        dataProductsTreatments?.data?.data?.treatments_plans.map((pl) => ({
          value: pl.id,
          label: pl.name,
        }));
      setTreatmentPlanNames([{ value: -1, label: "Custom" }, ...listPlans]);
    }
  }, [dataProductsTreatments]);

  useEffect(() => {
    if (patients) {
      setListPatientsOrigin(patients?.data?.data);
      setOptionsPatients(
        patients?.data?.data?.map((ele) => {
          return {
            value: ele.id,
            label: `${ele.name} ${ele.lastname}`,
          };
        })
      );
    }
  }, [patients]);

  const getProviders = useGetUserByRoles(
    ["ServiceProvider", "Manager", "StaffAdmin", "AccountOwner"],
    selectedClinic?.value
  );
  const { data: providers, isLoading: isLoadingProviders } = getProviders;
  const [optionsProviders, setOptionsProviders] = useState([]);

  useEffect(() => {
    if (providers) {
      setOptionsProviders(
        providers?.data?.data?.map((ele) => {
          return {
            value: ele.id,
            label: `${ele.name} ${ele.lastname}`,
          };
        })
      );
    }
  }, [providers]);

  const getOpeners = useGetOpeners(selectedClinic?.value);
  const { data: openers } = getOpeners;
  const [optionsOpeners, setOptionsOpeners] = useState([]);

  useEffect(() => {
    if (openers) {
      setOptionsOpeners(
        openers?.data?.data?.map((ele) => ({
          value: ele.id,
          label: `${ele.name} ${ele.lastname}`,
        }))
      );
    }
  }, [openers]);

  const getClinic = useGetClinicById(selectedClinic?.value);
  const { data: dataClinic, isSuccess: isSuccessDataClinic } = getClinic;
  const [clinicTax, setClinicTax] = useState(0);
  useEffect(() => {
    if (dataClinic?.data?.data && isSuccessDataClinic) {
      setClinicTax(dataClinic?.data?.data?.taxes);
      if (dataClinic?.data?.data?.expirationDays) {
        setSelectedDueDate(
          dayjs().add(dataClinic?.data?.data?.expirationDays, "days")
        );
      }
      setDiscountConsultationFee(
        dataClinic?.data?.data?.consultationFeeOnbyDefault
      );
      if (dataClinic?.data?.data?.consultationFeeValue) {
        setDiscountConsultationValue({
          name:
            dataClinic?.data?.data?.consultationFeeName !== ""
              ? dataClinic?.data?.data?.consultationFeeName
              : "Discount",
          type:
            dataClinic?.data?.data?.consultationFeeType !== ""
              ? dataClinic?.data?.data?.consultationFeeType
              : "dollar",
          value: dataClinic?.data?.data?.consultationFeeValue,
        });
      }
      setShowItemizedPricing(
        dataClinic?.data?.data?.itemizedPricingOnbyDefault
      );
    }
  }, [dataClinic, isSuccessDataClinic]);

  const getPackages = useGetPackagesDropdown(selectedClinic?.value);
  const { data: dataPackages } = getPackages;
  const [optionsPackages, setOptionsPackages] = useState([]);
  useEffect(() => {
    if (dataPackages) {
      setOptionsPackages(
        dataPackages?.data?.data?.map((ele) => ({
          value: ele.id,
          label: `${ele.name}`,
        }))
      );
    }
  }, [dataPackages]);

  const initialValues = {
    id: null,
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: null,
  };
  const [actualInfo, setActualInfo] = useState(initialValues);

  const initialValuesOpenItem = {
    type: "product",
    name: "",
    price: null,
    tax: clinicTax,
    isTaxIncluded: true,
    package: "",
  };
  const [actualInfoOpenItems, setActualInfoOpenItems] = useState({
    initialValuesOpenItem,
  });
  useEffect(() => {
    setActualInfoOpenItems(initialValuesOpenItem);
  }, [clinicTax]);

  const patientInfo = {
    id: null,
    email: "",
    name: "",
    lastname: "",
    callingCode: "+1",
    phone: null,
    clinicId: null,
    clinicName: "",
  };

  const itemInitialErrors = {
    treatmentsCategories: {
      error: false,
      message: "",
    },
    treatments: {
      error: false,
      message: "",
    },
    productsCategories: {
      error: false,
      message: "",
    },
    products: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [itemErrorForm, setItemErrorForm] = useState(itemInitialErrors);

  const openItemInitialErrors = {
    type: {
      error: false,
      message: "",
    },
    name: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    tax: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [openItemErrorForm, setOpenItemErrorForm] = useState(
    openItemInitialErrors
  );

  const saveQuoteInitialErrors = {
    patient: {
      error: false,
      message: "",
    },
    dueDate: {
      error: false,
      message: "",
    },
    items: {
      error: false,
      message: "",
    },
  };
  const [saveQuoteErrorForm, setSaveQuoteErrorForm] = useState(
    saveQuoteInitialErrors
  );

  const initialQuotation = {
    clinicId: null,
    subtotal: 0,
    discount: 0,
    taxes: 0,
    total: 0,
    items: [],
  };
  const [quotation, setQuotation] = useState(initialQuotation);
  const [openItems, setOpenItems] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "clinic":
        setSelectedClinic(option);
        setSelectedPatient({});
        setSelectedProviders([]);
        setQuotation(initialQuotation);
        setOpenItems([]);
        setDiscountConsultationValue(defaultDiscount);
        break;
      case "patient":
        if (value !== "createNewPatient") {
          const newErrorForm = { ...saveQuoteErrorForm };
          newErrorForm[id].error = false;
          newErrorForm[id].message = "";
          setSaveQuoteErrorForm(newErrorForm);
          setForceUpdate(!forceUpdate);
          setSelectedPatient({ value: option?.value, label: option.children });
        } else {
          setSelectedPatient({});
        }
        break;
      case "dueDate":
        const newErrorFormDD = { ...saveQuoteErrorForm };
        newErrorFormDD[id].error = false;
        newErrorFormDD[id].message = "";
        setSaveQuoteErrorForm(newErrorFormDD);
        setForceUpdate(!forceUpdate);
        setSelectedDueDate(value);
        break;
      case "selectedProviders":
        let newProviders = [...selectedProviders];
        const foundIndex = newProviders.findIndex(
          (ele) => ele.id === option.value
        );

        if (foundIndex === -1) {
          newProviders = [
            ...newProviders,
            { id: option.value, name: option.label },
          ];
        } else {
          newProviders[foundIndex] = { id: option.value, name: option.label };
        }
        setSelectedProviders(newProviders);
        break;
      case "opener":
        setSelectedOpener(option);
        break;
    }
    setForceUpdate(!forceUpdate);
  };

  const handleRemoveProvider = (provider) => {
    const updatedProviders = selectedProviders.filter(
      (prov) => prov.id !== provider.id
    );
    setSelectedProviders(updatedProviders);
  };

  const handleCheckbox = (e, id) => {
    switch (id) {
      case "discountConsultationFee":
        setDiscountConsultationFee(e.target.checked);
        break;
      case "itemizedPricing":
        setShowItemizedPricing(e.target.checked);
        break;
    }
  };

  const handleCloseModal = () => {
    setActualInfo(initialValues);
    setItemErrorForm(itemInitialErrors);
    setActualInfoOpenItems(initialValuesOpenItem);
    setOpenItemErrorForm(openItemInitialErrors);
    setShowModalPackages(false);
    setShowModal(false);
    setShowModalOpenItems(false);
  };

  const closeDrawer = () => {
    setShowDrawer(false);
    setShowDrawerPatient(false);
  };

  const openCreatePatient = () => {
    setShowDrawerPatient(true);
  };

  const handleCloseDrawerSuccess = () => {
    queryClient.invalidateQueries(["getPatientsByClinicId"]);
    setShowDrawerPatient(false);
    setShowModalPatient(true);
    setForceUpdate(!forceUpdate);
  };

  const handleCloseModalPatient = () => {
    setSelectedPatient(_.maxBy(optionsPatients, "value"));
    setShowModalPatient(false);
  };

  const handleDeletePaymentMethod = (label) => {
    setPaymentMethods((prevMethods) =>
      prevMethods.filter((method) => method !== label)
    );
  };

  const calculateTotal = () => {
    let calculation =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity + item.tax - item.discount
      ) +
      _.sumBy(
        openItems,
        (item) => item.price * item.quantity + item.tax - item.discount
      );
    const subtotal =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity - item.discount
      ) +
      _.sumBy(openItems, (item) => item.price * item.quantity - item.discount);
    additionalDiscount.forEach((discount) => {
      if (discount.bonusValue > 0) {
        if (discount.bonusType === "percentage") {
          calculation -= (subtotal * discount.bonusValue) / 100;
        } else {
          calculation -= discount.bonusValue;
        }
      }
    });

    if (discountConsultationFee && isSuccessDataClinic) {
      if (discountConsultationValue.type === "percentage") {
        calculation -= (subtotal * discountConsultationValue.value) / 100;
      } else {
        calculation -= discountConsultationValue.value;
      }
    }
    calculation = Math.max(0, calculation);
    return formatPriceToDollar(calculation);
  };

  const handleSaveQuote = () => {
    const saveQuoteForm = {
      patient: Number(selectedPatient.value),
      dueDate: selectedDueDate,
      items:
        quotation.items.length > 0
          ? quotation.items.map((item) => ({
              id: Number(item.productTreatmentId),
              package: item.package,
              quantity: item.quantity,
            }))
          : openItems,
    };

    const validation = validateQuotingForm.validate(saveQuoteForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = saveQuoteErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setSaveQuoteErrorForm(newErrorForm);
      });
      setForceUpdate(!forceUpdate);
    } else {
      let additionalDiscountType = null;
      let additionalDiscountName = null;
      let additionalDiscountValue = null;
      let additionalDiscountType1 = null;
      let additionalDiscountName1 = null;
      let additionalDiscountValue1 = null;
      let additionalDiscountType2 = null;
      let additionalDiscountName2 = null;
      let additionalDiscountValue2 = null;
      let additionalDiscountType3 = null;
      let additionalDiscountName3 = null;
      let additionalDiscountValue3 = null;
      additionalDiscount.forEach((discount, index) => {
        switch (index) {
          case 0:
            additionalDiscountType = discount.bonusType || null;
            additionalDiscountName = discount.discountName || null;
            additionalDiscountValue = discount.bonusValue || null;
            break;
          case 1:
            additionalDiscountType1 = discount.bonusType || null;
            additionalDiscountName1 = discount.discountName || null;
            additionalDiscountValue1 = discount.bonusValue || null;
            break;
          case 2:
            additionalDiscountType2 = discount.bonusType || null;
            additionalDiscountName2 = discount.discountName || null;
            additionalDiscountValue2 = discount.bonusValue || null;
            break;
          case 3:
            additionalDiscountType3 = discount.bonusType || null;
            additionalDiscountName3 = discount.discountName || null;
            additionalDiscountValue3 = discount.bonusValue || null;
            break;
          default:
            break;
        }
      });
      saveQuote.reset();
      saveQuote.mutate(
        {
          clinicId: Number(selectedClinic.value),
          patientId: Number(selectedPatient.value),
          dueDate: selectedDueDate,
          additionalDiscountType,
          additionalDiscountValue,
          additionalDiscountName,
          additionalDiscountType1,
          additionalDiscountName1,
          additionalDiscountValue1,
          additionalDiscountType2,
          additionalDiscountName2,
          additionalDiscountValue2,
          additionalDiscountType3,
          additionalDiscountName3,
          additionalDiscountValue3,
          paymentMethods: paymentMethods,
          discountConsultationFee: discountConsultationFee,
          showRate: showItemizedPricing,
          providers: selectedProviders.map((provider) => {
            return {
              userId: provider.id,
            };
          }),
          productsTreatments: quotation.items.map((item) => ({
            id: Number(item.productTreatmentId),
            package: item.package,
            quantity: item.quantity,
            belongsToPkg: item.belongsToPkg ? item.belongsToPkg : false,
          })),
          openItems: openItems.map((item) => ({
            package: item.package,
            name: item.name,
            type: item.type,
            price: item.price,
            quantity: item.quantity,
            tax: item.isTaxIncluded,
            belongsToPkg: item.belongsToPkg ? item.belongsToPkg : false,
            discount: item.discount,
          })),
        },
        {
          onSuccess: (data) => {
            toast.success("The quotation has been saved successfully");
            const patient = listPatientsOrigin.find(
              (p) => Number(p.id) === Number(selectedPatient.value)
            );

            dispatch(
              setSelectedQuotationInformation({
                id: Number(data?.data?.data?.id),
                state: "pending",
                dueDate: new Date(selectedDueDate),
                clinic: {
                  id: selectedClinic.value,
                  name: selectedClinic.label,
                },
                patient: {
                  id: selectedPatient.value,
                  name: patient.name,
                  lastname: patient.lastname,
                },
              })
            );
            navigate("/quote-history/edit-quotation");
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                err.response.data.error.message ||
                "Error trying to save the quote"
            );
          },
        }
      );
    }
  };

  return (
    <Div height="max-content" width="1070px" direction="column">
      <ModalItems
        addItem={addItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfo}
        errorForm={itemErrorForm}
        setErrorForm={setItemErrorForm}
        quotation={quotation}
        setQuotation={setQuotation}
        packageNames={treatmentPlanNames}
      />
      <ModalOpenItems
        showModal={showModalOpenItems}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfoOpenItems}
        errorForm={openItemErrorForm}
        setErrorForm={setOpenItemErrorForm}
        openItems={openItems}
        setOpenItems={setOpenItems}
        packageNames={treatmentPlanNames}
        clinicTax={clinicTax}
      />
      <ModalAdditionalDiscount
        showModalDiscount={showModalDiscount}
        setShowModalDiscount={setShowModalDiscount}
        additionalDiscount={additionalDiscount}
        setAdditionalDiscount={setAdditionalDiscount}
      />
      <DrawerPaymentMethod
        showDrawer={showDrawer}
        closeDrawer={closeDrawer}
        paymentMethods={paymentMethods}
        setPaymentMethods={setPaymentMethods}
      />
      <DrawerPatient
        showDrawer={showDrawerPatient}
        closeDrawer={closeDrawer}
        handleCloseDrawerSuccess={handleCloseDrawerSuccess}
        actualInfo={patientInfo}
        optionsClinics={optionsClinics}
        optionsCodes={optionsCodes}
      />
      <ModalPatient
        showModal={showModalPatient}
        handleCloseModal={handleCloseModalPatient}
      />
      <ModalPackages
        showModal={showModalPackages}
        handleCloseModal={handleCloseModal}
        clinicTax={clinicTax}
        optionsPackages={optionsPackages}
        quotation={quotation}
        setQuotation={setQuotation}
        addItem={addItem}
        openItems={openItems}
        setOpenItems={setOpenItems}
        setAdditionalDiscount={setAdditionalDiscount}
      />
      <Row justify="space-between" align="center" m="0px 0px 30px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Quoting Tool
          </Text>
        </Div>
        {superAdmin && (
          <Select
            label={"Select Practice"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray800}
            background={theme.colors.gray800}
            color={theme.colors.gray400}
            width={"286px"}
            defaultValue=""
            value={actualInfo?.clinicId?.toString()}
            options={optionsClinics}
            onChange={(val, option) => handleSelect(val, option, "clinic")}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.label?.props?.children || "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
          />
        )}
      </Row>

      {superAdmin && selectedClinic === null ? (
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h4}
          color={theme.colors.green}
        >
          Select a practice to get a quote
        </Text>
      ) : (
        <>
          <Row gap="16px">
            <QuotingCard>
              <BilledInfo
                selectedClinic={selectedClinic}
                optionsPatients={optionsPatients}
                optionsOpeners={optionsOpeners}
                optionsProviders={optionsProviders}
                handleSelect={handleSelect}
                selectedPatient={selectedPatient}
                selectedDueDate={selectedDueDate}
                saveQuoteErrorForm={saveQuoteErrorForm}
                openCreatePatient={openCreatePatient}
                selectedProviders={selectedProviders}
                handleRemoveProvider={handleRemoveProvider}
                selectedOpener={selectedOpener}
                setSelectedOpener={setSelectedOpener}
              />
              <ItemsInfo
                addItem={addItem}
                setShowModal={setShowModal}
                setShowModalOpenItems={setShowModalOpenItems}
                setShowModalPackages={setShowModalPackages}
                quotation={quotation}
                setQuotation={setQuotation}
                saveQuoteErrorForm={saveQuoteErrorForm}
                initialQuotation={initialQuotation}
                openItems={openItems}
                setOpenItems={setOpenItems}
              />
            </QuotingCard>

            <TotalCard>
              <LateralCard>
                <Text
                  weight={theme.fonts.weight.medium}
                  size={theme.fonts.size.h4}
                  color={theme.colors.white}
                  mb="24px"
                >
                  Payment Method
                </Text>
                {paymentMethods.map((method) => (
                  <Div
                    m="4px 0"
                    p="20px"
                    background={theme.colors.gray600}
                    radius="13px"
                    align="center"
                    justify="space-between"
                  >
                    <Text
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray50}
                    >
                      {method}
                    </Text>
                    <Link onClick={() => handleDeletePaymentMethod(method)}>
                      <DeleteIcon stroke={theme.colors.gray50} />
                    </Link>
                  </Div>
                ))}
                <Link
                  justify="center"
                  height="58px"
                  m="14px 0 0 0"
                  style={{
                    border: `1px dashed ${theme.colors.gray500}`,
                    "border-radius": "13px",
                    justifyContent: "center",
                  }}
                  onClick={() => setShowDrawer(true)}
                >
                  <AddIcon stroke={theme.colors.green} />
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.default}
                    color={theme.colors.green}
                    ml="10px"
                  >
                    Edit payment method
                  </Text>
                </Link>
              </LateralCard>
              <LateralCard>
                <Row align="center" justify="space-between" m="0 0 24px 0">
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h4}
                    color={theme.colors.white}
                  >
                    Amount due
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray50}
                  >
                    {"(USD)"}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Subtotal
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0 && openItems.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(
                            quotation?.items,
                            (item) => item.price * item.quantity
                          ) +
                            _.sumBy(
                              openItems,
                              (item) => item.price * item.quantity
                            )
                        )}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Savings
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(quotation?.items, (item) => item.discount) +
                            _.sumBy(openItems, (item) => item.discount)
                        )}
                  </Text>
                </Row>
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="0 0 12px 0"
                >
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total Tax
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0 && openItems.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(quotation?.items, (item) => item.tax) +
                            _.sumBy(openItems, (item) => item.tax)
                        )}
                  </Text>
                </Row>
                {additionalDiscount.length > 0 &&
                  additionalDiscount.map((discount, index) => (
                    <Row
                      style={{
                        background: theme.colors.gray600,
                        "border-radius": "11px",
                        justifyContent: "space-between",
                      }}
                      p="10px 5px 10px 10px"
                      m="0 0 19px 0"
                    >
                      <Text
                        weight={theme.fonts.weight.bold}
                        size={theme.fonts.size.default}
                        color={theme.colors.gray50}
                        style={{ width: "185px" }}
                      >
                        {discount?.discountName
                          ? discount?.discountName
                          : "Additional discount"}
                      </Text>
                      <Row gap="3px">
                        <Text
                          weight={theme.fonts.weight.bold}
                          size={theme.fonts.size.default}
                          color={theme.colors.white}
                        >
                          {discount?.bonusValue}
                        </Text>
                        <Text
                          weight={theme.fonts.weight.bold}
                          size={theme.fonts.size.default}
                          color={theme.colors.white}
                        >
                          {discount?.bonusType === "dollar" ? "USD" : "%"}
                        </Text>
                        <Link
                          onClick={() =>
                            setAdditionalDiscount(
                              additionalDiscount.filter(
                                (_, idx) => index !== idx
                              )
                            )
                          }
                        >
                          <CloseIcon
                            stroke={theme.colors.gray400}
                            width="20px"
                            height="20px"
                          />
                        </Link>
                      </Row>
                    </Row>
                  ))}
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="0 0 12px 0"
                >
                  <Checkbox
                    label={
                      discountConsultationValue?.name ||
                      "Frequent Additional Discount"
                    }
                    checked={discountConsultationFee}
                    onChange={(event) =>
                      handleCheckbox(event, "discountConsultationFee")
                    }
                    colorLabel={theme.colors.gray50}
                    sizeLabel={theme.fonts.size.default}
                  />
                </Row>
                {discountConsultationFee && isSuccessDataClinic && (
                  <Row
                    style={{
                      background: theme.colors.gray600,
                      "border-radius": "11px",
                      justifyContent: "space-around",
                    }}
                    p="10px 5px 10px 5px"
                    m="0 0 19px 0"
                  >
                    <Text
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.default}
                      color={theme.colors.gray50}
                      style={{ width: "185px" }}
                    >
                      {discountConsultationValue.name}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.bold}
                      size={theme.fonts.size.default}
                      color={theme.colors.white}
                    >
                      {`${discountConsultationValue.value} ${
                        discountConsultationValue.type === "dollar"
                          ? "USD"
                          : "%"
                      }`}
                    </Text>
                  </Row>
                )}
                <Div
                  height="1px"
                  width="100%"
                  background={theme.colors.gray500}
                  m="0 0 19px 0"
                />
                <Row align="center" justify="space-between" m="0 0 19px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h3}
                    color={theme.colors.green}
                  >
                    {quotation.items.length === 0 && openItems === 0
                      ? formatPriceToDollar(0)
                      : calculateTotal()}
                  </Text>
                </Row>

                {(quotation.items.length > 0 || openItems.length > 0) &&
                  additionalDiscount.length < 4 && (
                    <Link
                      justify="center"
                      height="58px"
                      style={{
                        border: `1px dashed ${theme.colors.gray500}`,
                        "border-radius": "13px",
                        justifyContent: "center",
                      }}
                      onClick={() => setShowModalDiscount(true)}
                    >
                      <AddIcon stroke={theme.colors.green} />
                      <Text
                        weight={theme.fonts.weight.semibold}
                        size={theme.fonts.size.default}
                        color={theme.colors.green}
                      >
                        Add additional discount
                      </Text>
                    </Link>
                  )}
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="20px 0 0 0"
                >
                  <Checkbox
                    label={"Show itemized pricing in Quote"}
                    checked={showItemizedPricing}
                    onChange={(event) =>
                      handleCheckbox(event, "itemizedPricing")
                    }
                    colorLabel={theme.colors.green}
                    sizeLabel={theme.fonts.size.sm}
                    weightLabel={theme.fonts.weight.bold}
                  />
                </Row>
              </LateralCard>
            </TotalCard>
          </Row>
          <Row align="center" m="35px 0px 37px 0px" gap={"14px"}>
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleSaveQuote}
              width="180px"
              m={"0 10px 0 0"}
              loading={saveQuote.isLoading}
            >
              Save Quote
            </Button>
            <Text
              weight={theme.fonts.weight.bold}
              size={theme.fonts.size.default}
              color={theme.colors.green}
            >
              To preview the quote as a pdf file please save the quotation
            </Text>
          </Row>
        </>
      )}
    </Div>
  );
};
