import { useState, useEffect, useMemo } from "react";
import { Skeleton, Upload } from "antd";
import { toast } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { Row, Div, Text, Container, Col } from "../../../styles/Common";
import { Link } from "../../../components/Link";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { ChevronLeftIcon } from "../../../assets/icons";
import userCheck from "../../../assets/icons/user-check.svg";
import { theme } from "../../../styles/theme";
import { useNavigate } from "react-router-dom";
import { UserCard, InfoCard, PhotoCard } from "./styles";
import {
  convertFileToBase64,
  createURLFromBase64,
} from "../../../utilities/helpers";
import { validateEditProfileForm } from "../../../utilities/validations";
import useEditProfile from "./hooks/useEditProfile";
import FormUserCard from "./components/FormUserCard";
import FormInfoCard from "./components/FormInfoCard";
import useGetProfile from "../hooks/useGetProfile";

export const EditProfile = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const editProfile = useEditProfile();

  const getProfile = useGetProfile();
  const { data: dataProfile, isLoading } = getProfile;
  const profile = dataProfile?.data?.data;

  const [showModal, setShowModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userForm, setUserForm] = useState({
    name: profile?.user?.name,
    lastname: profile?.user?.lastname,
    phone: profile?.user?.phone,
    address: profile?.user?.address,
    image: profile?.user?.image,
  });

  const [errorForm, setErrorForm] = useState({
    name: {
      error: false,
      message: "",
    },
    lastname: {
      error: false,
      message: "",
    },
    phone: {
      error: false,
      message: "",
    },
    image: {
      error: false,
      message: "",
    },
    address: {
      error: false,
      message: "",
    },
  });

  const [imageLogo, setImageLogo] = useState(null);
  const [prevImageLogo, setPrevImageLogo] = useState(null);
  useEffect(() => {
    if (
      prevImageLogo !== profile?.user?.image &&
      profile?.user?.image?.includes("http")
    ) {
      setImageLogo(profile?.user?.image);
      setPrevImageLogo(`${profile?.user?.image}?${new Date().getTime()}`);
    }
  }, [profile]);

  const handleUploadLogo = async (dataLogo) => {
    const file = dataLogo.file;
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpg" &&
      file.type !== "image/jpeg"
    ) {
      toast.error("The image must be .png or .jpg file");
    } else {
      const urlBase64 = await toast.promise(convertFileToBase64(file), {
        loading: "Uploading...",
        success: "Upload completed",
        error: "Upload failed, try again",
      });
      setImageLogo(urlBase64);
      setPrevImageLogo(createURLFromBase64(urlBase64, "image/jpeg"));
    }
  };

  const handleChangeUser = (event) => {
    const { value, id } = event.target;

    const newErrorForm = errorForm;
    newErrorForm[id].error = false;
    newErrorForm[id].message = "";
    setErrorForm(newErrorForm);

    const newUserForm = userForm;

    newUserForm[id] = value;

    setUserForm(newUserForm);
    setForceUpdate(!forceUpdate);
  };

  const handleEditUser = () => {
    toast.remove();
    const validation = validateEditProfileForm.validate(userForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = errorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorForm(newErrorForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      editProfile.reset();
      editProfile.mutate(
        {
          id: profile?.id,
          name: userForm.name,
          lastname: userForm.lastname,
          phone: userForm.phone,
          address: userForm.address,
          image:
            imageLogo === null || imageLogo.includes("http")
              ? null
              : `data:image/png;base64,${imageLogo}`,
        },
        {
          onSuccess: () => {
            setShowModal(true);
            queryClient.invalidateQueries(["getProfile"]);
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                "Error trying to edit an user"
            );
          },
        }
      );
    }
  };

  const handleClose = () => {
    setShowModal(false);
    navigate(-1);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <Modal open={showModal} onCancel={handleClose} width={"407px"}>
        <Modal.Header
          icon={userCheck}
          iconBorderColor={theme.colors.green}
          iconWidth={"50px"}
          iconHeight={"50px"}
          align="-webkit-center"
          padding={"20px 52px 0px 52px"}
          title={"Profile Information has been updated"}
          weightTitle={theme.fonts.weight.semibold}
        />
        <Modal.Body
          margin="24px 0 0 0"
          maxHeight={200}
          align={"center"}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Col width="100%" height="100%" align="center" justify="center">
            <Row width="100%" p="24px 60px 0px 60px">
              <Button
                size={theme.fonts.size.default}
                weight={theme.fonts.weight.semibold}
                onClick={handleClose}
                width="100%"
                variant="filled"
              >
                Go to my profile
              </Button>
            </Row>
          </Col>
        </Modal.Body>
      </Modal>

      <Row height="18px" align="center" m="0px 0px 37px 0px">
        <Link
          onClick={() => navigate(-1)}
          size={theme.fonts.size.sm}
          color={theme.colors.green}
          style={{ gap: "5px" }}
        >
          <ChevronLeftIcon stroke={theme.colors.green} />
          Go back
        </Link>
      </Row>

      <Row height="40px" align="center" m="0px 0px 37px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Edit my Profile
          </Text>
        </Div>
      </Row>

      <Row m="0px 0px 28px 0px" justify="center">
        <UserCard>
          {isLoading ? (
            <Div
              direction="column"
              gap="30px"
              align="center"
              width="100%"
              height="100%"
              p="20px"
            >
              <Skeleton.Avatar active size={158} />
              <Skeleton active />
            </Div>
          ) : (
            <FormUserCard
              handleUploadLogo={handleUploadLogo}
              imageLogo={imageLogo}
              prevImageLogo={prevImageLogo}
              handleChangeUser={handleChangeUser}
              userForm={userForm}
              errorForm={errorForm}
            />
          )}
        </UserCard>

        <InfoCard>
          {isLoading ? (
            <Div
              direction="column"
              align="start"
              gap="30px"
              width="100%"
              height="100%"
              p="40px"
            >
              <Skeleton.Input active size={50} />
              <Skeleton active paragraph={{ rows: 2 }} />
            </Div>
          ) : (
            <FormInfoCard
              handleChangeUser={handleChangeUser}
              errorForm={errorForm}
              userForm={userForm}
              profile={profile}
            />
          )}
        </InfoCard>
      </Row>

      <Row align="center" m="0px 0px 37px 0px">
        <Button
          size={theme.fonts.size.default}
          weight={theme.fonts.weight.semibold}
          onClick={handleEditUser}
          loading={editProfile.isLoading}
          width="226px"
        >
          Save Changes
        </Button>
      </Row>
    </Container>
  );
};
