import React from "react";
import { theme } from "../../../../../styles/theme";
import { Col, Row, Text, Div } from "../../../../../styles/Common";
import { Input } from "../../../../../components/Input";
import { Link } from "../../../../../components/Link";
import { Button } from "../../../../../components/Button";
import { Password } from "../../../../../components/Password";
import { ShadowCard } from "../../styles";
import { ClinicIcon, EmailOpenedIcon } from "../../../../../assets/icons";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { validateChangePassword } from "../../../../../utilities/validations";
import useChangePassword from "../../hooks/useChangePassword";
import { encrypt } from "../../../../../utilities/helpers";

const FormInfoCard = ({ handleChangeUser, errorForm, userForm, profile }) => {
  const [showPWInputs, setShowPWInputs] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  const changePassword = useChangePassword();

  const [pwForm, setPwForm] = useState({
    currentPassword: null,
    newPassword: null,
  });

  const [errorPWForm, setErrorPWForm] = useState({
    currentPassword: {
      error: false,
      message: "",
    },
    newPassword: {
      error: false,
      message: "",
    },
  });
  const openPWFields = () => {
    setShowPWInputs(true);
  };

  const handleChangePassword = (event) => {
    const { value, id } = event.target;

    const newErrorPWForm = errorPWForm;
    newErrorPWForm[id].error = false;
    newErrorPWForm[id].message = "";
    setErrorPWForm(newErrorPWForm);

    const newPWForm = pwForm;

    newPWForm[id] = value;

    setPwForm(newPWForm);
    setForceUpdate(!forceUpdate);
  };

  const handleSavePassword = () => {
    toast.remove();

    const validation = validateChangePassword.validate(pwForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorPWForm = errorPWForm;

      validation.error.details.forEach((ele) => {
        newErrorPWForm[ele.context.label].error = true;
        newErrorPWForm[ele.context.label].message =
          "Required field or invalid format";
        setErrorPWForm(newErrorPWForm);
        setForceUpdate(!forceUpdate);
      });
    } else {
      changePassword.reset();
      changePassword.mutate(
        {
          newPassword: encrypt(pwForm.newPassword),
          password: encrypt(pwForm.currentPassword),
        },
        {
          onSuccess: () => {
            setShowPWInputs(false);
            toast.success("The password has been changed successfully");
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.message ||
                "Error trying to change the password"
            );
          },
        }
      );
    }
  };

  return (
    <Col m="31px 22px 0px 22px" width="100%">
      <Row align="center" m="0px 0px 12px 0px">
        <Text
          weight={theme.fonts.weight.medium}
          size={theme.fonts.size.h4}
          color={theme.colors.white}
        >
          More Information
        </Text>
      </Row>
      <Row align="center" m="0px 0px 15px 0px" gap={"6px"}>
        <ShadowCard>
          <Div
            width={"52px"}
            height={"52px"}
            background={theme.colors.gray800}
            radius={"100%"}
            align={"center"}
            justify={"center"}
          >
            <ClinicIcon
              stroke={theme.colors.white}
              width={"30px"}
              height={"30px"}
            />
          </Div>
          <Col m="0 0px 0 16px" width={"194px"}>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.green}
              align={"left"}
            >
              Practice
            </Text>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.white}
              align={"left"}
              mt={"6px"}
              style={{ "white-space": "nowrap" }}
            >
              {profile.clinic.name ? profile.clinic.name : "SuperAdmin"}
            </Text>
          </Col>
        </ShadowCard>
        <ShadowCard>
          <Div
            width={"52px"}
            height={"52px"}
            background={theme.colors.gray800}
            radius={"100%"}
            align={"center"}
            justify={"center"}
          >
            <EmailOpenedIcon
              stroke={theme.colors.white}
              width={"30px"}
              height={"30px"}
            />
          </Div>
          <Col m="0 0px 0 16px" width={"194px"}>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.green}
              align={"left"}
            >
              Email
            </Text>
            <Text
              weight={theme.fonts.weight.regular}
              size={theme.fonts.size.sm}
              color={theme.colors.white}
              align={"left"}
              mt={"6px"}
            >
              {profile.email}
            </Text>
          </Col>
        </ShadowCard>
      </Row>
      <Row align="center">
        <Input
          label={"Address*"}
          id="address"
          weightLabel={theme.fonts.weight.regular}
          sizeLabel={theme.fonts.size.sm}
          border={theme.colors.gray500}
          background={theme.colors.gray600}
          color={theme.colors.gray400}
          width="100%"
          autoComplete="off"
          value={userForm.address}
          onChange={handleChangeUser}
          error={errorForm.address.error}
          helper={errorForm.address.error ? errorForm.address.message : ""}
        />
      </Row>

      <Row align="center" m="30px 0px 8px 0px" justify="end">
        <Link label="ChangePassword" onClick={openPWFields}>
          Change your Password
        </Link>
      </Row>
      {showPWInputs ? (
        <>
          <Row align="center" m="5px 0px 15px 0px" justify="space-between">
            <Password
              label={"Current Password*"}
              id="currentPassword"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"285px"}
              autoComplete="new-password"
              onChange={handleChangePassword}
              error={errorPWForm.currentPassword.error}
              helper={
                errorPWForm.currentPassword.error
                  ? errorPWForm.currentPassword.message
                  : ""
              }
              colorIcon={theme.colors.gray400}
            />
            <Password
              label={"New Password*"}
              id="newPassword"
              weightLabel={theme.fonts.weight.regular}
              sizeLabel={theme.fonts.size.sm}
              border={theme.colors.gray500}
              background={theme.colors.gray600}
              color={theme.colors.gray400}
              width={"285px"}
              autoComplete="none"
              onChange={handleChangePassword}
              error={errorPWForm.newPassword.error}
              helper={
                errorPWForm.newPassword.error
                  ? errorPWForm.newPassword.message
                  : ""
              }
              colorIcon={theme.colors.gray400}
            />
          </Row>
          <Row align="center" m="10px 0px 8px 0px" justify="end">
            <Button
              label="ChangePassword"
              width="210px"
              height="40px"
              onClick={handleSavePassword}
            >
              Save your Password
            </Button>
          </Row>
        </>
      ) : (
        <Row></Row>
      )}
    </Col>
  );
};

export default FormInfoCard;
