import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useQueryClient } from "react-query";
import _ from "lodash";
import { Row, Div, Text } from "../../../styles/Common";
import { Button } from "../../../components/Button";
import { Select } from "../../../components/Select";
import { theme } from "../../../styles/theme";
import { isSuperAdmin, userClinic } from "../../../utilities/helpers";
import { LateralCard, QuotingCard, TotalCard } from "./styles";
import { BilledInfo } from "./components/BilledInfo/Index";
import { ItemsInfo } from "./components/ItemsInfo/Index";
import ModalItems from "./components/ModalItems";
import useGetClinicById from "../../Quoting Tool/hooks/useGetClinicById";
import { formatPriceToDollar } from "../../../utilities/helpers";
import useSavePackage from "./hooks/useSavePackage";
import useAddItem from "../../Quoting Tool/hooks/useAddItem";
import { validatePackageForm } from "../../../utilities/validations";
import { setSelectedPackageInformation } from "../../../core/store/packages";
import ModalOpenItems from "./components/ModalOpenItems";
import ModalCalculator from "./components/ModalCalculator";

export const CreatePackage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const superAdmin = isSuperAdmin();
  const queryClient = useQueryClient();

  const { common } = useSelector((state) => state);
  const optionsClinics = common?.clinicsList || {};

  const { name: userClinicName, id: userClinicId } = userClinic();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalOpenItems, setShowModalOpenItems] = useState(false);
  const [showModalSavings, setShowModalSavings] = useState(false);
  const [selectedSavings, setSelectedSavings] = useState({});
  const [packageName, setPackageName] = useState("");

  const [selectedClinic, setSelectedClinic] = useState(
    superAdmin ? null : { value: userClinicId, label: userClinicName }
  );
  const [selectedDueDate, setSelectedDueDate] = useState(
    dayjs().add(1, "month")
  );

  const addItem = useAddItem();
  const savePackage = useSavePackage();

  const getTaxClinic = useGetClinicById(selectedClinic?.value);
  const { data: dataTax } = getTaxClinic;
  const [clinicTax, setClinicTax] = useState(0);
  useEffect(() => {
    if (dataTax?.data?.data) {
      setClinicTax(dataTax?.data?.data?.taxes);
    }
  }, [dataTax]);

  const initialValues = {
    id: null,
    treatmentsCategories: [],
    treatments: [],
    productsCategories: [],
    products: [],
    packageName: "",
  };
  const [actualInfo, setActualInfo] = useState(initialValues);

  const initialValuesOpenItem = {
    type: "product",
    name: "",
    price: null,
    tax: clinicTax,
    isTaxIncluded: true,
  };
  const [actualInfoOpenItems, setActualInfoOpenItems] = useState(
    initialValuesOpenItem
  );
  useEffect(() => {
    setActualInfoOpenItems(initialValuesOpenItem);
  }, [clinicTax]);

  const itemInitialErrors = {
    treatmentsCategories: {
      error: false,
      message: "",
    },
    treatments: {
      error: false,
      message: "",
    },
    productsCategories: {
      error: false,
      message: "",
    },
    products: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [itemErrorForm, setItemErrorForm] = useState(itemInitialErrors);

  const openItemInitialErrors = {
    type: {
      error: false,
      message: "",
    },
    name: {
      error: false,
      message: "",
    },
    price: {
      error: false,
      message: "",
    },
    tax: {
      error: false,
      message: "",
    },
    isTaxIncluded: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    custom: {
      error: false,
      message: "",
    },
  };
  const [openItemErrorForm, setOpenItemErrorForm] = useState(
    openItemInitialErrors
  );

  const saveQuoteInitialErrors = {
    dueDate: {
      error: false,
      message: "",
    },
    packageName: {
      error: false,
      message: "",
    },
    items: {
      error: false,
      message: "",
    },
  };
  const [saveQuoteErrorForm, setSaveQuoteErrorForm] = useState(
    saveQuoteInitialErrors
  );

  const initialQuotation = {
    clinicId: null,
    subtotal: 0,
    discount: 0,
    taxes: 0,
    total: 0,
    items: [],
  };
  const [quotation, setQuotation] = useState(initialQuotation);
  const [openItems, setOpenItems] = useState([]);

  const handleSelect = (value, option, id) => {
    switch (id) {
      case "clinic":
        setSelectedClinic(option);
        setQuotation(initialQuotation);
        setOpenItems([]);
        break;
      case "dueDate":
        const newErrorFormDD = { ...saveQuoteErrorForm };
        newErrorFormDD[id].error = false;
        newErrorFormDD[id].message = "";
        setSaveQuoteErrorForm(newErrorFormDD);
        setForceUpdate(!forceUpdate);
        setSelectedDueDate(value);
        break;
    }
    setForceUpdate(!forceUpdate);
  };

  const handleChangePackageName = (event) => {
    const { value, id } = event.target;
    const newErrorFormDD = { ...saveQuoteErrorForm };
    newErrorFormDD[id].error = false;
    newErrorFormDD[id].message = "";
    setSaveQuoteErrorForm(newErrorFormDD);

    setOpenItems((prevOpenItems) => [
      ...prevOpenItems.map((item) => ({ ...item, package: value })),
    ]);
    setQuotation({
      ...quotation,
      items: quotation.items.map((item) => ({
        ...item,
        package: value,
      })),
    });

    const newPackageName = value;
    setPackageName(newPackageName);
  };

  const handleCloseModal = () => {
    setActualInfo(initialValues);
    setItemErrorForm(itemInitialErrors);
    setActualInfoOpenItems(initialValuesOpenItem);
    setOpenItemErrorForm(openItemInitialErrors);
    setSelectedSavings({});
    setShowModal(false);
    setShowModalOpenItems(false);
    setShowModalSavings(false);
  };

  const calculateTotal = () => {
    let calculation =
      _.sumBy(
        quotation?.items,
        (item) => item.price * item.quantity + item.tax - Number(item.discount)
      ) +
      _.sumBy(
        openItems,
        (item) => item.price * item.quantity + item.tax - Number(item.discount)
      );

    calculation = Math.max(0, calculation);
    return formatPriceToDollar(calculation);
  };

  const handleSavePackage = () => {
    const saveQuoteForm = {
      dueDate: selectedDueDate,
      packageName: packageName,
      items:
        quotation.items.length > 0
          ? quotation.items.map((item) => ({
              id: Number(item.productTreatmentId),
              package: item.package,
              quantity: item.quantity,
            }))
          : openItems,
    };

    const validation = validatePackageForm.validate(saveQuoteForm, {
      abortEarly: false,
    });
    if (validation.error) {
      const newErrorForm = saveQuoteErrorForm;
      validation.error.details.forEach((ele) => {
        newErrorForm[ele.context.label].error = true;
        newErrorForm[ele.context.label].message =
          "Required field or invalid format";
        setSaveQuoteErrorForm(newErrorForm);
      });
      setForceUpdate(!forceUpdate);
    } else {
      savePackage.reset();
      savePackage.mutate(
        {
          clinicId: Number(selectedClinic.value),
          name: packageName,
          dueDate: selectedDueDate,
          productsTreatments: quotation.items.map((item) => ({
            productTreatmentId: Number(item.productTreatmentId),
            type: item.type,
            quantity: item.quantity,
            name: item.name,
            categoryId: item.categoryId,
            price: item.price,
            tax: item.tax,
            discount: Number(item.discount),
          })),
          openItems: openItems.map((item) => ({
            name: item.name,
            type: item.type,
            price: item.price,
            quantity: item.quantity,
            tax: item.isTaxIncluded,
            discount: Number(item.discount),
          })),
        },
        {
          onSuccess: (data) => {
            toast.success("The package has been saved successfully");

            dispatch(
              setSelectedPackageInformation({
                id: Number(data?.data?.data?.id),
                dueDate: new Date(selectedDueDate),
                clinicId: selectedClinic.value,
              })
            );
            navigate("/packages/edit-package");
          },
          onError: (err) => {
            toast.error(
              err.response.data.error.description ||
                err.response.data.error.message ||
                "Error trying to save the package"
            );
          },
        }
      );
    }
  };

  return (
    <Div height="max-content" width="1070px" direction="column">
      <ModalItems
        addItem={addItem}
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedClinic={selectedClinic}
        actualInfo={actualInfo}
        errorForm={itemErrorForm}
        setErrorForm={setItemErrorForm}
        quotation={quotation}
        setQuotation={setQuotation}
        packageName={packageName}
      />
      <ModalOpenItems
        showModal={showModalOpenItems}
        handleCloseModal={handleCloseModal}
        actualInfo={actualInfoOpenItems}
        errorForm={openItemErrorForm}
        setErrorForm={setOpenItemErrorForm}
        setOpenItems={setOpenItems}
        clinicTax={clinicTax}
        packageName={packageName}
      />
      <ModalCalculator
        showModal={showModalSavings}
        handleCloseModal={handleCloseModal}
        selectedSavings={selectedSavings}
        quotation={quotation}
        setQuotation={setQuotation}
        openItems={openItems}
        setOpenItems={setOpenItems}
      />

      <Row justify="space-between" align="center" m="0px 0px 30px 0px">
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Create Package
          </Text>
        </Div>
        {superAdmin && (
          <Select
            label={"Select Practice"}
            id="clinic"
            weightLabel={theme.fonts.weight.regular}
            sizeLabel={theme.fonts.size.sm}
            border={theme.colors.gray800}
            background={theme.colors.gray800}
            color={theme.colors.gray400}
            width={"286px"}
            defaultValue=""
            value={actualInfo?.clinicId?.toString()}
            options={optionsClinics}
            onChange={(val, option) => handleSelect(val, option, "clinic")}
            autoComplete="off"
            optionFilterProp="children"
            filterOption={(input, option) => {
              const optionText = option?.label?.props?.children || "";
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
            showSearch
          />
        )}
      </Row>

      {superAdmin && selectedClinic === null ? (
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h4}
          color={theme.colors.green}
        >
          Select a practice to create package
        </Text>
      ) : (
        <>
          <Row gap="16px">
            <QuotingCard>
              <BilledInfo
                selectedClinic={selectedClinic}
                handleSelect={handleSelect}
                selectedDueDate={selectedDueDate}
                saveQuoteErrorForm={saveQuoteErrorForm}
                packageName={packageName}
                handleChangePackageName={handleChangePackageName}
              />
              <ItemsInfo
                setShowModal={setShowModal}
                setShowModalOpenItems={setShowModalOpenItems}
                setShowModalSavings={setShowModalSavings}
                quotation={quotation}
                setQuotation={setQuotation}
                saveQuoteErrorForm={saveQuoteErrorForm}
                openItems={openItems}
                setOpenItems={setOpenItems}
                GlobalPackageName={packageName}
                setSelectedSavings={setSelectedSavings}
              />
            </QuotingCard>

            <TotalCard>
              <LateralCard>
                <Row align="center" justify="space-between" m="0 0 24px 0">
                  <Text
                    weight={theme.fonts.weight.medium}
                    size={theme.fonts.size.h4}
                    color={theme.colors.white}
                  >
                    Amount due
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.sm}
                    color={theme.colors.gray50}
                  >
                    {"(USD)"}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Subtotal
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0 && openItems.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(
                            quotation?.items,
                            (item) => item.price * item.quantity
                          ) +
                            _.sumBy(
                              openItems,
                              (item) => item.price * item.quantity
                            )
                        )}
                  </Text>
                </Row>
                <Row align="center" justify="space-between" m="0 0 12px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Savings
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(
                            quotation?.items.map((item) =>
                              Number(item.discount)
                            ),
                            (discount) => discount
                          ) +
                            _.sumBy(
                              openItems.map((item) => Number(item.discount)),
                              (discount) => discount
                            )
                        )}
                  </Text>
                </Row>
                <Row
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  m="0 0 12px 0"
                >
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total Tax
                  </Text>
                  <Text
                    weight={theme.fonts.weight.regular}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    {quotation.items.length === 0 && openItems.length === 0
                      ? formatPriceToDollar(0)
                      : formatPriceToDollar(
                          _.sumBy(quotation?.items, (item) => item.tax) +
                            _.sumBy(openItems, (item) => item.tax)
                        )}
                  </Text>
                </Row>

                <Div
                  height="1px"
                  width="100%"
                  background={theme.colors.gray500}
                  m="0 0 19px 0"
                />
                <Row align="center" justify="space-between" m="0 0 19px 0">
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray50}
                  >
                    Total
                  </Text>
                  <Text
                    weight={theme.fonts.weight.bold}
                    size={theme.fonts.size.h3}
                    color={theme.colors.green}
                  >
                    {quotation.items.length === 0 && openItems === 0
                      ? formatPriceToDollar(0)
                      : calculateTotal()}
                  </Text>
                </Row>
              </LateralCard>
            </TotalCard>
          </Row>
          <Row align="center" m="35px 0px 37px 0px" gap={"14px"}>
            <Button
              size={theme.fonts.size.default}
              weight={theme.fonts.weight.semibold}
              onClick={handleSavePackage}
              width="180px"
              m={"0 10px 0 0"}
              loading={savePackage.isLoading}
            >
              Save Package
            </Button>
          </Row>
        </>
      )}
    </Div>
  );
};
