import React from "react";
import { toast } from "react-hot-toast";
import { theme } from "../../../../../styles/theme";
import { Col, Row, Div, Text } from "../../../../../styles/Common";
import { formatPriceToDollar } from "../../../../../utilities/helpers";
import _ from "lodash";
import {
  AddIcon,
  CloseIcon,
  DecreaseIcon,
  EditIcon,
} from "../../../../../assets/icons";
import { Link } from "../../../../../components/Link";
import { Button } from "../../../../../components/Button";
import { ItemsCard, TableRow } from "../../../../Quoting Tool/styles";

export const ItemsInfo = ({
  setShowModal,
  setShowModalOpenItems,
  setShowModalPackages,
  quotation,
  setQuotation,
  addItem,
  isQuotePending,
  isEditingItems,
  setIsEditingItems,
  openItems,
  setOpenItems,
}) => {
  const updateQuotationRequest = (itemsChanged) => {
    if (itemsChanged.items.length > 0) {
      const items = itemsChanged.items.map((item) => {
        return {
          id: Number(item.productTreatmentId) || null,
          quantity: item.quantity,
          package: item.package,
        };
      });

      const prevBelongToPkg = quotation?.items.map((item) => ({
        id: item.productTreatmentId,
        quantity: item.quantity,
        package: item.package,
        belongsToPkg: item.belongsToPkg,
        discount: item.discount,
      }));

      addItem.reset();
      addItem.mutate(
        { productsTreatments: items },
        {
          onSuccess: (res) => {
            const updatedItems = res.data.data.items.map((item) => {
              const prevItem = prevBelongToPkg.find(
                (prevBelong) =>
                  prevBelong.id === item.productTreatmentId &&
                  prevBelong.package === item.package
              );
              if (prevItem) {
                return {
                  ...item,
                  tax: Number(item.tax),
                  belongsToPkg: prevItem.belongsToPkg,
                  discount: prevItem.belongsToPkg
                    ? prevItem.discount
                    : item.discount,
                };
              } else {
                return {
                  ...item,
                  tax: Number(item.tax),
                  belongsToPkg: null,
                };
              }
            });
            setQuotation({
              ...res.data.data,
              items: updatedItems,
            });
          },
          onError: (err) => {
            quotation?.items.length > 0 &&
              toast.error(
                err.response.data.error.description ||
                  "Error trying to update the quotation"
              );
          },
        }
      );
    } else {
      setQuotation({
        clinicId: null,
        subtotal: 0,
        discount: 0,
        taxes: 0,
        total: 0,
        items: [],
      });
    }
  };

  const groupedItems = _.groupBy(quotation?.items, "package");
  const items = quotation && quotation.items ? quotation.items : [];
  const combinedItems = _.groupBy([...items, ...openItems], "package");
  const filteredCombinedItems = _.mapValues(combinedItems, (group) =>
    group.filter(
      (item) => item.productTreatmentId !== null || item.isOpenItem === true
    )
  );

  const countPackagesQty = () => {
    let countPackages = 0;
    _.forEach(combinedItems, (items) => {
      const allBelongToPkg = _.every(items, { belongsToPkg: true });
      if (allBelongToPkg) {
        countPackages++;
      }
    });
    return countPackages;
  };

  const handleUpdateQuantity = (packageName, itemIndex, action, isOpenItem) => {
    if (!isOpenItem) {
      const updatedQuotation = { ...quotation };
      const updatedItems = [...updatedQuotation.items];
      const packageItems = filteredCombinedItems[packageName];
      const globalIndex = updatedItems.findIndex(
        (item) =>
          item.package === packageName &&
          item.name === packageItems[itemIndex].name
      );
      if (action === "increase") {
        updatedItems[globalIndex].quantity += 1;
      } else if (
        action === "decrease" &&
        updatedItems[globalIndex].quantity > 1
      ) {
        updatedItems[globalIndex].quantity -= 1;
      }

      updatedQuotation.items = updatedItems;
      updateQuotationRequest(updatedQuotation);
    } else {
      const updatedItems = [...openItems];
      const packageItems = filteredCombinedItems[packageName];
      const globalIndex = updatedItems.findIndex(
        (item) =>
          item.package === packageName &&
          item.name === packageItems[itemIndex].name
      );
      const unitaryTax =
        packageItems[itemIndex].tax / packageItems[itemIndex].quantity;

      if (action === "increase") {
        updatedItems[globalIndex].quantity += 1;
      } else if (
        action === "decrease" &&
        updatedItems[globalIndex].quantity > 1
      ) {
        updatedItems[globalIndex].quantity -= 1;
      }
      updatedItems[globalIndex].tax =
        unitaryTax * updatedItems[globalIndex].quantity;
      setOpenItems(updatedItems);
    }
  };

  const handleDeleteItem = (deletedItem, isOpenItem) => {
    if (!isOpenItem) {
      const updatedQuotation = { ...quotation };
      const updatedItems = quotation.items.filter(
        (item) => item !== deletedItem
      );
      updatedQuotation.items = updatedItems;
      updateQuotationRequest(updatedQuotation);
    } else {
      const updatedItems = openItems.filter((item) => item !== deletedItem);
      setOpenItems(updatedItems);
    }
    toast.success("Item successfully deleted");
  };

  const hanldeRemovePackage = (packageName) => {
    const updatedQuotationItems = quotation.items.filter(
      (item) => item.package !== packageName
    );

    const updatedOpenItems = openItems.filter(
      (item) => item.package !== packageName
    );

    setQuotation((prevQuotation) => ({
      ...prevQuotation,
      items: updatedQuotationItems,
    }));
    setOpenItems(updatedOpenItems);
  };

  return (
    <ItemsCard style={{ margin: "25px" }}>
      <Row m="0 0 18px 0">
        <Text
          weight={theme.fonts.weight.bold}
          size={theme.fonts.size.h5}
          color={theme.colors.gray50}
        >
          Item detail
        </Text>
      </Row>
      <Row m="0 0 11px 0" justify="center" width="680px">
        <Col>
          <TableRow background="transparent">
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "130px" }}
            >
              Item Name
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "100px" }}
            >
              Price
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "100px" }}
            >
              Quantity
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "82px" }}
            >
              Subtotal
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "66px" }}
            >
              Tax
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "92px" }}
            >
              Savings
            </Text>
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.sm}
              color={theme.colors.gray400}
              style={{ width: "100px" }}
            >
              Total Price
            </Text>
          </TableRow>
          {Object.entries(filteredCombinedItems).map(
            ([packageName, items], packageIndex) => (
              <Col key={packageIndex}>
                <Row justify="space-between">
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.default}
                    color={theme.colors.gray400}
                    mb="10px"
                  >
                    {packageName}
                  </Text>
                  {isEditingItems &&
                    items.every((item) => item.belongsToPkg) && (
                      <Link
                        color={theme.colors.gray400}
                        onClick={() => hanldeRemovePackage(packageName)}
                      >
                        Remove package
                        <CloseIcon
                          stroke={theme.colors.gray400}
                          width="20px"
                          height="20px"
                        />
                      </Link>
                    )}
                </Row>
                {items.map((item, index) => (
                  <TableRow
                    key={index}
                    background={
                      index % 2 === 0 ? theme.colors.gray600 : "transparent"
                    }
                  >
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "120px", "white-space": "nowrap" }}
                    >
                      {item.name}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "100px" }}
                    >
                      {formatPriceToDollar(item.price)}
                    </Text>
                    <Row
                      width={item.belongsToPkg ? "85px" : "110px"}
                      justify="center"
                      gap="5px"
                    >
                      {isEditingItems && !item.belongsToPkg && (
                        <Link
                          onClick={() =>
                            handleUpdateQuantity(
                              packageName,
                              index,
                              "decrease",
                              item?.isOpenItem ? item.isOpenItem : false
                            )
                          }
                        >
                          <DecreaseIcon
                            stroke={theme.colors.gray400}
                            width="15px"
                            height="15px"
                          />
                        </Link>
                      )}
                      <Text
                        weight={theme.fonts.weight.medium}
                        size={theme.fonts.size.sm}
                        color={theme.colors.gray400}
                        style={{
                          border: `1px solid ${theme.colors.gray500}`,
                          "border-radius": "25px",
                          width: "40px",
                        }}
                      >
                        {item.quantity}
                      </Text>
                      {isEditingItems && !item.belongsToPkg && (
                        <Link
                          onClick={() =>
                            handleUpdateQuantity(
                              packageName,
                              index,
                              "increase",
                              item?.isOpenItem ? item.isOpenItem : false
                            )
                          }
                        >
                          <AddIcon
                            stroke={theme.colors.gray400}
                            width="15px"
                            height="15px"
                          />
                        </Link>
                      )}
                    </Row>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "82px" }}
                    >
                      {formatPriceToDollar(item.price * item.quantity)}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "70px" }}
                    >
                      {formatPriceToDollar(item.tax)}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "92px" }}
                    >
                      {formatPriceToDollar(item.discount)}
                    </Text>
                    <Text
                      weight={theme.fonts.weight.medium}
                      size={theme.fonts.size.sm}
                      color={theme.colors.gray400}
                      style={{ width: "88px" }}
                    >
                      {formatPriceToDollar(
                        item.price * item.quantity + item.tax - item.discount
                      )}
                    </Text>
                    {isEditingItems && !item.belongsToPkg && (
                      <Link
                        onClick={() =>
                          handleDeleteItem(
                            item,
                            item?.isOpenItem ? item.isOpenItem : false
                          )
                        }
                      >
                        <CloseIcon
                          stroke={theme.colors.gray400}
                          width="20px"
                          height="20px"
                        />
                      </Link>
                    )}
                  </TableRow>
                ))}
                <TableRow background={"transparent"}>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "110px", "white-space": "nowrap" }}
                  >
                    TOTAL
                  </Text>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "100px" }}
                  >
                    {formatPriceToDollar(_.sumBy(items, "price"))}
                  </Text>
                  <Row width="100px" justify="center" gap="5px">
                    <Text
                      weight={theme.fonts.weight.semibold}
                      size={theme.fonts.size.sm}
                      color={theme.colors.green}
                      style={{
                        border: `1px solid ${theme.colors.green}`,
                        "border-radius": "25px",
                        width: "130px",
                      }}
                    >
                      {_.sumBy(items, "quantity")}
                    </Text>
                  </Row>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "82px" }}
                  >
                    {formatPriceToDollar(
                      _.sumBy(items, (item) => item.price * item.quantity)
                    )}
                  </Text>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "66px" }}
                  >
                    {formatPriceToDollar(_.sumBy(items, "tax"))}
                  </Text>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "92px" }}
                  >
                    {formatPriceToDollar(_.sumBy(items, "discount"))}
                  </Text>
                  <Text
                    weight={theme.fonts.weight.semibold}
                    size={theme.fonts.size.sm}
                    color={theme.colors.green}
                    style={{ width: "100px" }}
                  >
                    {formatPriceToDollar(
                      _.sumBy(items, (item) => {
                        const subtotal = item.price * item.quantity;
                        const taxes = item.tax || 0;
                        const discount = item.discount;
                        return subtotal + taxes - discount;
                      })
                    )}
                  </Text>
                </TableRow>
                <Div
                  height="1px"
                  background={theme.colors.gray600}
                  m="10px 0"
                />
              </Col>
            )
          )}
          {isQuotePending && (
            <Link
              m="14px 0 0 0"
              onClick={() => setIsEditingItems(!isEditingItems)}
              style={{ justifyContent: "right" }}
            >
              <EditIcon
                stroke={isEditingItems ? theme.colors.red : theme.colors.green}
              />
              <Text
                weight={theme.fonts.weight.semibold}
                size={theme.fonts.size.default}
                color={isEditingItems ? theme.colors.red : theme.colors.green}
              >
                {isEditingItems ? "Cancel edit" : "Edit items"}
              </Text>
            </Link>
          )}
        </Col>
      </Row>
      {isEditingItems && (
        <Row justify="space-between" width="680px" align="flex-end">
          <Link
            gap="5px"
            style={{ borderBottom: `1px solid  ${theme.colors.gray500}` }}
            onClick={() => setShowModalOpenItems(true)}
          >
            <AddIcon
              stroke={theme.colors.gray500}
              width={"16px"}
              height={"16px"}
            />
            <Text
              weight={theme.fonts.weight.medium}
              size={theme.fonts.size.default}
              color={theme.colors.gray500}
            >
              Add open item
            </Text>
          </Link>
          {countPackagesQty() < 3 && (
            <Link
              gap="5px"
              style={{ borderBottom: `1px solid  ${theme.colors.gray500}` }}
              onClick={() => setShowModalPackages(true)}
            >
              <AddIcon
                stroke={theme.colors.gray500}
                width={"16px"}
                height={"16px"}
              />
              <Text
                weight={theme.fonts.weight.medium}
                size={theme.fonts.size.default}
                color={theme.colors.gray500}
              >
                Add package
              </Text>
            </Link>
          )}
          <Button
            variant={"outlined"}
            size={theme.fonts.size.default}
            weight={theme.fonts.weight.semibold}
            onClick={() => setShowModal(true)}
            width="155px"
          >
            <Div gap={"8px"} style={{ justifyContent: "center" }}>
              <AddIcon stroke={theme.colors.purple300} />
              Add Items
            </Div>
          </Button>
        </Row>
      )}
    </ItemsCard>
  );
};
