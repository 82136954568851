import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { Row, Div, Text, Container } from "../../styles/Common";
import { Input } from "../../components/Input";
import { Button } from "../../components/Button";
import { Skeleton } from "../../components/Skeleton";
import Table from "../../components/Table";
import { theme } from "../../styles/theme";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  UserIcon,
  DeleteIcon,
  EditIcon,
  EyeIcon,
  ArchiveIcon,
} from "../../assets/icons";
import useGetUsers from "./hooks/useGetUsers";
import useDebounce from "../../core/hooks/useDebounce";
import { Link } from "../../components/Link";
import {
  convertRoleName,
  formattedDate,
  isAdmin,
} from "../../utilities/helpers";
import { setSelectedUserInformation } from "../../core/store/users";
import ModalUser from "./components/ModalUser";
import ModalDelete from "./components/ModalDelete";
import ModalArchive from "./components/ModalArchive";
import { Tooltip } from "antd";

export const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [forceUpdate, setForceUpdate] = useState(true);
  const [showModal, setShowModal] = useState({ show: false });
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [pages, setPages] = useState({ pageNumber: 0, pageSize: 10 });
  const [sortOptions, setSortOptions] = useState({
    sortBy: null,
    sortDirection: null,
  });

  const [totalItems, setTotalItems] = useState(10);
  const [searchText, setSearchText] = useState(null);
  const searchValue = useDebounce(searchText, 600);

  const [payloadOptions, setPayloadOptions] = useState({
    page: pages.pageNumber,
    pageSize: pages.pageSize,
    sortDirection: sortOptions.sortDirection,
    sortBy: sortOptions.sortBy,
    searchBy: searchValue,
  });
  const getUsers = useGetUsers(payloadOptions);
  const { data, isLoading, isFetching } = getUsers;
  const [usersList, setUsersList] = useState([]);

  const [actualInfo, setActualInfo] = useState({
    id: 0,
    email: "",
    user: {
      id: 0,
      name: "",
      lastname: "",
      callingCode: "",
      phone: 0,
      image: null,
      createdAt: "",
    },
    clinic: {
      id: 0,
      name: "",
    },
    role: {
      name: "",
    },
    createdAt: "",
  });
  useEffect(() => {
    if (!isLoading && !isFetching) {
      const dataTable = data.data.data.items.map((ele, i) => ({
        ...ele,
        key: `${ele.id}`,
        actions: [
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: (
              <Tooltip title="Edit" color={theme.colors.gray950}>
                <EditIcon
                  stroke={theme.colors.gray50}
                  style={{
                    cursor:
                      isAdmin() &&
                      (ele.role.name === "Manager" ||
                        ele.role.name === "AccountOwner")
                        ? "not-allowed"
                        : "pointer",
                  }}
                />
              </Tooltip>
            ),
            onClick: (row) => {
              if (
                !(
                  isAdmin() &&
                  (ele.role.name === "Manager" ||
                    ele.role.name === "AccountOwner")
                )
              ) {
                dispatch(setSelectedUserInformation(row));
                navigate("edit-user");
              }
            },
          },
          {
            key: `1${i}-view`,
            id: ele.id,
            icon: (
              <Tooltip title="See" color={theme.colors.gray950}>
                <EyeIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setActualInfo(row);
              dispatch(setSelectedUserInformation(row));
              setShowModal({ show: true });
            },
          },
          {
            key: `1${i}-delete`,
            id: ele.id,
            icon: (
              <Tooltip title="Delete" color={theme.colors.gray950}>
                {" "}
                <DeleteIcon stroke={theme.colors.gray50} />
              </Tooltip>
            ),
            onClick: (row) => {
              setSelectedUserId(row.user.id);
              setShowModalDelete(true);
            },
          },
          {
            key: `1${i}-edit`,
            id: ele.id,
            icon: (
              <Tooltip title="Archive" color={theme.colors.gray950}>
                <ArchiveIcon fill={theme.colors.gray100} />
              </Tooltip>
            ),
            onClick: (row) => {
              setSelectedUserId(row.user.id);
              setShowModalArchive(true);
            },
          },
        ],
      }));
      setUsersList(dataTable);
      setTotalItems(data.data.data.total);
    }
  }, [data, isLoading, isFetching]);

  useEffect(() => {
    setPayloadOptions({
      ...payloadOptions,
      page: pages.pageNumber,
      pageSize: pages.pageSize,
      sortBy: sortOptions.sortBy,
      sortDirection: sortOptions.sortDirection,
      searchBy: searchValue,
    });
  }, [pages, sortOptions, searchValue]);

  useEffect(() => {}, [actualInfo]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const handleChangeTable = (pagination, filter, sort) => {
    if (sort && sort.columnKey) {
      setSortOptions({
        sortBy: sort.columnKey,
        sortDirection: sort.order === "ascend" ? "ASC" : "DESC",
      });
    } else {
      setSortOptions({ sortBy: null, sortDirection: null });
    }

    setPages({
      pageNumber: pagination.current - 1,
      pageSize: pagination.pageSize,
    });
    setForceUpdate(!forceUpdate);
  };

  const tableColumns = useMemo(
    () => [
      {
        title: "C. Date",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 120,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) => {
          return `${formattedDate(value)}`;
        },
      },
      {
        title: "User Name",
        dataIndex: "user",
        key: "userName",
        width: 200,
        ellipsis: true,
        sorter: true,
        showSorterTooltip: false,
        render: (value, item) =>
          `${value ? `${value.name} ${value.lastname}` : ""}`,
      },
      {
        title: "Practice Name",
        dataIndex: "clinic",
        key: "clinicName",
        sorter: true,
        ellipsis: true,
        showSorterTooltip: false,
        width: 150,
        render: (value, item) => value.name,
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        width: 200,
        sorter: false,
        ellipsis: true,
        showSorterTooltip: false,
        render: (value, item) => value,
      },
      {
        title: "Role",
        dataIndex: "role",
        key: "role.name",
        width: 150,
        sorter: false,
        showSorterTooltip: false,
        render: (value, item) => `${value ? convertRoleName[value.name] : ""}`,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: 160,
        render: (actions, row) => {
          return (
            <Div gap="10px">
              {actions &&
                actions.map((action, i) => (
                  <Link
                    key={i}
                    onClick={() => action.onClick(row)}
                    color={theme.colors.gray50}
                    icon={action.icon}
                    styles={{
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                  />
                ))}
            </Div>
          );
        },
      },
    ],
    [usersList]
  );

  const handleCloseModal = () => {
    setShowModal({ show: false });
    setShowModalDelete(false);
    setShowModalArchive(false);
    setSelectedUserId(null);
  };

  return (
    <Container style={{ margin: "0px 0px 0px 10px" }}>
      <ModalUser
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        actualInfo={actualInfo}
      />

      <ModalDelete
        showModal={showModalDelete}
        handleCloseModal={handleCloseModal}
        selectedUserId={selectedUserId}
      />
      <ModalArchive
        showModal={showModalArchive}
        handleCloseModal={handleCloseModal}
        selectedUserId={selectedUserId}
      />

      <Row
        height="40px"
        justify="space-between"
        align="center"
        m="0px 0px 37px 0px"
      >
        <Div
          style={{ borderLeft: `5px solid ${theme.colors.green}` }}
          p="0px 0px 0px 16px"
          weight="5px"
          height="20px"
          align="center"
        >
          <Text
            weight={theme.fonts.weight.semibold}
            size={theme.fonts.size.h2}
            color={theme.colors.white}
          >
            Users
          </Text>
        </Div>

        <Div gap="10px" align="center">
          <Input
            placeholder="Search and filter by name, role, practice or email"
            color={theme.colors.gray400}
            width="597px"
            border={theme.colors.gray800}
            onChange={handleChangeSearch}
            background={theme.colors.gray800}
          />

          <Button width="183px" onClick={() => navigate("/users/create-user")}>
            <Row width="100%" justify="center">
              <Div gap={"8px"} align={"center"}>
                <UserIcon stroke={theme.colors.black50} />
                Create user
              </Div>
            </Row>
          </Button>
        </Div>
      </Row>

      <Row>
        <Div width="100%" direction="column">
          <Table
            columns={tableColumns}
            data={usersList}
            rowClassName={(record, index) =>
              `${
                index % 2 === 0 ? "table-row-light" : "table-row-dark"
              } table-row-hover`
            }
            style={{ cursor: "pointer" }}
            sizerLabels={["Showing", "users", "Page"]}
            pagination={{
              defaultCurrent: 1,
              ...pages,
              total: totalItems,
              showSizeChanger: true,
              pageSizeOptions: ["10", "50", "100"],
              locale: {
                items_per_page: "",
              },
              position: ["bottomCenter"],
            }}
            onChange={handleChangeTable}
            locale={{
              emptyText:
                isLoading || isFetching ? (
                  <Div justify="center" gap="16px">
                    {[...Array(5)].map((item, idx) => (
                      <Skeleton
                        key={idx}
                        title={false}
                        paragraph={{
                          rows: 10,
                          width: "100%",
                        }}
                        loading
                      />
                    ))}
                  </Div>
                ) : (
                  "No Data"
                ),
            }}
          />
        </Div>
      </Row>
    </Container>
  );
};
