import React from "react";
import { CustomButton } from "./styles";
import { theme } from "../../styles/theme";

export const Button = ({
  children,
  width,
  background,
  color,
  border,
  onClick,
  weight,
  height,
  radius,
  disabled,
  variant,
  hBackground,
  hActiveBackground,
  hActvieBorder,
  hActviecolor,
  ...rest
}) => {
  switch (variant) {
    case "outlined":
      return (
        <CustomButton
          width={width}
          background={theme.colors.purple400T}
          color={theme.colors.purple300}
          border={theme.colors.purple400}
          onClick={onClick}
          weight={weight}
          height={height}
          radius={radius}
          hBackground={theme.colors.purpleT}
          hBorder={theme.colors.purple400}
          hActiveBackground={theme.colors.purpleGradient}
          hActviecolor={theme.colors.purple100}
          disabled={disabled}
          {...rest}
        >
          {children}
        </CustomButton>
      );
    case "filled":
      return (
        <CustomButton
          width={width}
          background={theme.colors.purple300}
          color={theme.colors.white}
          onClick={onClick}
          weight={weight}
          height={height}
          radius={radius}
          hBackground={theme.colors.purple}
          hBorder={theme.colors.purple300}
          hActiveBackground={theme.colors.purple600}
          disabled={disabled}
          {...rest}
        >
          {children}
        </CustomButton>
      );

    default:
      return (
        <CustomButton
          width={width}
          background={background || theme.colors.whiteButon}
          color={color || theme.colors.black50}
          onClick={onClick}
          weight={weight}
          height={height}
          radius={radius}
          hBackground={hBackground || theme.colors.grayButon}
          hBorder={theme.colors.gray50}
          hActiveBackground={hActiveBackground || theme.colors.purple100}
          hActvieBorder={hActvieBorder}
          hActviecolor={hActviecolor}
          disabled={disabled}
          {...rest}
        >
          {children}
        </CustomButton>
      );
  }
};
